import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function CreatorTwo(props) {
  const { title, description, data } = props;
  const [page, setPage] = useState(1);
  const shiftPage = (direction) => {
    if (direction === "left") {
      if (page > 1) {
        setPage(page - 1);
      }
    } else {
      if (page < Math.ceil(data.length / 8)) {
        setPage(page + 1);
      }
    }
  };
  return (
    <div
      className={`container  ${title !== undefined ? "md:mt-24 mt-16" : ""}`}
    >
      <div className="grid grid-cols-1 text-center">
        <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">
          {title}
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">{description}</p>
      </div>

      <div
        className={`grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] ${
          title !== undefined ? "mt-12" : ""
        }`}
      >
        {data?.map((item, index) => (
          <div
            key={index}
            className="group relative overflow-hidden bg-gray-50 dark:bg-slate-800 rounded-md shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 ease-in-out duration-500 hover:-mt-2 h-fit"
          >
            <div className="flex justify-between items-center p-3">
              <div className="flex items-center">
                <div className="relative inline-block">
                  <img src={item.avatar} className="h-16 rounded-md" alt="" />
                  <i className="mdi mdi-check-decagram text-emerald-600 text-lg absolute -top-2 -end-2"></i>
                </div>

                <div className="ms-3">
                  <Link
                    to="/creator-profile"
                    className="font-semibold block hover:text-violet-600"
                  >
                    {item.name}
                  </Link>
                  <span className="text-slate-400 text-sm block mt-1">
                    {item.subtext}
                  </span>
                </div>
              </div>

              <Link
                to="#"
                className="btn btn-icon rounded-full bg-violet-600/5 hover:bg-violet-600 border-violet-600/10 hover:border-violet-600 text-violet-600 hover:text-white"
              >
                <i className="uil uil-user-plus text-[20px]"></i>
              </Link>
            </div>

            <div className="p-2 border-t dark:border-t-gray-700">
              <div className="flex items-center">
                {item?.subImages?.map((subitem, index) => (
                  <div className="w-1/2 p-1" key={index}>
                    <Link to="#" className="rounded-md lightbox">
                      <img src={subitem} className="rounded-md" alt="" />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {data.length > 8 ? (
        <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
          <div className="md:col-span-12 text-center">
            <nav>
              <ul className="inline-flex items-center -space-x-px">
                <li>
                  <button
                    onClick={() => shiftPage("left")}
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                  >
                    <i className="uil uil-angle-left text-[20px]"></i>
                  </button>
                </li>
                {data.length > 8 ? (
                  [...Array(Math.ceil(data.length / 8)).keys()].map(
                    (item, index) => (
                      <li key={index}>
                        <button
                          className={`w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600
                                    ${
                                      page === item + 1
                                        ? "text-white bg-violet-600 dark:bg-violet-600"
                                        : ""
                                    }`}
                          onClick={() => setPage(item + 1)}
                        >
                          {item + 1}
                        </button>
                      </li>
                    )
                  )
                ) : (
                  <></>
                )}
                <li>
                  <button
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                    onClick={() => shiftPage("right")}
                  >
                    <i className="uil uil-angle-right text-[20px]"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

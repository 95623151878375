import React, { useContext, useEffect, useState } from "react";
import Nfts from "../../components/nfts";
import { UserContext } from "../../store/UserContext";
import axios from "axios";
import {
  showSuccessToast,
  showErrorToast,
} from "../../components/NotificationService";

export default function General() {
  const { setAdmin, getAdminConfigs, adminConfigs } = useContext(UserContext);
  const eventTypes = ["1v1", "2v2", "3v3", "4v4", "5v5"];
  const visibilities = ["Open", "Blind"];
  const prizetypes = ["ETH"];
  const teamsTypes = ["Random"];
  const payoutTypes = ["Winners"];
  const [eventType, setEventType] = useState("1v1");
  const [visibility, setVisibility] = useState("Open");
  const [entryFee, setEntryFee] = useState(0);
  const [prizeType, setPrizeType] = useState("ETH");
  const [teams, setTeams] = useState("Random");
  const [payout, setPayout] = useState("Winners");
  const [statsData, setStatsData] = useState([
    {
      type: "1v1",
      totalEvents: 6,
      totalEntries: 12,
      totalPrize: "0.5 ETH",
      totalRake: "0.01 ETH",
      teams: "Random",
      payout: "Winners",
      rake: "2%",
    },
    {
      type: "2v2",
      totalEvents: 3,
      totalEntries: 12,
      totalPrize: "1.5 ETH",
      totalRake: "0.03 ETH",
      teams: "Random",
      payout: "Winners",
      rake: "3%",
    },
  ]);
  const [eventData, setEventData] = useState([
    {
      name: "1v1",
      type: "1v1",
      visibility: "Open",
      entryFee: 0,
      prizeType: "ETH",
      startTime: "3D: 5H: 30M: 20S",
      teams: "Random",
      payout: "Winners",
    },
  ]);

  const reload = async () => {
    try {
      await getAdminConfigs();
      setStatsData(adminConfigs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reload();
  }, []);

  const OnConfirmClick = () => {
    try {
      const adminConfig = {
        event: eventType,
        prize_type: prizeType,
        rake: rake,
        payout_type: payout,
        team_type: teams,
      };
      console.log(adminConfig);
      const result = setAdmin(adminConfig);
      if (result) {
        showSuccessToast("Settings Updated");
      }
    } catch (error) {
      showErrorToast("Error Updating Settings");
    }
  };

  const [rake, setRake] = useState(2);
  return (
    <div className="grid  items-center mt-4 justify-center">
      <div className="flex justify-center items-center mb-4 p-2 mt-4 dark:text-white text-black space-x-14">
        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium ">
          Modify Battle Settings
        </h3>
        <h2 className="text-2xl font-semibold mb-4 border-b-2 border-gray-300 pb-2 ">
          Applies to all current and future events
        </h2>
      </div>
      {/* Form to create events */}

      <form>
        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900 text-white text-xl p-3 space-x-2">
          <label>Event Type</label>
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900 dark:text-white text-slate-900 text-xl p-3 space-x-2"
          >
            {eventTypes.map((type, index) => (
              <option
                value={type}
                className="text-black dark:text-white"
                key={index}
              >
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900 text-white text-xl p-3 space-x-2">
          <label>Prize Type</label>
          <select
            value={prizeType}
            onChange={(e) => setPrizeType(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900 dark:text-white text-slate-900 text-xl p-3 space-x-2"
          >
            {prizetypes.map((type, index) => (
              <option
                value={type}
                className="text-black dark:text-white"
                key={index}
              >
                {type}
              </option>
            ))}
          </select>
          <div className="relative flex items-center space-x-2">
            <div className="text-white text-xl">
              {eventType === "All" ? "Kingdom Pools" : "Rake"}
            </div>
            <input
              value={rake}
              onChange={(e) => setRake(e.target.value)}
              className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900 dark:text-white text-slate-900 text-xl p-3"
              type="number"
            />
            <span className="mr-2 text-white"> %</span>
          </div>
        </div>
        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900 text-white text-xl p-3 space-x-2">
          <label> Teams</label>
          <select
            value={teams}
            onChange={(e) => setTeams(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900 dark:text-white text-slate-900 text-xl p-3 space-x-2"
          >
            {teamsTypes.map((type, index) => (
              <option
                value={type}
                className="text-black dark:text-white"
                key={index}
              >
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900 text-white text-xl p-3 space-x-2">
          <label> Payout Type</label>
          <select
            value={payout}
            onChange={(e) => setPayout(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900 dark:text-white text-slate-900 text-xl p-3 space-x-2"
          >
            {payoutTypes.map((type, index) => (
              <option
                value={type}
                className="text-black dark:text-white"
                key={index}
              >
                {type}
              </option>
            ))}
          </select>
        </div>

        {/*make a input for setting rake %*/}

        {/* Other form fields */}
      </form>
      <button
        className="btn btn-primary mt-4 bg-violet-600 hover:bg-violet-700 transition-all duration-300 rounded shadow-lg border-violet-700 hover:border-violet-80 text-white"
        onClick={() => OnConfirmClick()}
      >
        Save Changes
      </button>
      <div className="form-group mt-4 relative flex-col items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900 text-white text-xl p-3 space-x-2 ml-4 text-center overflow-scroll max-h-[500px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-700 overflow-x-hidden">
        <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium ">
          Current Stats
        </h3>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Event Type</th>
              <th className="px-4 py-2">Total Entries</th>
              <th className="px-4 py-2">Total Sent</th>
              <th className="px-4 py-2">Total Rake</th>
              <th className="px-4 py-2">Teams</th>
              <th className="px-4 py-2">Payout</th>
              <th className="px-4 py-2">Rake %</th>
            </tr>
          </thead>
          <tbody>
            {statsData?.map((data, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{data.event}</td>
                <td className="border px-4 py-2">{data.total_entries}</td>
                <td className="border px-4 py-2 text-red-500">
                  {parseFloat(data.total_sent).toFixed(8)}
                </td>
                <td className="border px-4 py-2 text-green-500">
                  {parseFloat(data.total_fees).toFixed(8)}
                </td>
                <td className="border px-4 py-2">{data.team_type}</td>
                <td className="border px-4 py-2">{data.payout_type}</td>
                <td className="border px-4 py-2">{parseFloat(data.rake)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

import React, { useEffect, useContext } from "react";
import Feature from "../../components/feature";
import Roadmap from "../../components/roadmap";
import { ElementFeature, RarityFeature } from "../../components/rarityFeature";

import DiscoverItems from "../../components/discover-items";
import QA from "../../components/qa";
import GetTouch from "../../components/get-touch";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import { Link } from "react-router-dom";
import Switcher from "../../components/switcher";
import { UserContext } from "../../store/UserContext";
import AnimatedBackground from "../../components/animatedBackground";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    document.documentElement.className = "dark";
    document.body.classList.add(
      "font-urbanist",
      "text-base",
      "text-black",
      "dark:text-white",
      "dark:bg-green-500"
    );
    if (!user || user.owner_name == "") {
    }
  }, []);

  return (
    <>
      <span className="fixed blur-[200px] w-[600px] h-[600px] rounded-full top-1/2 start-1/2 ltr:-translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2 bg-gradient-to-tl from-black to-black dark:from-black dark:to-black"></span>
      <Navbar />
      <section className="relative md:pt-4 pt-4 overflow-hidden">
        <AnimatedBackground />
        <div className="container">
          <div className="grid grid-cols-1 justify-center text-center mt-10">
            <div className="relative">
              <div className="relative mb-5">
                <h1 className=" font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-slate dark:text-white/7">
                  Pony
                  <span className="bg-gradient-to-r from-green-500 to-green-900 text-transparent bg-clip-text">
                    Jacks
                  </span>
                </h1>

                <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/10 dark:after:bg-violet-600/30 after:-top-[50px] after:start-[30%] after:-z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>

                <div className="overflow-hidden after:content-[''] after:absolute after:h-10 after:w-10 after:bg-violet-600/20 dark:after:bg-violet-600/40 after:bottom-[0] after:end-[15%] after:-z-1 after:rounded-full after:animate-ping"></div>
              </div>
              <p className="text-slate-400 dark:text-white/70 text-lg max-w-xl mx-auto">
                Ponyjacks combines love for art, rarity, horses, gaming, and
                web3. 88 unique breeds of NFT horses with custom art, rarity,
                and traits - grab yours and enter the Ponyverse. By owning one,
                you're automatically entered into our Early Access tournaments
                for a chance to battle and win prizes.
              </p>
              <div className="mt-8">
                <Link
                  to="/lobby"
                  className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full"
                >
                  Explore now
                </Link>
              </div>
            </div>
          </div>

          <div className="relative animate-[spin_30s_linear_infinite] -z-1">
            <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
            <span className="after:absolute after:start-0 after:bottom-1/2 after:translate-y-1/2 after:rotate-90 after:h-2 after:w-8 after:rounded-md after:bg-violet-600/20 relative after:z-10"></span>
          </div>
        </div>
        <Feature />
      </section>
      <section className="relative md:py-24 py-16">
        <DiscoverItems
          title="Build your Stable"
          data={
            user?.nfts
              ?.filter((nft) => nft && nft.subtext)
              .sort((a, b) => b.category !== "Ponyjacks") || []
          }
        />
        <RarityFeature />
        <ElementFeature />
        <Roadmap />
        <QA />
        <GetTouch />
      </section>
      <Footer />
      <Switcher />
    </>
  );
}

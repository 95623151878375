import React from "react";
import PonyDexTable from "./ponydexTable";

const PonyDex = () => {
  return (
    <div className=" container px-2 mx-auto text-center">
      <div className=" bg-gray-100 min-h-screen dark:bg-slate-900 dark:text-gray-200">
        <div className=" bg-blue-900 text-white py-4">
          <div className="container mx-auto text-center">
            <h1 className="text-3xl font-bold">Ponydex</h1>
          </div>
        </div>
        <div className=" mx-auto py-8">
          <div className="bg-white p-8 rounded-lg shadow-md dark:bg-slate-900">
            <h2 className="text-2xl font-bold mb-4">
              Your guide for the 88 released breeds of Ponyjacks including art,
              rarity, and stats.
            </h2>
            <p>
              Legendary and Uniques will remain hidden until discovered/minted
              by the community, and will be rewarded with a Diamond Horseshoe
              which can be used to unlock special Ponyjacks/prizes.
            </p>
            <ol className="list-decimal ml-6 mb-4 text-left">
              <li>
                There will be only up to 1000 Gen 0 Ponyjacks, far fewer, only
                50-300 at first
              </li>
              <li>Ponyjacks have 1 or 2 Elements aka Kingdoms</li>
              <li>Each Kingdom's breeds are good at something</li>
              <li>
                The rarer, the better the stat boost your Ponyjack has from that
                Kingdom
              </li>
              <li>
                Our first game type, battles, combines lucky dice rolls and
                skill of choosing strong Ponyjacks and/or teams
              </li>
              <li>
                For now, they are "auto-battlers," AND you are auto entered to
                compete/win prizes simply by owning Ponyjacks!
              </li>
              <li>
                To get a Ponyjack, grab a Mystery Box or bid on our collection
                when we mint. Welcome to the Ponyverse and GOOD LUCK!
              </li>
              <li>
                Special Unlocks with Diamond Horseshoes and potential Evolutions
              </li>{" "}
            </ol>
            <p>
              You can also view the PonyDex{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1OyKu30C15RXwWXrCSNMbgfuHPLLMZo19Q1xCx4_v-m8/edit#gid=1853840857"
                className="text-blue-600"
                target="_blank"
              >
                here
              </a>
            </p>
          </div>
          <div className="bg-white p-2 rounded-lg shadow-md mt-4 dark:bg-slate-900">
            <PonyDexTable />
          </div>
          <div className=" p-2 rounded-lg shadow-md mt-4 dark:bg-slate-900 container mt-4 p-24 flex flex-col justify-center items-center">
            <p className="text-2xl font-bold mb-4">Arenas and Kingdoms</p>
            <img
              src={
                "https://beige-rival-marsupial-405.mypinata.cloud/ipfs/QmfS67FLbgYHTMYQq7hmVFN72hrFqxTsSNsjJ6goekYNwf/AllArenasPic.png"
              }
              alt="Allarenaspic"
              className=" h-1/2 px-4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PonyDex;

import { a } from "react-spring";
import Bonuses from "./Bonuses.json";
export const BonusFinder = ({ currentRound, teamID }) => {
  const JsonData = currentRound && JSON.parse(currentRound?.json_data);
  const atk_current_life = JsonData?.basic?.atk_current_life;
  const def_current_life = JsonData?.basic?.def_current_life;
  const atk_damage_received = JsonData?.basic?.atk_damage_received;
  const def_damage_received = JsonData?.basic?.def_damage_received;
  const attackingTeamID = parseInt(JsonData?.basic?.attackingTeamID);
  const damage_received = def_damage_received;
  const def_damageArr = JsonData?.def_damageArr;
  const defenderNft = def_damageArr?.[0];
  const current_life =
    teamID === attackingTeamID ? atk_current_life : def_current_life;
  const atk_team_roster = JsonData?.atk_team_roster;
  const def_team_roster = JsonData?.def_team_roster;
  const defendingTeamID = JsonData?.defendingTeamID;
  const team_roster =
    teamID === attackingTeamID ? atk_team_roster : def_team_roster;
  const active_team_pony = team_roster?.[0]?.active;
  const dead_team_pony = team_roster?.[0].dead;
  const jobArray = JsonData?.jobArr;
  const isSpeedRoll = jobArray?.filter((item) => item === "speed").length > 0;
  const rollResultsArray = JsonData?.rollResults || [];
  const getAllRolls = (rollResultsArray) => {
    const allRolls = [];

    rollResultsArray.forEach((obj) => {
      const key = Object.keys(obj)[0]; // Extract the key (like 'attack', 'dodge', ...)
      const { team_id, result } = obj[key]; // Extract 'team_id' and 'result' from the inner object
      if (teamID === team_id) allRolls.push({ diceRolls: result, type: key });
    });

    return allRolls;
  };

  const allRolls = getAllRolls(rollResultsArray);

  const diceRollsArray = [];
  allRolls.forEach((roll) => {
    diceRollsArray.push(roll.diceRolls);
  });

  var Arena = 0;
  var Classic = 0;
  var Fire = 0;
  var Mythic = 0;
  var Sky = 0;
  var Earth = 0;
  var Aqua = 0;

  diceRollsArray.forEach((diceRoll) => {
    Arena += Number(diceRoll.arena || "0");
    Classic += Number(diceRoll.classic || "0");
    Fire += Number(diceRoll.fire || "0");
    Mythic += Number(diceRoll.mythic || "0");
    Sky += Number(diceRoll.sky || "0");
    Earth += Number(diceRoll.earth || "0");
    Aqua += Number(diceRoll.aqua || "0");
  });

  //check which has the highest value
  var highest = Math.max(Arena, Classic, Fire, Mythic, Sky, Earth, Aqua);
  if (highest === 0) return null;
  var name = "";
  if (highest === Arena) {
    name = "Arena";
  } else if (highest === Classic) {
    name = "Classic";
  } else if (highest === Fire) {
    name = "Fire";
  } else if (highest === Mythic) {
    name = "Mythic";
  } else if (highest === Sky) {
    name = "Sky";
  } else if (highest === Earth) {
    name = "Earth";
  } else if (highest === Aqua) {
    name = "Aqua";
  }

  var baseAnimation = Bonuses.filter((item) => item.name === name)?.[0];
  var fileName = baseAnimation?.file;
  var allBonus = [];
  if (Arena !== 0)
    allBonus.push({
      name: "Arena",
      value: Arena,
      fileName: Bonuses.filter((item) => item.name === "Arena")?.[0]?.file,
    });
  if (Classic !== 0)
    allBonus.push({
      name: "Classic",
      value: Classic,
      fileName: Bonuses.filter((item) => item.name === "Classic")?.[0]?.file,
    });
  if (Fire !== 0)
    allBonus.push({
      name: "Fire",
      value: Fire,
      fileName: Bonuses.filter((item) => item.name === "Fire")?.[0]?.file,
    });
  if (Mythic !== 0)
    allBonus.push({
      name: "Mythic",
      value: Mythic,
      fileName: Bonuses.filter((item) => item.name === "Mythic")?.[0]?.file,
    });
  if (Sky !== 0)
    allBonus.push({
      name: "Sky",
      value: Sky,
      fileName: Bonuses.filter((item) => item.name === "Sky")?.[0]?.file,
    });
  if (Earth !== 0)
    allBonus.push({
      name: "Earth",
      value: Earth,
      fileName: Bonuses.filter((item) => item.name === "Earth")?.[0]?.file,
    });
  if (Aqua !== 0)
    allBonus.push({
      name: "Aqua",
      value: Aqua,
      fileName: Bonuses.filter((item) => item.name === "Aqua")?.[0]?.file,
    });

  return {
    fileName,
    name,
    highest,
    allBonus,
  };
};

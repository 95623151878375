import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";

export default function Switcher() {
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("mode");
    return savedMode || "light"; // Default to "light" if no saved mode is found
  });

  useEffect(() => {
    // Retrieve saved mode from localStorage
    const savedMode = localStorage.getItem("mode");
    if (savedMode) {
      document.documentElement.className = savedMode;
      setMode(savedMode);
    }

    // Retrieve saved layout from localStorage
    const savedLayout = localStorage.getItem("layout");
    if (savedLayout) {
      document.documentElement.dir = savedLayout;
    }
  }, []); // Run only once on component mount

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500,
      smooth: true,
    });
  };

  function changeMode(mode, event) {
    switch (mode) {
      case "mode":
        const newMode = document.documentElement.className.includes("dark")
          ? "light"
          : "dark";
        // Save mode to localStorage
        localStorage.setItem("mode", newMode);
        document.documentElement.className = newMode;
        setMode(newMode);
        break;
      case "layout":
        const newLayout = event.target?.innerText === "LTR" ? "ltr" : "rtl";
        // Save layout to localStorage
        localStorage.setItem("layout", newLayout);
        document.documentElement.dir = newLayout;
        break;

      default:
        break;
    }
  }

  return (
    <>
      <a
        href="#"
        onClick={scrollToTop}
        id="back-to-top"
        className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-violet-600 text-white leading-9 justify-center"
      >
        <i className="uil uil-arrow-up"></i>
      </a>

      <div className="fixed top-[25%] -left-2 z-50 hidden sm:block">
        <span className="relative inline-block rotate-90">
          <input
            type="checkbox"
            className="checkbox opacity-0 absolute"
            id="chk"
            onClick={(event) => changeMode("mode", event)}
            defaultChecked={mode === "dark"} // Use defaultChecked instead of checked
          />
          <label
            className={`label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8 ${
              mode === "dark" ? "justify-end" : "justify-start"
            }`}
            htmlFor="chk"
          >
            <i className="uil uil-moon text-[20px] text-yellow-500"></i>
            <i className="uil uil-sun text-[20px] text-yellow-500"></i>
            <span className="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] w-7 h-7"></span>
          </label>
        </span>
      </div>
    </>
  );
}

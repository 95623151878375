import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import AnimatedBackground from "../../components/animatedBackground";
import { Fade, Slide, AttentionSeeker } from "react-awesome-reveal";

const baseURL =
  "https://beige-rival-marsupial-405.mypinata.cloud/ipfs/QmfS67FLbgYHTMYQq7hmVFN72hrFqxTsSNsjJ6goekYNwf/";

const sections = [
  {
    id: "select-ponyjack",
    title: "Select Your Ponyjack",
    content:
      "Choose from a variety of Ponyjacks, each with unique abilities and traits. Your choice of Ponyjack will determine your strategy in battles.",
    image: baseURL + "Apejack.png",
  },
  {
    id: "join-create-battle",
    title: "Join/Create a Battle",
    content:
      "Join an existing battle from the lobby or create your own. Engage in 1v1, 2v2, 3v3, and more exciting battles with other players.",
    image: baseURL + "Titanjack.png",
  },
  {
    id: "customize-your-stable",
    title: "Customize Your Stable",
    content:
      "Personalize your stable with unique themes, backgrounds, and decorations. Make your stable a reflection of your style and personality.",
    image: baseURL + "Goldjack.png",
  },
  {
    id: "train-your-ponyjack",
    title: "Train Your Ponyjack",
    content:
      "Train your Ponyjack to improve its stats and abilities. Engage in training sessions and mini-games to make your Ponyjack stronger and more skilled.",
    image: baseURL + "Spiritjack.png",
  },
  {
    id: "collect-artifacts",
    title: "Collect Artifacts",
    content:
      "Discover powerful artifacts that can enhance your Ponyjack's performance. Collect and equip these artifacts strategically to gain an advantage in battles.",
    image: baseURL + "Lavajack.png",
  },
  {
    id: "join-quests-and-adventures",
    title: "Join Quests and Adventures",
    content:
      "Embark on exciting quests and adventures in the Ponyverse. Solve puzzles, defeat challenges, and uncover hidden treasures with your Ponyjack.",
    image: baseURL + "Strangejack.png",
  },
  {
    id: "compete-in-championships",
    title: "Compete in Championships",
    content:
      "Participate in prestigious championships and tournaments. Test your Ponyjack's skills against the best trainers and aim for championship glory.",
    image: baseURL + "Terrorjack.png",
  },
  {
    id: "socialize-with-other-players",
    title: "Socialize with Other Players",
    content:
      "Connect with a vibrant community of Ponyjack trainers. Join clubs, chat with friends, and trade Ponyjacks to expand your network.",
    image: baseURL + "Holojack.png",
  },
];
export default function Whitepaper() {
  return (
    <>
      <Navbar />
      <AnimatedBackground />
      <section className="px-4 relative mt-8 ">
        <div className="container mx-auto min-h-screen">
          <h2 className="text-4xl font-bold text-gray-800 dark:text-white mb-6 text-center animate-fade-in-down">
            How to Play
          </h2>
          <section className="py-8 mt-4 grid md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <div
                key={section.id}
                id={section.id}
                className={`${
                  index % 2 === 0 ? "md:col-start-1" : "md:col-start-2"
                }`}
              >
                <div className="bg-green dark:bg-dark-green dark:bg-opacity-70 p-6 rounded-lg shadow-md border-2 border-gray-300 dark:border-gray-800">
                  <div className="md:flex flex-col justify-center items-center gap-4">
                    <div className="md:w-1/2 z-10">
                      <h3 className="text-3xl font-semibold text-gray-800 dark:text-white mb-2 text-left md:text-center">
                        {section.title}
                      </h3>
                    </div>
                    <div className="md:flex justify-center items-center gap-2">
                      <p className="text-gray-600 dark:text-gray-300 text-lg text-left md:text-center mb-2">
                        {section.content}
                      </p>
                      <img
                        src={section.image}
                        alt="Ponyjack"
                        className={`mx-auto md:ml-0 w-3/4  -top-12 z-0 opacity-80 ${
                          index % 2 === 0 ? "left-2" : "right-2"
                        } md:relative md:top-0 md:w-full md:mx-0`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}

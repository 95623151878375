import "./App.css";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Index from "./pages/index/index";
import { useEffect, useContext } from "react";
import React from "react";
import IndexSeven from "./pages/index/index-seven";
import Contact from "./pages/contact";
import About from "./pages/about";
import Creators from "./pages/creator/creators";
import MyStable from "./pages/creator/my-stable";
import CreatorProfileEdit from "./pages/creator/profile-edit";
import BecomeCreator from "./pages/creator/become-creator";
import Kingdoms from "./pages/arena/kingdoms";
import BlogDetail from "./pages/blog/blog-detail";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import ResetPassword from "./pages/auth/reset-password";
import Comingsoon from "./pages/special/comingsoon";
import Maintenance from "./pages/special/maintenance";
import Error from "./pages/special/error";
import Thankyou from "./pages/special/thankyou";
import Terms from "./pages/terms";
import Support from "./pages/helpcenter/support";
import Guides from "./pages/helpcenter/guides";
import ItemDetail from "./pages/arena/item-detail";
import TournamentDetails from "./pages/arena/tournament-detail";
import IndexTwo from "./pages/index/index-two";
import IndexTen from "./pages/index/index-ten";
import Admin from "./pages/admin/admin";
import IndexThree from "./pages/index/index-three";
import IndexFour from "./pages/index/index-four";
import IndexFive from "./pages/index/index-five";
import IndexSix from "./pages/index/index-six";
import IndexEight from "./pages/index/index-eight";
import IndexNine from "./pages/special/mint";
import Activity from "./pages/arena/activity";
import Leaderboard from "./pages/arena/leaderboard";
import Wallet from "./pages/wallet";
import UploadWork from "./pages/arena/upload-work";
import HelpcenterFaqs from "./pages/helpcenter/helpcenter-faqs";
import PrivacyPolicy from "./pages/privacy-policy";
import LockScreen from "./pages/auth/lock-screen";
import HelpcenterOverview from "./pages/helpcenter/helpcenter-overview";
import ScrollToTop from "./components/scroll-top";
import ExploreTwo from "./pages/arena/lobby";
import ExploreThree from "./pages/arena/explore-three";
import { UserProvider } from "./store/UserContext";
import { BattleProvider } from "./store/BattleContext";
import NotificationService from "./components/NotificationService";
import { createConfig, configureChains, WagmiConfig } from "wagmi";
import { publicProvider } from "wagmi/providers/public";
import { mainnet } from "wagmi/chains";
import BattleScreen from "./pages/arena/battlescreen";
import { UserContext } from "./store/UserContext";

import Whitepaer from "./pages/special/whitepaper";

function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");

    document.body.classList.add(
      "font-urbanist",
      "text-base",
      "text-black",
      "dark:text-white",
      "dark:bg-green-900"
    );
  });
  const { publicClient, webSocketPublicClient } = configureChains(
    [mainnet],
    [publicProvider()]
  );

  const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
  });

  function ProtectedRoute({ children, ...rest }) {
    // const navigate = useNavigate();
    // const { user, account } = useContext(UserContext);

    // useEffect(() => {
    //   // If user is not authenticated, redirect to login
    //   console.log(user);
    //   if (!account) {
    //     navigate("/login");
    //   }
    // }, [user, navigate]);

    // return account ? children : null;
    return children;
  }

  return (
    <WagmiConfig config={config}>
      <NotificationService />
      <UserProvider>
        <BattleProvider>
          <BrowserRouter>
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Index />} />
              <Route path="/mint" element={<IndexNine />} />
              <Route path="/admin" element={<Admin />} />
              <Route
                path="/battlescreen"
                element={
                  <ProtectedRoute>
                    <BattleScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/leaderboard"
                element={
                  <ProtectedRoute>
                    <Leaderboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/activity"
                element={
                  <ProtectedRoute>
                    <Activity />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/lobby"
                element={
                  <ProtectedRoute>
                    <ExploreTwo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/item-detail"
                element={
                  <ProtectedRoute>
                    <ItemDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/kingdoms"
                element={
                  <ProtectedRoute>
                    <Kingdoms />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mystable"
                element={
                  <ProtectedRoute>
                    <MyStable />
                  </ProtectedRoute>
                }
              />
              <Route path="/profile-edit" element={<CreatorProfileEdit />} />
              <Route
                path="/whitepaper"
                element={
                  <ProtectedRoute>
                    <Whitepaer />
                  </ProtectedRoute>
                }
              />

              {/* <Route path="/index-seven" element={<IndexSeven />} />
              <Route path="/index-two" element={<IndexTwo />} />
              <Route path="/index-three" element={<IndexThree />} />
              <Route path="/index-ten" element={<IndexTen />} />
              <Route path="/index-eight" element={<IndexEight />} />
              <Route path="/wallet" element={<Wallet />} />
              <Route path="/explore-three" element={<ExploreThree />} />
              <Route path="/index-four" element={<IndexFour />} />
              <Route path="/upload-work" element={<UploadWork />} />
              <Route path="/index-six" element={<IndexSix />} />
              <Route path="/index-five" element={<IndexFive />} />

              <Route path="/helpcenter-guides" element={<Guides />} />
              <Route path="/helpcenter-support" element={<Support />} />
              <Route path="/helpcenter-support" element={<Support />} />
              <Route path="/helpcenter-faqs" element={<HelpcenterFaqs />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<Terms />} /> */}
              <Route path="/comingsoon" element={<Comingsoon />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/error" element={<Error />} />
              <Route path="/thankyou" element={<Thankyou />} />

              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/lock-screen" element={<LockScreen />} />
              <Route
                path="/helpcenter-overview"
                element={<HelpcenterOverview />}
              />

              <Route path="/blog-detail" element={<BlogDetail />} />

              <Route path="/become-creator" element={<BecomeCreator />} />
              <Route path="/players" element={<Creators />} />
              <Route path="/aboutus" element={<About />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </BrowserRouter>
        </BattleProvider>
      </UserProvider>
    </WagmiConfig>
  );
}

export default App;

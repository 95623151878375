import React, { useContext, useEffect, useState } from "react";

import logo_icon_28 from "../assets/images/logo-pony.png";
import logo_dark from "../assets/images/logo-pony.png";
import logo_white from "../assets/images/logo-pony.png";
import image from "../assets/images/avatar/1.png";
import { Link } from "react-router-dom";
import { UserContext } from "../store/UserContext";

export default function Navbar() {
  const [isDropdown, openDropdown] = useState(true);
  const [isOpen, setMenu] = useState(true);
  const { user, account, balance, connectMetaMask, disconnect } =
    useContext(UserContext);
  useEffect(() => {
    activateMenu();
  }, []); // An empty dependency array ensures this useEffect runs once when component mounts

  window.addEventListener("scroll", windowScroll);
  function windowScroll() {
    const navbar = document.getElementById("topnav");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      if (navbar !== null) {
        navbar?.classList.add("nav-sticky");
      }
    } else {
      if (navbar !== null) {
        navbar?.classList.remove("nav-sticky");
      }
    }

    const mybutton = document.getElementById("back-to-top");
    if (mybutton != null) {
      if (
        document.body.scrollTop > 500 ||
        document.documentElement.scrollTop > 500
      ) {
        mybutton.classList.add("flex");
        mybutton.classList.remove("hidden");
      } else {
        mybutton.classList.add("hidden");
        mybutton.classList.remove("flex");
      }
    }
  }

  const toggleMenu = () => {
    setMenu(!isOpen);
    if (document.getElementById("navigation")) {
      const anchorArray = Array.from(
        document.getElementById("navigation").getElementsByTagName("a")
      );
      anchorArray.forEach((element) => {
        element.addEventListener("click", (elem) => {
          const target = elem.target.getAttribute("href");
          if (target !== "") {
            if (elem.target.nextElementSibling) {
              var submenu = elem.target.nextElementSibling.nextElementSibling;
              submenu.classList.toggle("open");
            }
          }
        });
      });
    }
  };

  const getClosest = (elem, selector) => {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(
              s
            ),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;
  };
  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {
      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add("active");

        var immediateParent = getClosest(matchingMenuItem, "li");

        if (immediateParent) {
          immediateParent.classList.add("active");
        }

        var parent = getClosest(immediateParent, ".child-menu-item");
        if (parent) {
          parent.classList.add("active");
        }

        var parent = getClosest(parent || immediateParent, ".parent-menu-item");

        if (parent) {
          parent.classList.add("active");

          var parentMenuitem = parent.querySelector(".menu-item");
          if (parentMenuitem) {
            parentMenuitem.classList.add("active");
          }

          var parentOfParent = getClosest(parent, ".parent-parent-menu-item");
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        } else {
          var parentOfParent = getClosest(
            matchingMenuItem,
            ".parent-parent-menu-item"
          );
          if (parentOfParent) {
            parentOfParent.classList.add("active");
          }
        }
      }
    }
  };

  const ProtectedBar = () => {
    const UserExists =
      user?.owner_name && user?.owner_name !== "" ? true : false;
    return UserExists ? (
      <div
        id="navigation"
        className={`${isOpen === true ? "hidden" : "block"}`}
      >
        <ul className="navigation-menu justify-end">
          <li className="has-submenu parent-menu-item">
            <Link to="/"> Home</Link>
          </li>
          <li className="has-submenu parent-menu-item">
            <Link to="/mint"> Mint</Link>
          </li>
          <li className="has-submenu parent-parent-menu-item">
            <Link to="#">Arena</Link>
            <span className="menu-arrow"></span>
            <ul className="submenu">
              <li>
                <Link to="/lobby" className="sub-menu-item">
                  {" "}
                  Lobby
                </Link>
              </li>
              <li>
                <Link to="/activity" className="sub-menu-item">
                  {" "}
                  History
                </Link>
              </li>
            </ul>
          </li>
          <li className="has-submenu parent-parent-menu-item">
            <Link to="#/leaderboard">Leaderboard</Link>
            <span className="menu-arrow"></span>
            <ul className="submenu">
              {/* <li className="has-submenu parent-menu-item">
                <Link to="/kingdoms" className="sub-menu-item">
                  {" "}
                  Kingdoms
                </Link>
              </li> */}
              <li className="has-submenu parent-menu-item">
                <Link to="/leaderboard" className="sub-menu-item">
                  {" "}
                  General
                </Link>
              </li>
            </ul>
          </li>
          <li className="has-submenu parent-parent-menu-item">
            <Link to="#">Info</Link>
            <span className="menu-arrow"></span>
            <ul className="submenu">
              <li>
                <Link to="/aboutus" className="sub-menu-item">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/whitepaper" className="sub-menu-item">
                  Whitepaper
                </Link>
              </li>

              <li className="has-submenu parent-menu-item">
                <Link to="#"> Special </Link>
                <span className="submenu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <Link to="/comingsoon" className="sub-menu-item">
                      {" "}
                      Coming Soon
                    </Link>
                  </li>
                  <li>
                    <Link to="/maintenance" className="sub-menu-item">
                      {" "}
                      Maintenance
                    </Link>
                  </li>
                  <li>
                    <Link to="/error" className="sub-menu-item">
                      {" "}
                      404!
                    </Link>
                  </li>
                  <li>
                    <Link to="/thankyou" className="sub-menu-item">
                      {" "}
                      Thank you
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-submenu parent-menu-item">
                <Link to="#"> Help Center </Link>
                <span className="submenu-arrow"></span>
                <ul className="submenu">
                  <li>
                    <Link to="/helpcenter-overview" className="sub-menu-item">
                      {" "}
                      Overview
                    </Link>
                  </li>
                  <li>
                    <Link to="/helpcenter-faqs" className="sub-menu-item">
                      {" "}
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link to="/helpcenter-guides" className="sub-menu-item">
                      {" "}
                      Guides
                    </Link>
                  </li>
                  <li>
                    <Link to="/helpcenter-support" className="sub-menu-item">
                      {" "}
                      Support
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li>
                    <Link to="/terms" className="sub-menu-item">
                      Terms Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" className="sub-menu-item">
                      Privacy Policy
                    </Link>
                  </li> */}
            </ul>
          </li>
          {/* <li className="has-submenu parent-menu-item ml-4">
            <Link to="/admin" className="sub-menu-item">
              {" "}
              Admin Setup
            </Link>
          </li>{" "} */}
        </ul>
      </div>
    ) : (
      <div
        id="navigation"
        className={`${isOpen === true ? "hidden" : "block"}`}
      >
        <ul className="navigation-menu justify-center">
          <li className="has-submenu parent-menu-item">
            <Link to="/login"> Login</Link>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <nav
        id="topnav"
        className="is-sticky dark:bg-slate-900 rounded dark:bg-opacity-50"
      >
        <div className="container justify-content-between flex-wrap flex-md-nowrap align-items-center py-2">
          {/* <!-- Logo container--> */}

          <Link className="logo flex justify-center items-center mt-4" to="/">
            <img
              src={logo_icon_28}
              alt="Pony Jacks"
              className="logo-light me-2 w-10 h-10"
            />
            <h1 className=" font-bold lg:leading-snug leading-snug text-xl  text-slate dark:text-white/7 sm:ml-2 ml-0">
              Pony
              <span className="bg-gradient-to-r from-green-500 to-green-900 text-transparent bg-clip-text">
                Jacks
              </span>
            </h1>
          </Link>
          <div className="menu-extras">
            <div className="menu-item">
              {/* <!-- Mobile menu toggle--> */}
              <a className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </div>
          </div>
          {/* <!--Login button Start--> */}
          <ul className="buy-button list-none mb-0">
            <li className="inline-block ps-1 mb-0">
              <div id="accountDisplay"></div>
              <a
                onClick={() => connectMetaMask(true)}
                id="connectWallet"
                className="btn btn-icon rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"
              >
                <i className="uil uil-wallet"></i>
              </a>
            </li>

            <li className="dropdown inline-block relative ps-1">
              <button
                onClick={() => openDropdown(!isDropdown)}
                data-dropdown-toggle="dropdown"
                className="dropdown-toggle btn btn-icon rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white inline-flex"
                type="button"
              >
                <img src={image} className="rounded-full" alt="" />
              </button>

              <div
                className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 ${
                  isDropdown ? "hidden" : "block"
                }`}
              >
                <div className="relative">
                  <div className="py-8 bg-gradient-to-tr from-violet-600 to-red-600"></div>
                  <div className="absolute px-4 -bottom-7 start-0">
                    <div className="flex items-end">
                      <img
                        src={image}
                        className="rounded-full w-10 h-w-10 shadow dark:shadow-gray-700"
                        alt=""
                      />

                      <span className="font-semibold text-[15px] ms-1">
                        {user?.owner_name}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="mt-10 px-4">
                  <h5 className="font-semibold text-[15px]">Wallet:</h5>
                  {account && (
                    <div className="flex items-center justify-between">
                      <span className="text-[13px] text-slate-400">
                        {account?.slice(0, 6) + "..." + account?.slice(-4)}
                      </span>

                      <button
                        className="text-violet-600"
                        onClick={() =>
                          window.open(
                            `https://polygonscan.com/address/${account}`
                          )
                        }
                      >
                        <i className="uil uil-link"></i>
                      </button>
                      <button
                        className="text-violet-600"
                        onClick={() => navigator.clipboard.writeText(account)}
                      >
                        <i className="uil uil-copy"></i>
                      </button>
                    </div>
                  )}
                </div>

                <div className="mt-4 px-4">
                  <h5 className="text-md">
                    Balance:{" "}
                    <span className="text-sm text-violet-600 font-semibold">
                      {balance} PONYJACK
                    </span>
                  </h5>
                </div>

                <ul className="py-2 text-start">
                  <li>
                    <Link
                      to="/mystable"
                      className="block text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600"
                    >
                      <i className="uil uil-user text-[16px] align-middle me-1"></i>{" "}
                      My Stable
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/profile-edit"
                      className="block text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600"
                    >
                      <i className="uil uil-setting text-[16px] align-middle me-1"></i>{" "}
                      Edit Profile
                    </Link>
                  </li>
                  <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                  <li>
                    <button
                      className="block text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600"
                      onClick={disconnect}
                    >
                      <i className="uil uil-sign-out-alt text-[16px] align-middle me-1"></i>{" "}
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
          {<ProtectedBar />}
        </div>
      </nav>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../store/UserContext";
import TransactionPopup from "./transactionPopup";
import {
  RarityGradient,
  default as AttributeDisplay,
} from "../components/attributeDisplay";

export default function BattleHistory(props) {
  const { showButton, battleData } = props;
  const [showPopup, setShowPopup] = useState(false);
  const [details, setDetails] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate(); // <-- Add this line
  const [page, setPage] = useState(1);
  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    selectedBattle,
    setSelectedBattle,
  } = useContext(UserContext);

  const buttonData = [
    {
      type: "All",
      icon: "uil uil-apps",
    },
    {
      type: "1v1",
      icon: "uil uil-user",
    },
    {
      type: "Free 1v1",
      icon: "uil uil-user-plus",
    },
    {
      type: "2v2",
      icon: "uil uil-users-alt",
    },
    {
      type: "3v3",
      icon: "uil uil-user-square", // General icon as a placeholder
    },
    {
      type: "4v4",
      icon: "uil uil-user-circle", // General icon as a placeholder
    },
    {
      type: "5v5",
      icon: "uil uil-tag", // General icon as a placeholder
    },
    {
      type: "Paid Tourney",
      icon: "uil uil-trophy",
    },
    {
      type: "Free Tourney",
      icon: "uil uil-gift",
    },
  ];

  const onReplayClick = (item) => {
    setSelectedBattle(item);
    const battle_id = item.battle_id;
    navigate("/battlescreen?battle_id=" + battle_id); //navigate to battle page, with selected battle
    //navigate to battle page, with selected battle
  };

  const onWithdrawClick = (item) => {
    var chain_state = item?.pendingBattle?.chain_state;
    var amount = chain_state?.split("/")?.[1]?.split("/")?.[0];
    var doubledAmount = parseFloat(amount) * 1.98;
    setDetails(
      "Withdraw Battle Winnings Battle ID: " +
        item.battle_id +
        " Amount: " +
        doubledAmount.toFixed(4)
    );
    setJsonData(item);
    setShowPopup(true);
    //withdraw the battle
    console.log("withdraw battle", item);
  };

  const shiftPage = (direction) => {
    if (direction === "left") {
      if (page > 1) {
        setPage(page - 1);
      }
    } else {
      if (page < Math.ceil(battleData?.length / 8)) {
        setPage(page + 1);
      }
    }
  };

  const formatDate = (date) => {
    //get current time
    var currentTime = new Date();
    //convert current time to UTC
    currentTime = new Date(
      currentTime.getTime() + currentTime.getTimezoneOffset() * 60000
    );
    var timestamp = new Date(date);
    var usTimezoneOffset = -390;
    timestamp = new Date(timestamp.getTime() + usTimezoneOffset * 60000);
    var difference = currentTime.getTime() - timestamp.getTime();
    var seconds = parseFloat(difference / 1000).toFixed(0);
    var minutes = parseFloat(seconds / 60).toFixed(0);
    var hours = parseFloat(minutes / 60).toFixed(0);
    var days = parseFloat(hours / 24).toFixed(0);
    return days < 1
      ? hours < 1
        ? minutes < 1
          ? seconds + " seconds"
          : minutes + " minutes"
        : hours + " hours"
      : days + " days";
  };

  return !show && showButton ? (
    <div className="flex-center flex-col items-center">
      <button className="btn btn-lg btn-primary" onClick={() => setShow(true)}>
        <i className="mdi mdi-arrow-right-bold"></i>
      </button>
    </div>
  ) : (
    <div className="w-full  flex-center flex flex-col items-center justify-top">
      {showButton && (
        <button
          className="btn btn-lg btn-primary"
          onClick={() => setShow(false)}
        >
          <i className="mdi mdi-arrow-up-bold"></i>
        </button>
      )}
      {showPopup && (
        <TransactionPopup
          setShow={setShowPopup}
          show={showPopup}
          name="Withdraw"
          details={details}
          jsonData={jsonData}
        />
      )}{" "}
      {battleData?.slice((page - 1) * 8, page * 8)?.map((item, index) => (
        <div
          className={`group rounded-xl  shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto lg:max-w-2xl
                mt-4 bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 flex-center flex-col items-center p-4
                `}
          key={index}
        >
          <div
            className={`grid  lg:grid-cols-${
              item.battleHistory.length > 3 ? item.battleHistory.length / 2 : 2
            } gap-4`}
          >
            {item.battleHistory?.map((item2, index2) => (
              <div
                className={`group relative rounded-lg transition-all p-2 duration-500 flex-col flex justify-center items-center
                ${
                  item2.userInfo?.owner_name === item.winner
                    ? "bg-green-500 bg-opacity-20 dark:bg-green-500 dark:bg-opacity-20"
                    : "bg-red-500 bg-opacity-20 dark:bg-red-500 dark:bg-opacity-20"
                }
                `}
              >
                <RarityGradient
                  rarity={item2.nftData?.rarity}
                  kingdom1={item2.nftData?.arena_type}
                  kingdom2={item2.nftData?.sub_arena_type}
                />{" "}
                <img
                  className={`object-cover rounded-xl max-h-${40} max-w-40`}
                  src={item2.nftData?.image}
                  alt=""
                />
                {item2?.userInfo?.owner_name === item.winner && (
                  <i className="mdi mdi-trophy-variant text-lg text-yellow-500 text-lg absolute -top-2 -end-2 "></i>
                )}
                <span className="font-bold">{item2.nftData?.title}</span>{" "}
                <AttributeDisplay
                  attributes={item2.nftData?.attributes}
                  iconSize={item.battleHistory.length < 5 ? 8 : 4}
                />
                <div className="flex items-center">
                  <img
                    className="w-4 h-4 rounded-full"
                    src={item2.userInfo?.profile_image}
                    alt=""
                  />
                  <h6 className="ms-1 text-sm">{item2.userInfo?.owner_name}</h6>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="absolute z-40-bottom-10 hover:bottom-1/2 hover:translate-y-20 start-0 end-0 mx-auto text-center transition-all duration-500">
                <div className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white">
                  <button onClick={() => selectItemDetails(item)}>
                    <i className="mdi mdi-lightning-bolt"></i>
                    Details
                  </button>{" "}
                </div>
              </div> */}
          <div className="p-4 w-full">
            <ul className="flex justify-between items-center mb-4">
              <li className=" items-center">
                <span className="bg-slate-900 text-white dark:bg-slate-800 text-s px-2.5 py-1 font-semibold rounded-full ">
                  Battle
                </span>
              </li>
              <li className=" items-center">
                <span className="bg-slate-900 text-white dark:bg-slate-800 text-s px-2.5 py-1 font-semibold rounded-full ">
                  {item.battle_type || "1v1"}{" "}
                  <i
                    className={`${
                      buttonData.filter(
                        (button) => button.type === item.battle_type
                      )[0]?.icon || "uil uil-user"
                    }`}
                  ></i>
                </span>
              </li>
              <li>
                <span className="text-slate-900 dark:text-white text-s font-semibold">
                  {formatDate(item.created) + " ago" || "1d 2h ago"}
                </span>
              </li>
            </ul>

            <span className="text-3xl font-semibold block text-slate-900 dark:text-white flex-center flex text-center">
              <i className="mdi mdi-ethereum me-2 text-yellow-600"></i>
              {item.price || "1.00 ETH"}
            </span>

            <div className="flex justify-center flex-col items-center">
              <button
                className="btn btn-sm rounded-full bg-green-600 hover:bg-green-700 border-green-600 hover:border-green-700 text-white"
                onClick={() => onReplayClick(item)}
              >
                View Replay
              </button>

              {item.pending && item.winner === user?.owner_name && (
                <button
                  className="btn mt-4 btn-sm rounded-full bg-blue-600 hover:bg-blue-700 border-blue-600 hover:border-blue-700 text-white"
                  onClick={() => onWithdrawClick(item)}
                >
                  Withdraw
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
      {battleData?.length > 8 ? (
        <div className="grid  grid-cols-1 mt-8">
          <div className="md:col-span-2 text-center">
            <nav>
              <ul className="inline-flex items-center space-x-2">
                <li>
                  <button
                    onClick={() => shiftPage("left")}
                    className="inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                  >
                    <i className="uil uil-angle-left text-[20px]"></i>
                  </button>
                </li>
                {battleData?.length > 8 ? (
                  [...Array(Math.ceil(battleData.length / 8)).keys()].map(
                    (item, index) => (
                      <li key={index}>
                        <button
                          className={`btn inline-flex justify-center items-center mx-1 p-4 rounded-xl text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600
                                    ${
                                      page === item + 1
                                        ? "text-violet-600 bg-violet-600 dark:bg-violet-600 dark:text-white"
                                        : ""
                                    }`}
                          onClick={() => setPage(item + 1)}
                        >
                          {item + 1}
                        </button>
                      </li>
                    )
                  )
                ) : (
                  <></>
                )}
                <li>
                  <button
                    className="inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                    onClick={() => shiftPage("right")}
                  >
                    <i className="uil uil-angle-right text-[20px]"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

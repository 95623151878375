import React from "react";

const roadmapData = [
  {
    phase: "Phase 0 - Current State",
    description:
      "This phase focuses on the current state of the Ponyjacks project.",
    goals: [
      "Continue to run Weekly Shows, Battles, and Tourneys, some Free some Paid for Prizes (ETH, Star Horseshoes, Artifacts, Special Ponyjacks…)",
      "Continue to drop Mystery Boxes. We’re at about 50 and may continue dropping with increasing prices up to around 100. Good chance we pause leading up to the new site launch to build hype and market the next wave of boxes at higher mints.",
      "Currently about 10-20 really solid community members who have made it an absolute pleasure to be building this project for! My hope is to create something truly special for all of you!",
      "Fitz has been a superstar building the current battle sheet and continues to make incredible progress on the backend for the new engine. We’ve grabbed some artists for attack animations and are building out the rest of the team.",
    ],
  },
  {
    phase: "Phase 1 - Lobby + Battles 2023",
    description:
      "In Phase 1, we focus on launching a new site with lobby and battle features.",
    goals: [
      "New site should be ready for beta launch with Wallet connect, stable creation, naming feature",
      "Stable viewer to see all your Ponyjacks/artifacts/horseshoes/history",
      "Entry Lobby for free and paid events (Star Horseshoe/ETH)",
      "Various event types: (1v1, 2v2, 3v3, 4v4, 5v5)",
      "*potentially* special events: (tourneys, boss battles, onslaught, king of the hill, ponywar 12v12)",
      "Filters for event parameters: visibility Blind/Open entry, set/random teams, payouts winners/survivors",
      "*potentially* functionally equippable artifacts",
      "Live battles feed with new attack animations to make watching events exciting and spicy",
      "Replays of battles you missed",
    ],
  },
  {
    phase: "Phase 2 - Racing, Traits, Breeding 2024",
    description:
      "Phase 2 introduces racing, traits, and breeding mechanics to Ponyjacks.",
    goals: [
      "Tackle Racing with lifelike simulations, 1000m dashes, where horses 'reroll' new totals based on their traits, stats, environment, and competition.",
      "Possibility of introducing team races with new dynamics",
      "Possibility of track ownership, 8 tracks 1 for each of the 8 kingdoms of the Ponyverse",
      "Make Ponyjacks Unique NFTs through Traits, with Gen 0 Ponyjacks reminted as 1 of 1s with personality + physical traits",
      "Explore special traits and potential negative traits",
      "Gen 0 Ponyjacks will be the strongest Ponyjacks of the project by far with higher starting stats and LIFE stat for breeding.",
      "Breeding mechanics introduced with potential for twins, mutations, and trait inheritance.",
    ],
  },

  {
    phase: "Phase 3 - Spirit, Joust, Ponyball",
    description:
      "In Phase 3, we introduce Spirit/Soul, Joust, and Ponyball events to the Ponyjacks world, expanding the gameplay experience.",
    goals: [
      "Add Spirit/Soul, a hidden trait that affects horse performance across different events. It may take the form of a hidden trait similar to those in Phase 2 or involve more complex mechanics like a power ranking system.",
      "Explore the concept of Joust, which may involve launching a line of gladiator NFTs or making Ponyjacks NFTs compatible as riders atop Ponyjacks. We may also introduce additional strategy options such as choosing weapons, armor types, and charge styles.",
      "Conceptualize Ponyball, a web3 sport phenomenon that brings FIFA, NBA2K, or Rocket League-like team tactics to the Ponyverse. This could include new stats for Ponyjacks like jump, agility, style, trickshot, and more. The exact mechanics of Ponyball are open for community input and development, and it's yet to be determined whether it will be another auto-battler or if players will take direct control of their Ponyjacks.",
    ],
  },
  {
    phase: "Marketing",
    description:
      "Marketing is an essential part of the Ponyjacks project, and we plan to promote it in waves as we complete each phase of development.",
    goals: [
      "Collaborate with other web3 projects to expand our reach and create synergies within the blockchain gaming community.",
      "Launch our own social media campaigns to engage with our community and attract new players and investors.",
      "The first marketing wave will occur as soon as we have a functional site with the entry lobby and live battles, with the goal of continuing to mint the original 1000 Gen 0 Ponyjacks.",
      "Each subsequent marketing wave will coincide with the completion of major development milestones, ensuring that the project gains visibility and momentum as it progresses.",
    ],
  },
];

export default function Roadmap() {
  return (
    <div className="py-8 mt-4">
      <div className="container mx-auto">
        <h2 className="text-4xl font-bold text-gray-800 dark:text-white mb-6 text-center animate-fade-in-down">
          Roadmap
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {roadmapData.map((phase, index) => (
            <div
              key={index}
              className="mb-4 bg-green-200 dark:bg-green-200  dark:bg-opacity-20 p-4 rounded-lg shado border-2 border-gray-300 dark:border-gray-800"
            >
              <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4 text-center">
                {phase.phase}
              </h3>
              <ul className="list-disc list-inside">
                {phase.goals.map((item, itemIndex) => (
                  <li
                    key={itemIndex}
                    className="text-gray-600 dark:text-gray-300 mb-1 text-sm text-left font-semibold
                    transition duration-100 ease-in-out transform hover:-translate-y-1  hover:bg-slate-900 hover:z-50 rounded-lg
                    hover:text-green-300 dark:hover:text-slate-100 hover:shadow-lg dark:hover:shadow-xl hover:text-xl
                    "
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

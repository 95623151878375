import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { UserContext } from "../../store/UserContext";
const PonyDexTable = () => {
  const { allNFTs } = useContext(UserContext);

  const numColumns = 8;
  const Rarities = [
    {
      name: "Unique",
      color: "bg-purple-600",
      description: "1 of 1",
    },
    {
      name: "Legendary",
      color: "bg-yellow-600",
      description: "3",
    },
    {
      name: "Super Rare",
      color: "bg-blue-600",
      description: "7",
    },
    {
      name: "Rare",
      color: "bg-green-600",
      description: "15",
    },
    {
      name: "Common",
      color: "bg-gray-600",
      description: "21",
    },
  ];
  const elements = [
    {
      name: "Earth",
      color: "bg-yellow-800",
      description: "Defense",
    },
    {
      name: "Life",
      color: "bg-green-700",
      description: "Health",
    },
    {
      name: "Aqua",
      color: "bg-blue-400",
      description: "Speed",
    },
    {
      name: "Fire",
      color: "bg-red-500",
      description: "Attack",
    },
    {
      name: "Sky",
      color: "bg-blue-800",
      description: "Intelligence",
    },
    {
      name: "Dark",
      color: "bg-gray-700",
      description: "Dodge",
    },
    {
      name: "Mythic",
      color: "bg-pink-400",
      description: "Special",
    },
    {
      name: "Classic",
      color: "bg-yellow-300 bg-opacity-60",
      description: "Energy",
    },
  ];

  const PonyCell = ({ pony, rarityColor }) => {
    return (
      <td
        className={`px-2 ${rarityColor} border border-gray-100 dark:border-gray-800 p-2 hover:transform hover:scale-10 transition-all duration-500`}
      >
        <div className="text-center">
          <div className="shadow-lg rounded-lg overflow-hidden transition transform hover:scale-105">
            <img
              src={pony.image}
              alt={pony.name}
              className=" text-xs mx-auto  max-w-[50px] max-h-[50px]"
            />
            <p className="text-xs text-white font-bold  mt-2">{pony.title}</p>
          </div>
        </div>
      </td>
    );
  };

  function getCurrentRow(rarityRows, preferredIndex) {
    // Find last row < max size
    return rarityRows.find(
      (row) => !row[preferredIndex] || row[preferredIndex] == <td></td>
    );
  }

  //get all rarityTypes for allNFts
  const allRarityTypes = [];
  for (let i = 0; i < allNFTs.length; i++) {
    var rarity = allNFTs[i].rarity;
    //check if rarity is already in array
    if (allRarityTypes.filter((r) => r.name === rarity).length !== 0) {
      //increase amount by 1
      allRarityTypes.find((r) => r.name === rarity).amount += 1;
    } else {
      allRarityTypes.push({
        name: rarity,
        amount: 1,
      });
    }
  }
  console.log(allRarityTypes);

  const tableRows = {
    Unique: [],
    Legendary: [],
    "Super Rare": [],
    Rare: [],
    Common: [],
  };
  //sort all nfts matching elements array
  var SortedNFTs = allNFTs.sort((a, b) => {
    var ElementIndexA = elements.findIndex((element) => {
      return element.name === a.arena_type;
    });
    var ElementIndexB = elements.findIndex((element) => {
      return element.name === b.arena_type;
    });
    return ElementIndexA - ElementIndexB;
  });

  for (let i = 0; i < SortedNFTs.length; i++) {
    var pony = SortedNFTs[i];
    var name = pony.title;
    if (name == "Mimejack")
      console.log("Mimejack", pony.arena_type, pony.rarity);

    var rarity = pony.rarity;
    const kingdom1 = pony.arena_type;
    //find bgcolor for rarity
    var rarityColor = Rarities.find((r) => r.name === rarity).color;
    var data = <PonyCell pony={pony} rarityColor={rarityColor} />;
    //check if there is a row for this rarity
    const rarityRow = tableRows[rarity];
    var preferredIndex = elements.findIndex((element) => {
      return element.name === kingdom1;
    });
    //check if there is a row for this rarity
    if (rarityRow.length === 0) {
      //create new row
      tableRows[rarity].push([data]);
    } else {
      //get current row
      var currentRow = getCurrentRow(rarityRow, preferredIndex);
      if (currentRow) {
        //add to current row
        if (preferredIndex !== 0) {
          for (let j = 0; j < preferredIndex; j++) {
            if (currentRow[j] == undefined)
              currentRow.push(
                <td className="border border-gray-100 dark:border-gray-800"></td>
              );
          }
        }
        currentRow[preferredIndex] = data;
      } else {
        //create new row
        var newRow = [];
        //if preferred index is not 0, add empty cells
        if (preferredIndex !== 0) {
          for (let j = 0; j < preferredIndex; j++) {
            newRow.push(
              <td className="border border-gray-100 dark:border-gray-800"></td>
            );
          }
        }
        newRow.push(data);
        tableRows[rarity].push(newRow);
      }
    }
  }
  console.log(tableRows);

  return (
    <div className="bg-gray-100 min-h-screen dark:bg-slate-900 dark:text-gray-200 max-w-full mx-auto">
      <div className="overflow-x-auto">
        <table className="w-full">
          <tr>
            <th className="px-2 text-center text-xl bg-blue-500 text-white">
              Rarity
            </th>
            {elements.map((element) => (
              <td
                key={element.name}
                className={`${element.color} text-center  text-xl text-white`}
              >
                <p className="text-xl font-bold">{element.name}</p>
                <p className="text-l">{"(" + element.description + ")"}</p>
              </td>
            ))}
          </tr>
          <tbody className="text-center">
            {Object.values(Rarities).map((rarity) => (
              <>
                {tableRows[rarity.name].map((row, index) => (
                  <tr className=" text-center" key={index}>
                    {index == 0 ? (
                      <td
                        className={`${rarity.color} text-center text-xl font-bold`}
                      >
                        <p className="text-xl">{rarity.name}</p>{" "}
                        <p className="text-l">Each {rarity.description} </p>
                      </td>
                    ) : (
                      <td className="border border-gray-100 dark:border-gray-800"></td>
                    )}
                    {row.map((cell, index2) => cell)}
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PonyDexTable;

import React, { useContext, useEffect, useState } from "react";
import image1 from "../../assets/images/avatar/1.png";
import image2 from "../../assets/images/items/2.gif";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../store/UserContext";
import { BattleContext } from "../../store/BattleContext";
import BattleList from "../../components/battleList";
import LobbyCard from "./LobbyCard";
import ConfirmationPopup from "../../components/ConfirmationPopup";
export default function BattleLobby() {
  const [page, setPage] = useState(1);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedBattle, setSelectedBattle] = useState(null);
  const navigate = useNavigate(); // <-- Add this line

  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    connectMetaMask,
    showModal,
    setShowModal,
    disconnect,
  } = useContext(UserContext);

  const { activeBattles, cancelBattle, getUnjoinedBattles } =
    useContext(BattleContext);

  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    if (user) {
      getUnjoinedBattles();
    }
  }, [user]);

  const shiftPage = (direction) => {
    if (direction === "left") {
      if (page > 1) {
        setPage(page - 1);
      }
    } else {
      if (page < Math.ceil(allBattles().length / 8)) {
        setPage(page + 1);
      }
    }
  };

  const handleDetailsClick = (item) => {
    selectItemDetails(item);
    navigate("/item-detail");
    console.log(item);
  };

  const handleJoin = (item) => {
    console.log("Joining battle", item);
    var selectedBattle = {
      battleId: item.id,
      player1: {
        name: user?.owner_name || "John",
        avatar: user?.avatar || image2,
        ponyjacks: [],
      },
      player2: {
        name: item.owner?.owner_name || "John",
        avatar: item?.owner?.avatar || image1,
        ponyjacks: item.ponyjacks,
      },
      price: item.price || 1,
      betToken: item.betToken || "",
      type: item.type || "2v2",
      ...item,
    };

    setSelectedBattle(selectedBattle);
    setShowConfirmationPopup(true);
  };

  const onRetryClick = (item) => {
    console.log("Retrying battle", item);
  };

  const handleCancel = async (item) => {
    console.log("Cancelling battle", item);
    var actionData = {
      _battleId: item.id,
    };
    await cancelBattle(actionData);
  };

  const rarityOptions = [
    "All",
    "Common",
    "Rare",
    "Super Rare",
    "Legendary",
    "Unique",
  ];

  const sortOptions = ["Lowest Entry", "Lower Rated"];

  const [battleType, setBattleType] = useState("All");
  const [rarity, setRarity] = useState("All");
  const [sortOption, setSortOption] = useState("Lowest Entry");
  const [searchText, setSearchText] = useState("");

  const buttonData = [
    {
      type: "1v1",
      icon: "uil uil-user",
    },
    {
      type: "Free 1v1",
      icon: "uil uil-user-plus",
    },
    {
      type: "2v2",
      icon: "uil uil-users-alt",
    },
    {
      type: "3v3",
      icon: "uil uil-user-square", // General icon as a placeholder
    },
    {
      type: "4v4",
      icon: "uil uil-user-circle", // General icon as a placeholder
    },
    {
      type: "5v5",
      icon: "uil uil-tag", // General icon as a placeholder
    },
    {
      type: "Paid Tourney",
      icon: "uil uil-trophy",
    },
    {
      type: "Free Tourney",
      icon: "uil uil-gift",
    },
  ];

  const allBattles = () => {
    var nfts = activeBattles;
    console.log(nfts);
    nfts = nfts.map((nft) => {
      return {
        id: nft.id,
        isActive: nft.isActive,
        owner: nft.challenger,
        avatar: image1,
        price: parseFloat(nft.betAmount) / 10 ** 18,
        type: nft.tokenIds.length + "v" + nft.tokenIds.length,
        numPlayers: nft.tokenIds.length,
        ponyjacks: nft.tokenIds,
        betToken: nft.betToken,
        constraints: nft.constraints,
        errored: nft.errored,
      };
    });
    if (rarity !== "All")
      nfts = nfts.filter(
        (nft) =>
          nft.ponyjacks?.filter((item) => item.rarity === rarity).length > 0
      );
    if (battleType !== "All")
      nfts = nfts.filter((nft) => nft.type === battleType);
    if (sortOption === "Lowest Entry")
      nfts = nfts.sort((a, b) => a.price - b.price);
    if (sortOption === "Lower Rated")
      nfts = nfts.sort((a, b) => a.totalBattles - b.totalBattles);
    if (searchText !== "")
      nfts = nfts.filter(
        (nft) =>
          nft.title?.includes(searchText) ||
          nft.subtext?.includes(searchText) ||
          nft.owner?.owner_name?.includes(searchText)
      );
    console.log(nfts);
    return nfts;
  };

  const [sideBarOption, setSideBarOption] = useState("Active Battles");
  const [show, setShow] = useState(false);

  const handleSelectOption = (option) => {
    setSideBarOption(option);
    setShow(true);
  };

  const SideBardOptions = () => {
    var options = [
      {
        name: "Active Battles",
        icon: "uil uil-user",
      },
      {
        name: "Open Battles",
        icon: "uil uil-user",
      },
      {
        name: "My Events",
        icon: "uil uil-user",
      },
    ];
    return options.map((option, index) => (
      <div
        key={index}
        className={`flex items-center justify-between p-2 rounded-md hover:bg-violet-600 dark:hover:bg-violet-600 cursor-pointer ${
          sideBarOption === option.name ? "bg-green-600 dark:bg-green-600" : ""
        }`}
        onClick={() => handleSelectOption(option.name)}
      >
        <div className="flex items-center text-white">
          <i className={`${option.icon} text-xl`}></i>
          <span className="ml-2">{option.name}</span>
        </div>
        <div className="flex items-center">
          <span className="text-xs bg-violet-600 dark:bg-violet-600 text-white rounded-full px-2 py-1">
            3
          </span>
        </div>
      </div>
    ));
  };

  return (
    <>
      <ConfirmationPopup
        show={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        battle={selectedBattle}
      />
      <section className="relative min-h-screen py-2">
        <div className="mx-auto">
          <div className="grid p-8 lg:grid-cols-12 md:grid-cols-9 grid-cols-6 gap-[30px]">
            <div className="col-span-2 md:col-span-2 lg:col-span-3">
              <div className="shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                <form>
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <label
                        htmlFor="searchname"
                        className="form-label font-semibold dark:text-white text-[15px]"
                      >
                        Search By Name or Owner:
                      </label>
                      <div className="relative mt-2">
                        <i className="uil uil-search text-lg absolute top-[6px] start-3"></i>
                        <input
                          name="search"
                          id="searchname"
                          type="text"
                          className="form-input w-full py-2 px-3 ps-10 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0"
                          placeholder="Search"
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="mt-2">
                      <label className="form-label font-semibold dark:text-white text-[15px]">
                        Rarity:
                      </label>
                      <select
                        className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0"
                        value={rarity}
                        onChange={(e) => setRarity(e.target.value)}
                      >
                        {rarityOptions.map((option, index) => (
                          <option key={index}>{option}</option>
                        ))}
                      </select>
                    </div>

                    <div className="mt-2">
                      <label className="form-label font-semibold dark:text-white text-[15px]">
                        Battle Type:
                      </label>
                      <select
                        className="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0"
                        value={battleType}
                        onChange={(e) => setBattleType(e.target.value)}
                      >
                        {buttonData
                          .concat([
                            {
                              type: "All",
                              icon: "uil uil-user",
                            },
                          ])
                          .map((option, index) => (
                            <option key={index}>
                              <i className={option.icon}> {option.type}</i>
                            </option>
                          ))}
                      </select>
                    </div>

                    <div className="mt-2">
                      <label class="form-label font-semibold dark:text-white text-[15px]">
                        Sort By:
                      </label>
                      <select
                        class="form-select form-input mt-2 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0"
                        value={sortOption}
                        onChange={(e) => setSortOption(e.target.value)}
                      >
                        {sortOptions.map((option, index) => (
                          <option key={index}>{option}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="lg:col-span-9 md:col-span-7 col-span-4">
              <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[30px]">
                {allBattles()
                  .slice((page - 1) * 10, page * 10)
                  .map((item, index) => (
                    <LobbyCard
                      item={item}
                      index={index}
                      gameType={item.type}
                      onDetailsClick={handleDetailsClick}
                      onJoinClick={handleJoin}
                      onRetryClick={handleJoin}
                      onCancelClick={
                        item?.owner.owner_wallet_id === user?.owner_wallet_id
                          ? handleCancel
                          : null
                      }
                    />
                  ))}
              </div>
              {allBattles().length > 10 ? (
                <div className="grid  grid-cols-1 mt-8">
                  <div className="md:col-span-2 text-center">
                    <nav>
                      <ul className="inline-flex items-center space-x-2">
                        <li>
                          <button
                            onClick={() => shiftPage("left")}
                            className="inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                          >
                            <i className="uil uil-angle-left text-[20px]"></i>
                          </button>
                        </li>
                        {allBattles().length > 10 ? (
                          [
                            ...Array(
                              Math.ceil(allBattles().length / 10)
                            ).keys(),
                          ].map((item, index) => (
                            <li key={index}>
                              <button
                                className={`btn inline-flex justify-center items-center mx-1 p-4 rounded-xl text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600
                                    ${
                                      page === item + 1
                                        ? "text-violet-600 bg-violet-600 dark:bg-violet-600 dark:text-white"
                                        : ""
                                    }`}
                                onClick={() => setPage(item + 1)}
                              >
                                {item + 1}
                              </button>
                            </li>
                          ))
                        ) : (
                          <></>
                        )}
                        <li>
                          <button
                            className="inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                            onClick={() => shiftPage("right")}
                          >
                            <i className="uil uil-angle-right text-[20px]"></i>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* {sidebar ? (
                <div className="fixed z-10 right-4 m-2 top-20 rounded-md p-2 text-slate-900 dark:text-white max-h-[75%] md:max-w-[50%] flex-col sm:w-full  justify-top items-center flex mt-4 space-y-8 bg-slate-900 border-2 border-gray-100 dark:border-slate-600 ">
                  <button
                    onClick={() => setSidebar(false)}
                    className="btn btn-xl rounded-lg bg-violet-400 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white text-center"
                  >
                    <div className="text-xl">
                      Hide
                      <i className="uil uil-angle-double-left"></i>
                    </div>
                  </button>
                  <SideBardOptions />
                  <div
                    className="flex-col  rounded-md shadow dark:shadow-gray-700 dark:bg-slate-900 bg-opacity-50 dark:bg-opacity-30 bg-violet-300 border-2 border-gray-100 dark:border-slate-600 text-slate-900 dark:text-white
                overflow-y-auto max-h-[60%] p-4 w-full
                "
                  >
                    <BattleList
                      showButton={true}
                      show={show}
                      setShow={setShow}
                    />
                  </div>{" "}
                </div>
              ) : (
                <div className="lg:col-span-1 md:col-span-1 flex-col col-span-1 rounded-md  p-4  text-slate-900 dark:text-white justify-top items-center flex mt-4 space-y-8 fixed z-10 right-5 top-20">
                  <button
                    onClick={() => setSidebar(true)}
                    className="btn btn-xs  rounded-lg bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white text-center"
                  >
                    <div className="text-l">
                      History
                      <i className="uil uil-angle-double-right"></i>
                    </div>
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import { showSuccessToast } from "../../components/NotificationService";
import image1 from "../../assets/images/avatar/1.png";
import image2 from "../../assets/images/items/2.gif";
import image3 from "../../assets/images/avatar/2.jpg";
import image4 from "../../assets/images/items/3.jpg";
import image5 from "../../assets/images/items/3.gif";
import image6 from "../../assets/images/items/4.jpg";
import image7 from "../../assets/images/avatar/3.jpg";
import image8 from "../../assets/images/items/5.jpg";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../../components/attributeDisplay";
import { UserContext } from "../../store/UserContext";
import { BattleContext } from "../../store/BattleContext";
import ArenaSwitcher from "../../components/arenaSwitcher";

export default function KingdomsLeaderboard() {
  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    connectMetaMask,
    showModal,
    setShowModal,
    disconnect,
  } = useContext(UserContext);

  //const {kingdomData, leaderboardData} = useContext(BattleContext);

  const [selectedType, setSelectedType] = useState("Ponyjacks"); // "Ponyjacks" or "Stables"
  const kingdomData = [
    // Example data for the kingdoms
    { kingdom: "Fire", players: 100, color: "text-red-500", points: 1000 },
    { kingdom: "Aqua", players: 100, color: "text-blue-500", points: 1000 },
    { kingdom: "Earth", players: 100, color: "text-green-500", points: 1000 },
    { kingdom: "Dark", players: 100, color: "text-gray-500", points: 1000 },
    {
      kingdom: "Classic",
      players: 100,
      color: "text-yellow-500",
      points: 1000,
    },
    { kingdom: "Life", players: 100, color: "text-green-500", points: 1000 },
    { kingdom: "Sky", players: 100, color: "text-blue-500", points: 1000 },
    {
      kingdom: "Mythic",
      players: 100,
      color: "text-purple-500",
      points: 1000,
    },
    // ... more kingdom data ...
  ];
  var leaderboardData = [];
  for (var i = 0; i < 100; i++) {
    var randomImage = Math.floor(Math.random() * 6) + 1;
    randomImage =
      randomImage === 6
        ? image6
        : randomImage === 5
        ? image5
        : randomImage === 4
        ? image4
        : randomImage === 3
        ? image3
        : randomImage === 2
        ? image2
        : image1;

    leaderboardData.push({
      stable: "Stable" + i,
      points: Math.floor(Math.random() * 1000),
      kingdom:
        kingdomData[Math.floor(Math.random() * kingdomData.length)].kingdom,
      avatar: randomImage,
    });
  }
  leaderboardData = leaderboardData.sort((a, b) => b.points - a.points);
  var ponyData =
    user?.nfts
      ?.filter((nft) => nft.category === "Ponyjacks")
      ?.map((nft) => {
        return {
          name: nft.title,
          image: nft.image,
          attributes: nft.attributes,
          points: Math.floor(Math.random() * 1000),
          kingdom: nft.arena_type || "Fire",
        };
      })
      ?.sort((a, b) => b.points - a.points) || [];
  ponyData = ponyData.concat(ponyData).concat(ponyData).concat(ponyData); // Duplicate the data to make it longer (for testing

  const prizePool = 5000; // Example prize pool value
  const [selectedArena, setSelectedArena] = useState(null);

  const getPositionString = (position) => {
    //returns the position string for the leaderboard
    var positionString = "";
    var lastDigit = position > 10 && position < 20 ? 0 : position % 10;
    var subText = "th";
    switch (lastDigit) {
      case 1:
        subText = "st";
        break;
      case 2:
        subText = "nd";
        break;
      case 3:
        subText = "rd";
        break;
      default:
        subText = "th";
    }
    positionString = position + subText;
    return positionString;
  };

  const saveChanges = () => {
    //saves the changes to the user's kingdom
    showSuccessToast("Kingdom saved successfully");
  };

  return (
    <>
      <Navbar />

      <section className="relative table w-full py-24 bg-[url('../../assets/images/Ponyjacks/ArenaMultiPic.png')] bg-top bg-no-repeat">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container mx-auto px-4">
          <div className="text-center mt-10">
            <h3 className="text-2xl md:text-5xl font-medium text-white">
              Kingdom Leaderboard
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Ponyjacks</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Kingdoms
            </li>
          </ul>
        </div>
      </section>
      <section className="py-8 min-h-screen">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
            <div className="text-2xl text-green-400 font-bold text-center rounded-lg dark:bg-slate-900 dark:bg-opacity-70  bg-gray-100 p-2 mb-4 flex justify-center items-center rounded-lg shadow-lg border-2 border-green-400">
              <h3 className="text-2xl font-semibold text-center mb-4 w-full flex-col overflow-hidden">
                <h3 className="text-2xl font-semibold text-center mb-1 w-full">
                  My Kingdom
                </h3>
                {user?.pledged_kingdom || selectedArena ? (
                  <div className=" mb-4 w-full flex-col">
                    <div className="text-2xl font-semibold text-center w-full text-slate-900 dark:text-white">
                      {selectedArena}
                    </div>
                    <button
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded btn-primary btn-lg btn"
                      onClick={() => saveChanges()}
                    >
                      Save Changes
                    </button>
                  </div>
                ) : (
                  <div className=" mb-4 w-full flex-col">
                    <div className="text-2xl font-semibold text-center w-full text-slate-900 dark:text-white">
                      None
                    </div>
                  </div>
                )}
                <ArenaSwitcher
                  selectedArena={selectedArena}
                  setSelectedArena={setSelectedArena}
                />
              </h3>
              <h3 className="text-2xl font-semibold text-center w-full flex-col overflow-hidden">
                <h3 className="text-2xl font-semibold text-center mb-4 w-full">
                  My Rank
                </h3>
                <i className="mdi mdi-trophy-variant text-2xl text-yellow-500">
                  {user?.rank || "21st"}
                </i>
              </h3>
            </div>
            <div
              className="text-2xl text-green-400 font-bold text-center rounded-lg dark:bg-slate-900 bg-gray-100 flex justify-center items-center rounded-lg 
              shadow-lg border-2 border-green-400 w-full h-[250px] space-x-4  dark:bg-opacity-70 "
            >
              <div className="text-2xl font-semibold text-center mb-4 w-full flex-col overflow-hidden space-y-4">
                <div className="text-2xl font-bold text-center mb-4 w-full">
                  Prize Pool
                </div>
                <i className="mdi mdi-ethereum text-2xl text-violet-400">
                  ${prizePool}
                </i>
              </div>
              <div className="text-2xl font-bold text-center mb-4 w-full flex-col overflow-hidden">
                <div className="text-2xl font-bold text-center mb-4 w-full">
                  Top 3 Stables
                </div>
                <i className="mdi mdi-trophy-variant text-2xl text-yellow-500"></i>
              </div>
              <div className="text-2xl font-bold text-center mb-4 w-full overflow-hidden flex-col space-y-4 justify-center items-center">
                Time Remaining
                <div className="text-2xl font-bold text-slate-400 mb-4 w-full">
                  28D: 12H: 59M: 59S
                  <i className="mdi mdi-timer text-2xl text-yellow-500"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3 mb-8">
            <div className="shadow-md rounded-lg border-t-2 border-blue-400 max-w-2xl  max-h-screen overflow-x-hidden overflow-y-auto mx-auto mb-4">
              <h3 className="text-2xl font-bold text-center w-full flex-col overflow-hidden text-green-600 dark:text-white dark:bg-slate-900 dark:bg-opacity-70 p-2">
                Kingdoms
              </h3>{" "}
              <table className="w-full table-auto overflow-hidden shadow-md rounded-lg border-l-2 border-r-2 border-blue-400">
                <thead className="bg-gray-900 text-white dark:bg-slate-900 overflow-hidden shadow-md rounded-lg">
                  <tr>
                    <th className="px-auto py-4 font-semibold text-center">
                      Position
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Kingdom
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Total Players
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Prize Split
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {kingdomData.map((entry, index) => (
                    <tr
                      className={
                        index % 2 === 0
                          ? "dark:bg-slate-900 bg-gray-100 text-2xl font-semibold dark:bg-opacity-80"
                          : "dark:bg-slate-900 bg-gray-100 text-2xl font-semibold dark:bg-opacity-70"
                      }
                    >
                      <td className="py-3 px-2 text-violet-300">
                        {getPositionString(index + 1)}
                      </td>
                      <td className="py-3 px-2 text-green-500 flex items-center justify-center space-x-4">
                        <div className={`${entry.color} flex items-center`}>
                          {entry.kingdom}
                        </div>
                        <AttributeIcon
                          trait={"Element"}
                          value={entry.kingdom}
                          size={8}
                        />{" "}
                      </td>
                      <td className="py-3 px-2 text-green-300 text-center">
                        100
                      </td>
                      <td className="py-3 px-2 text-red-400 font-bold text-center">
                        1{" "}
                        <i className="mdi mdi-ethereum text-2xl text-violet-500"></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>{" "}
            <div className="shadow-md rounded-lg border-t-2 border-blue-400 max-w-2xl  max-h-screen overflow-x-hidden overflow-y-auto mx-auto mb-4">
              <h3 className="text-2xl font-bold text-center w-full flex-col overflow-hidden text-green-600 dark:text-white dark:bg-slate-900 dark:bg-opacity-70 p-2">
                Stables
              </h3>{" "}
              <table className="w-full table-auto overflow-hidden shadow-md rounded-lg ">
                <thead className="bg-slate-900 text-white dark:bg-slate-900 overflow-hidden shadow-md rounded-lg">
                  <tr>
                    <th className="px-auto py-4 font-semibold text-center">
                      Position
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Stable
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Points
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Pledged Kingdom
                    </th>
                  </tr>
                </thead>
                <tbody className="max-w-2xl mx-auto">
                  {leaderboardData.map((entry, index) => (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0
                          ? "dark:bg-slate-900 bg-gray-100 text-2x font-semibold dark:bg-opacity-80"
                          : "dark:bg-slate-900 bg-gray-100 text-2x font-semibold dark:bg-opacity-70"
                      }
                    >
                      <td className="py-3 px-6 text-violet-300 font-semibold text-center shadow-md text-xl">
                        {getPositionString(index + 1)}
                      </td>
                      <td className="flex py-3 px-2  text-green-500 items-center justify-center">
                        <img
                          className="rounded-full  h-8 w-8 object-cover hover:transform-gpu hover:scale-110 transition-all duration-200 ease-in-out shadow-md"
                          alt={""}
                          src={entry.avatar}
                        />{" "}
                        <div className="py-3 px-2 text-lg font-semibold text-center">
                          {entry.stable}
                        </div>
                      </td>
                      <td className="py-3 px-2 text-violet-300 font-bold text-center shadow-md text-xl">
                        {entry.points}
                      </td>
                      <td className="py-3 px-2 text-green-500 flex items-center justify-center space-x-4">
                        <AttributeIcon
                          trait={"Element"}
                          value={entry.kingdom}
                          size={8}
                        />{" "}
                        <div
                          className={`${
                            kingdomData.filter(
                              (kingdom) => kingdom.kingdom === entry.kingdom
                            )[0].color
                          } flex items-center`}
                        >
                          {entry.kingdom}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="shadow-md rounded-lg border-t-2 border-blue-400 max-w-xl  max-h-screen overflow-y-auto mx-auto mb-4">
              <h3 className="text-2xl font-bold text-center w-full flex-col overflow-hidden text-green-600 dark:text-white dark:bg-slate-900 dark:bg-opacity-70 p-2">
                Ponyjacks
              </h3>
              <table className="w-full table-auto overflow-hidden shadow-md rounded-lg">
                <thead className="bg-slate-900 text-white dark:bg-slate-900 overflow-hidden shadow-md rounded-lg">
                  <tr>
                    <th className="px-auto py-4 font-semibold text-center">
                      Position
                    </th>
                    <th className="py-4 px-auto font-semibold text-center">
                      Name
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Points
                    </th>
                    <th className="px-auto py-4 font-semibold text-center">
                      Pledged Kingdom
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ponyData?.map((entry, index) => (
                    <tr
                      key={index}
                      className={
                        index % 2 === 0
                          ? "dark:bg-slate-900 bg-gray-100 text-2x font-semibold dark:bg-opacity-80"
                          : "dark:bg-slate-900 bg-gray-100 text-2x font-semibold dark:bg-opacity-70"
                      }
                    >
                      <td className="py-3 px-6 text-violet-300 font-semibold text-center shadow-md text-xl">
                        {getPositionString(index + 1)}
                      </td>
                      <td className="flex py-3 px-2  text-green-500 items-center justify-center">
                        <img
                          className="rounded-full  h-8 w-8 object-cover hover:transform-gpu hover:scale-110 transition-all duration-200 ease-in-out shadow-md"
                          alt={image6}
                          src={entry.image}
                        />{" "}
                        <div className="text-lg font-semibold text-center w-full ml-2">
                          {entry.name}
                        </div>
                      </td>
                      <td className="py-3 px-2 text-violet-300 font-bold text-center shadow-md text-xl">
                        {entry.points}
                      </td>
                      <td className="py-3 px-2 text-green-500 flex items-center justify-center space-x-4">
                        <AttributeIcon
                          trait={"Element"}
                          value={entry.kingdom}
                          size={8}
                        />{" "}
                        <div
                          className={`${
                            kingdomData.filter(
                              (kingdom) => kingdom.kingdom === entry.kingdom
                            )[0].color
                          } flex items-center`}
                        >
                          {entry.kingdom}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../store/UserContext";

export default function CollectionTwo(props) {
  const { title, data } = props;
  const navigate = useNavigate(); // <-- Add this line
  const { selectItemDetails } = useContext(UserContext);
  console.log(data);
  const handleClick = (item) => {
    if (!item.fullData) return;
    selectItemDetails(item.fullData);
    navigate("/item-detail");
  };
  return (
    <>
      <div className="mt-4">
        <div
          className={`grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]`}
        >
          {data.map((item, index) => (
            <div
              className="group rounded-2xl bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 overflow-hidden ease-in-out duration-500 w-full mx-auto 
                mt-4
                "
            >
              <img src={item.image} className="rounded-lg" alt="" />

              <div className="relative p-4 -mt-14">
                <div className="relative inline-block">
                  <img
                    src={item.avatar}
                    className="h-16 rounded-md shadow-md dark:shadow-gray-800"
                    alt=""
                  />
                  <span className="inline-block w-8 h-8 text-xl font-bold text-center bg-green-500 rounded-full border-2 border-white dark:border-slate-900">
                    {index + 1}
                  </span>
                  {index + 1 === 1 && (
                    <i className="mdi mdi-crown text-2  xl text-yellow-400 "></i>
                  )}
                </div>

                <div className="mt-3">
                  <button
                    onClick={() => handleClick(item)}
                    className="font-semibold block text-lg text-violet-600 transition-all duration-500"
                  >
                    {item.title}
                  </button>
                  <span className="text-slate-400 mt-1 text-md">
                    <span className="italic"></span> {item.owner}
                  </span>
                  <span className="text-slate-400 block text-md mt-1 border-b border-slate-300 pb-2 justify-between flex">
                    Total Battles{" "}
                    <h4 className="text-violet-600 font-bold">
                      {item.totalBattles}
                    </h4>
                  </span>
                  <span className="text-slate-400 block text-md mt-1 border-b border-slate-300 pb-2 justify-between flex">
                    Won{" "}
                    <h4 className="text-yellow-600 font-bold">
                      {item.wonBattles}
                    </h4>
                  </span>
                  <span className="text-slate-400 block text-md mt-1 border-b border-slate-300 pb-2 justify-between flex">
                    Earnings{" "}
                    <h4 className="text-green-600 font-bold">
                      {item.totalEarnings}
                      <i className="mdi mdi-ethereum"></i>
                    </h4>
                  </span>
                  <span className="text-slate-400 block text-md mt-1 border-b border-slate-300 pb-2 justify-between flex">
                    Loss{" "}
                    <h4 className="text-red-600 font-bold">
                      {item.totalLosses}
                      <i className="mdi mdi-ethereum"></i>
                    </h4>
                  </span>{" "}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../store/UserContext";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../components/attributeDisplay";
import FilterComponent from "../components/filterDisplay";
import { showErrorToast } from "../components/NotificationService";

export default function Collections(props) {
  const { selectedItem } = useContext(UserContext);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { data, selectedNFTs, setSelectedNFTs, battleType } = props;

  const [searchInput, setSearchInput] = useState("");
  const [rateOption, setRateOption] = useState("All");
  const [buyOption, setBuyOption] = useState("All");
  const [option, setOption] = useState("All");
  const [toggle, setToggle] = useState(false);
  const itemWidth = window.innerWidth < 640 ? window.innerWidth / 2 : 300;

  // Change layout to grid on small screens
  const isMobile = window.innerWidth < 640;
  const containerClass = isMobile
    ? "flex overflow-x-hidden overflow-y-hidden max-w-[90%]"
    : "flex overflow-x-hidden overflow-y-hidden ";

  const scrollSlider = (direction) => {
    const slider = document.getElementById("slider");
    if (direction === "left") {
      slider.scrollLeft -= 300;
    } else {
      slider.scrollLeft += 300;
    }
    setScrollPosition(slider.scrollLeft);
  };
  const [itemsPerPage, setItemsPerPage] = useState(8);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const slider = document.getElementById("slider");
    const handleResize = () => {
      const newItemsPerPage = Math.floor(slider.clientWidth / itemWidth);
      setItemsPerPage(newItemsPerPage);
      setTotalPages(Math.ceil(data.length / newItemsPerPage));
    };

    handleResize(); // Call once to set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data.length]);
  useEffect(() => {
    const slider = document.getElementById("slider");
    const handleScroll = () => {
      const newPage = Math.floor(slider.scrollLeft / (itemsPerPage * 300)) + 1;
      setCurrentPage(newPage);
    };

    slider.addEventListener("scroll", handleScroll);
    return () => {
      slider.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const allNFTs = (data) => {
    const filteredNFTs = data.filter((nft) => {
      return (
        nft.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        searchInput === ""
      );
    });
    const sortedNFTs = filteredNFTs.sort((a, b) => {
      if (rateOption === "top") {
        return b.price - a.price;
      } else if (rateOption === "new") {
        return b.id - a.id;
      } else if (rateOption === "Highest Price") {
        return b.price - a.price;
      } else if (rateOption === "Lowest Price") {
        return a.price - b.price;
      }
    });
    const buyNFTs = sortedNFTs.filter((nft) => {
      return (
        nft.rarity?.toLowerCase().includes(buyOption.toLowerCase()) ||
        buyOption === "All"
      );
    });
    const categoryNFTs = buyNFTs.filter((nft) => {
      return (
        nft.attributes?.filter((attr) => {
          return (
            attr.value?.toLowerCase().includes(option.toLowerCase()) ||
            attr.trait_type?.toLowerCase().includes(option.toLowerCase())
          );
        }).length > 0 || option === "All"
      );
    });
    const finalNFTs = categoryNFTs;
    return finalNFTs;
  };

  const handleAddNFT = (nft) => {
    //make sure the event type matches length of selected nfts
    var maxSelectables = 1;
    if (battleType === "1v1") {
      maxSelectables = 1;
    } else if (battleType === "2v2") {
      maxSelectables = 2;
    } else if (battleType === "3v3") {
      maxSelectables = 3;
    } else if (battleType === "4v4") {
      maxSelectables = 4;
    } else if (battleType === "5v5") {
      maxSelectables = 5;
    }
    if (selectedNFTs.length >= maxSelectables && !isSelected(nft)) {
      showErrorToast("You have already selected " + maxSelectables + " NFTs");
      return;
    }
    if (isSelected(nft)) {
      handleRemoveNFT(nft);
      return;
    }
    setSelectedNFTs([...selectedNFTs, nft]);
  };

  const handleRemoveNFT = (nft) => {
    setSelectedNFTs(
      selectedNFTs.filter((item) => item.tokenId !== nft.tokenId)
    );
  };

  const isSelected = (nft) => {
    return selectedNFTs.filter((item) => item.tokenId === nft.tokenId).length >
      0
      ? true
      : false;
  };

  return (
    <div className={`container mt-4 overflow-x-hidden `}>
      <FilterComponent
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setOption={setOption}
        setBuyOption={setBuyOption}
        setRateOption={setRateOption}
        toggle={toggle}
        setToggle={setToggle}
      />
      <div className={`mx-auto `}>
        <button
          onClick={() => scrollSlider("left")}
          className="absolute z-10 left-0 mt-20 text-[2rem] btn btn-primary rounded-full p-2 bg-white dark:bg-slate-900 hover:bg-violet-600 hover:text-white dark:hover:bg-violet-600 dark:hover:text-white transition-all duration-500"
        >
          {"<"}
        </button>
        <button
          onClick={() => scrollSlider("right")}
          className="absolute z-10 right-0 mt-20 text-[2rem] btn btn-primary rounded-full p-2 bg-white dark:bg-slate-900"
        >
          {">"}
        </button>

        <div id="slider" className={`scrollbar-hide ${containerClass}`}>
          {allNFTs(data).map((item, index) => (
            <div
              key={index}
              className={`group relative rounded-lg p-4 m-4 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 h-fit ${
                isSelected(item)
                  ? "border-2 border-white bg-violet-400 dark:bg-violet-600"
                  : "bg-slate-900 dark:bg-slate-900"
              }
                `}
            >
              <div
                className="relative overflow-hidden rounded-lg transition-all duration-500 dark:shadow-gray-700 max-w-[150px]
              flex justify-center items-center"
                style={item.style} // Apply hover effect style
              >
                <img
                  src={item.image}
                  alt=""
                  className="rounded-lg mb-1 max-w-[150px]"
                />
              </div>
              <RarityGradient
                rarity={item.rarity}
                kingdom1={item.arena_type}
                kingdom2={item.sub_arena_type}
              />{" "}
              <AttributeDisplay attributes={item.attributes} iconSize={8} />
              <div className="mt-2">
                <button
                  onClick={() => handleAddNFT(item)}
                  className="btn btn-primary text-white dark:text-white  hover:bg-violet-700 dark:hover:bg-violet-800 transition-all duration-500 w-full"
                >
                  {item.title}
                </button>
              </div>
              {isSelected(item) && (
                <div className="mt-4">
                  <div className="flex flex-col items-start justify-start text-left text-white">
                    <span className="text-sm font-semibold text-slate-700 dark:text-white">
                      Token ID: {item.tokenId.toString()}
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

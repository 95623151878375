import React, { createContext, useState, useContext, useEffect } from "react";
import axios from "axios";
import Web3 from "web3"; // Import Web3 library
import { UserContext } from "./UserContext";
import contractABI from "./battleAbi.json";
import tokenAbi from "./tokenAbi.json";
import SingerAbi from "./SignerAbi.json";
import { validator } from "web3-validator";
import { toast } from "react-toastify";
import { act } from "react-dom/test-utils";

// Create a context for Battle
export const BattleContext = createContext();

// Define the BattleProvider component
export const BattleProvider = ({ children }) => {
  const { user, selectedItem, account, allNFTs } = useContext(UserContext);
  const [activeBattles, setActiveBattles] = useState([]);
  const [selectedBattle, setSelectedBattle] = useState(null);
  const [battleHistory, setBattleHistory] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [kingdomData, setKingdomData] = useState([]);
  const [allUsers, setAllUsers] = useState([]); // [1]

  // Define your API endpoints
  const apiUrl = "https://backend.ponyjacks.app/api/battles/";
  //const apiUrl = "http://localhost:3300/api/battles/";
  const api = axios.create({
    baseURL: apiUrl, // Your API base URL
    withCredentials: true, // Enable sending and receiving cookies
    headers: {
      "Content-Type": "application/json",
    },
  });
  // Load initial data or perform any other necessary setup

  // Connect to Polygon testnet (Mumbai)
  const web3 = new Web3("https://80002.rpc.thirdweb.com");
  //check if web3 is connected

  // Your contract address and ABI
  const contractAddress = "0x1ee4fB16477B21f53e8D825B21C6A818BB910154"; // Replace with your actual contract address
  const tokenContractAddress = "0x66E5CfA3487ea1DBd08Ec85955070Ea60eff16EF";
  const signerContractAddress = "0xbd7eb5695fe647a868701fc5b4be236848786769";

  const contract = new web3.eth.Contract(contractABI, contractAddress);
  const tokenContract = new web3.eth.Contract(tokenAbi, tokenContractAddress);
  const signerContract = new web3.eth.Contract(
    SingerAbi,
    signerContractAddress
  );

  async function getBalance() {
    const balance = await web3.eth.getBalance(
      "0x7fDBAA06860Ba67d6C9d61486Af8AFcF8bF68779"
    );
    console.log(balance);
  }

  async function getAllUsers() {
    const api = "https://backend.ponyjacks.app/api/"; // Update the endpoint
    const url = api + `users`;
    const response = await axios.get(url);
    console.log(response.data);
    var users = response.data;
    setAllUsers(users);
    console.log(allUsers);
  }

  async function createBattle(actionData) {
    try {
      // Battle data
      console.log(actionData);
      const _tokenIds = actionData?._tokenIds;
      const _betToken = actionData?._betToken;
      const _betAmount = actionData?._betAmount;
      const _maxEntry = actionData?._maxEntry;
      const _maxMultiEntry = actionData?._maxMultiEntry;

      const _battleConstraints = actionData?._battleConstraints;

      const data = contract.methods
        .createBattle(
          _tokenIds,
          _betToken,
          _betAmount,
          _maxEntry,
          _maxMultiEntry,
          _battleConstraints
        )
        .encodeABI();

      console.log(data);

      const allowance = await checkAllowance(_betAmount);
      const result = await sendTransaction(data, contractAddress);

      console.log(result);
      return result;
    } catch (error) {
      if (error instanceof web3.eth.Contract.ContractRevertInstructionError) {
        console.error("Transaction reverted with reason: " + error.reason);
      } else {
        console.error("Error: " + error);
      }
    }
  }

  const startBattle = async (startData) => {
    try {
      // Make an HTTP POST request to your API to join a battle
      const result = await startBattleTxn(startData);
      if (!result) {
        toast.error("Transaction pending");
      }
      const backendData = await sendStartData(startData, result);
      return backendData;
      // Handle success, update state, etc.
    } catch (error) {
      // Handle errors
      toast.error("Transaction failed");
      console.log(error);
    }
  };

  const sendStartData = async (startData, result) => {
    try {
      const backendData = {
        team1: startData?.team1,
        team2: startData?.team2,
        betToken: startData?.betToken,
        betAmount: startData?.betAmount,
        _battleId: startData?._battleId,
        transactionHash: result.hash,
      };
      console.log(backendData);
      const response = await axios.post(`${apiUrl}start`, backendData);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const signWithdraw = async (withdrawData) => {
    try {
      console.log("Signing withdraw", withdrawData);
      const battleType =
        withdrawData.battle.nr_members.toString() +
        "v" +
        withdrawData.battle.nr_members.toString();

      const response = await axios.post(`${apiUrl}withdraw`, {
        battle_id: withdrawData.battle_id,
        battleType: battleType,
      });
      console.log(response);

      const signatureFromBackend = response.data.signatureData;
      console.log(signatureFromBackend);
      const withdrawVoucherFromBackend = response.data.withdrawVoucher;
      console.log(withdrawVoucherFromBackend);

      const data = signerContract.methods
        .withdraw(withdrawVoucherFromBackend, signatureFromBackend)
        .encodeABI();
      const result = await sendTransaction(data, signerContractAddress);
      if (result.passed)
        await updateFinalBattle(
          withdrawData.battle_id,
          result.receipt.transactionHash
        );
      return result.passed;
    } catch (error) {
      console.log(error);
    }
  };

  const updateFinalBattle = async (battleId, hash) => {
    try {
      console.log("Updating final battle", battleId, hash);
      const url = `${apiUrl}updateWithdraw`;
      const data = {
        battle_id: battleId,
        hash: hash,
      };
      const response = await axios.post(url, data);
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const startBattleTxn = async (startData) => {
    // Battle data
    try {
      const _tokenIds = startData?._tokenIds;
      const _battleId = startData?._battleId;
      const betAmount = startData?.betAmount;

      const data = contract.methods
        .joinBattle(_battleId, _tokenIds)
        .encodeABI();
      const allowance = await checkAllowance(betAmount);
      const result = await sendTransaction(data, contractAddress);
      return result;
    } catch (error) {
      console.error("Error: " + error);
    }
  };

  const getTransactionReceipt = async (txHash, retries) => {
    try {
      let receipt = null;
      while (receipt === null) {
        receipt = await web3.eth.getTransactionReceipt(txHash);
        //if transaction is not found, wait for 3 seconds before checking again
        if (receipt === null) {
          await new Promise((resolve) => setTimeout(resolve, 5000)); //wait for 5 seconds
          retries -= 1;
          // Wait for 3 seconds before checking again
        }
      }
      return receipt;
    } catch (error) {
      //retry 5 times before giving up
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        retries -= 1;
        return getTransactionReceipt(txHash, retries);
      } else {
        return null;
      }
    }
  };

  const sendTransaction = async (data, contractAddy) => {
    try {
      const polygonChainId = 80001;
      // Fetch the latest confirmed transaction nonce
      const latestNonce = await web3.eth.getTransactionCount(
        account,
        "pending"
      );

      // Calculate nonce for the next transaction
      const nextNonce = web3.utils.toHex(latestNonce);
      console.log(latestNonce);
      console.log(nextNonce);

      const params = {
        from: account,
        to: contractAddy,
        data: data,
        gasPrice: web3.utils.toHex(5000000000),
        gasLimit: web3.utils.toHex(21000), // adjust this value based on your needs
        chainId: polygonChainId,
        nonce: nextNonce,
      };
      console.log(params);
      const result = await window.ethereum.request({
        method: "eth_sendTransaction",
        params: [params],
      });
      const receipt = await getTransactionReceipt(result, 20);
      console.log(receipt);
      var passed = false;
      if (receipt && receipt.status) {
        console.log("Transaction was successful!");
        passed = true;
      } else {
        passed = false;
        console.error("Transaction was reverted!");
        if (
          receipt &&
          receipt.status === "0x0" &&
          receipt.logs &&
          receipt.logs.length > 0
        ) {
          console.error(
            "Revert reason:",
            web3.utils.hexToAscii(receipt.logs[0].data)
          );
        }
      }
      return { passed: passed, receipt: receipt, hash: result };
    } catch (error) {
      console.error("Error: " + error);
      console.log(error.message);
    }
  };

  const checkAllowance = async (betAmount) => {
    try {
      const allowance = tokenContract.methods.allowance(
        account,
        contractAddress
      );
      const result = await allowance.call();
      console.log(result);
      if (result > parseInt(betAmount)) {
        return true;
      }
      const data = tokenContract.methods
        .approve(contractAddress, web3.utils.toWei("1000", "ether"))
        .encodeABI();
      const result2 = await sendTransaction(data, tokenContractAddress);
      console.log(result2);
    } catch (error) {
      if (error instanceof web3.eth.Contract.ContractRevertInstructionError) {
        console.error("Transaction reverted with reason: " + error.reason);
      } else {
        console.error("Error: " + error);
      }
    }
  };

  const cancelBattle = async (actionData) => {
    try {
      const _battleId = actionData?._battleId;

      const data = contract.methods.cancelBattle(_battleId).encodeABI();

      const result = await sendTransaction(data, contractAddress);
      return result;
    } catch (error) {
      console.error("Error: " + error);
    }
  };

  const checkErroredBattles = async (battles) => {
    try {
      const inActiveBattles = battles.filter(
        (battle) => !battle.isActive && battle.id > 27
      );
      console.log(inActiveBattles, "inactive battles");
      const allBattleHistory = await getBattleHistory();
      //if the battle id is in inactive battles and not in battle history, then it is errored
      const erroredBattles = inActiveBattles
        ?.filter((battle) => {
          const battleId = battle.id;
          const battleHistory = allBattleHistory.find(
            (item) => item.battle_id === battleId
          );
          return !battleHistory;
        })
        .map((battle) => {
          return {
            ...battle,
            state: "errored",
          };
        });
      const activeBattles = battles?.filter((battle) => battle.isActive);
      const finalBattles = activeBattles.concat(erroredBattles);
      return finalBattles || [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const getUnjoinedBattles = async () => {
    try {
      // Make an HTTP GET request to your API to retrieve unjoined battles
      //use the contract mehod = getBattles, with offset and limit
      const getBattleCountMethod = contract.methods.getBattleCount();
      const battleCount = await getBattleCountMethod.call();
      console.log(battleCount);
      //decode the bigInt to a number
      var battleNo = battleCount.toString().split("n")[0];
      var battleInt = parseInt(battleNo);

      const getBattlesMethod = contract.methods.getBattles(0, battleInt);
      console.log("Encoded Data: ", getBattlesMethod);
      const response = await getBattlesMethod.call();
      console.log(response);

      // response will contain the returned data
      const battleData = response.filter((item) => item.isActive);
      if (allUsers.length === 0) await getAllUsers();

      var battles = battleData?.map((battle) => {
        var tokenIDs = battle.tokenIds;
        var nftData = [];
        tokenIDs.forEach((id) => {
          var nft = allNFTs.find((nft) => nft.tokenId == id);
          nftData.push(nft);
        });
        var challengerData = allUsers?.find(
          (user) =>
            user.owner_wallet_id.toLowerCase() ==
            battle.challenger.toLowerCase()
        );
        if (challengerData === undefined)
          challengerData = {
            owner_name: battle.challenger.toLowerCase(),
            owner_wallet_id: battle.challenger.toLowerCase(),
          };
        const errored = battle.state === "errored";
        return {
          id: battle.id.toString(),
          challenger: challengerData,
          betToken: battle.betToken,
          betAmount: battle.betAmount,
          battleType: battle.battleType,
          constraints: battle.constraints,
          isActive: battle.isActive,
          tokenIds: nftData,
          errored: errored,
        };
      });

      console.log("final", battles);
      setActiveBattles(battles);
    } catch (error) {
      console.log(error);
    }
  };

  // Function to get battle history for a user or NFT
  const getBattleHistory = async () => {
    try {
      const url = `${apiUrl}history`;
      const response = await api.get(url);
      const pendingBattleData = await getPendingBattles();
      const battleDataReceived = response.data;
      if (allUsers.length === 0) await getAllUsers();
      const battles = battleDataReceived.map((battle) => {
        var winner = "";
        console.log(battle);
        const battleHistory = battle.battleHistory.map((history) => {
          const nft_id = history.nft_id;
          const nftData = allNFTs.find((nft) => nft.tokenId == nft_id);
          const user_id = history.owner_wallet_id;
          const userData = allUsers.find(
            (user) =>
              user.owner_wallet_id.toLowerCase() == user_id.toLowerCase()
          );
          if (history.battle_result === 1) winner = userData?.owner_name;
          return {
            ...history,
            nftData: nftData,
            userInfo: userData,
          };
        });
        battle.winner = winner;
        return {
          ...battle,
          battleHistory: battleHistory,
        };
      });
      var finalDatawithPending = [];
      for (let i = 0; i < battles.length; i++) {
        var data = battles[i];
        var battle_id = data.battle_id;
        //find the battle id in pending battles
        var pendingBattle = pendingBattleData.find(
          (item) => item.battle_id === battle_id
        );
        console.log(pendingBattle);
        if (pendingBattle) {
          var chain_state = pendingBattle.chain_state;
          var amount = chain_state?.split("/")?.[1]?.split("/")?.[0];
          var betToken = chain_state?.split("/")?.[0];
          var state = chain_state?.split("/")?.[2];
          data = {
            ...data,
            pendingBattle: pendingBattle,
            pending: state === "pending",
            price: amount,
            betToken: betToken,
          };
          finalDatawithPending.push(data);
        }
      }
      console.log("context data", finalDatawithPending);
      setBattleHistory(finalDatawithPending);
      return finalDatawithPending;
    } catch (error) {
      console.log(error);
      // Handle errors
    }
  };

  const getPendingBattles = async () => {
    try {
      const url = `${apiUrl}pending`;
      const response = await api.get(url);
      return response.data || [];
    } catch (error) {
      // Handle errors
    }
  };

  // Function to get leaderboard data
  const getLeaderboardData = async () => {
    try {
      // Make an HTTP GET request to your API to retrieve leaderboard data
      const response = await axios.get(`${apiUrl}leaderboard`);
      console.log(response.data);
      setLeaderboardData(response.data);
    } catch (error) {
      // Handle errors
    }
  };

  // Function to get all kingdom data
  const getAllKingdomData = async () => {
    try {
      // Make an HTTP GET request to your API to retrieve kingdom data
      const response = await axios.get(`${apiUrl}kingdoms`);
      // Handle success, update state, etc.
    } catch (error) {
      // Handle errors
    }
  };

  // Provide the context values to children components
  const contextValue = {
    activeBattles,
    selectedBattle,
    battleHistory,
    leaderboardData,
    kingdomData,
    getUnjoinedBattles,
    createBattle,
    cancelBattle,
    startBattle,
    signWithdraw,
    getBattleHistory,
    getLeaderboardData,
    getAllKingdomData,
    getAllUsers,
  };

  return (
    <BattleContext.Provider value={contextValue}>
      {children}
    </BattleContext.Provider>
  );
};

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import CollectionTwo from "../../components/collection-two";
import item1 from "../../assets/images/items/1.jpg";
import item2 from "../../assets/images/items/2.gif";
import item3 from "../../assets/images/items/3.jpg";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import { all } from "axios";

import { UserContext } from "../../store/UserContext";
import { BattleContext } from "../../store/BattleContext";
export default function Collections() {
  const {
    user,
    allUsers,
    allNFTs,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    connectMetaMask,
    showModal,
    setShowModal,
    disconnect,
  } = useContext(UserContext);

  const { leaderboardData, getLeaderboardData } = useContext(BattleContext);

  useEffect(() => {
    getLeaderboardData();
  }, []);

  const buttonData = [
    {
      type: "All",
      icon: "uil uil-globe",
    },
    {
      type: "1v1",
      icon: "uil uil-user",
    },
    {
      type: "Free 1v1",
      icon: "uil uil-user-plus",
    },
    {
      type: "2v2",
      icon: "uil uil-users-alt",
    },
    {
      type: "3v3",
      icon: "uil uil-user-square", // General icon as a placeholder
    },
    {
      type: "4v4",
      icon: "uil uil-user-circle", // General icon as a placeholder
    },
    {
      type: "5v5",
      icon: "uil uil-tag", // General icon as a placeholder
    },
    {
      type: "Paid Tourney",
      icon: "uil uil-trophy",
    },
    {
      type: "Free Tourney",
      icon: "uil uil-gift",
    },
  ];

  const durationData = [
    {
      type: "All Time",
      icon: "uil uil-clock",
    },
    {
      type: "Daily",
      icon: "uil uil-sun",
    },
    {
      type: "Weekly",
      icon: "uil uil-calendar-alt",
    },
    {
      type: "Monthly",
      icon: "uil uil-calendar",
    },
  ];

  const [selectedType, setSelectedType] = useState(buttonData[0].type);
  const [selectedOwner, setSelectedOwner] = useState("Ponyjacks");
  const [duration, setDuration] = useState("All Time");

  const AllBattleData = () => {
    if (selectedOwner === "Ponyjacks") {
      return getPonyData();
    } else {
      return getPlayerData();
    }
  };
  const getPonyData = () => {
    console.log("leaderboardData", leaderboardData);
    var leaderboard = leaderboardData.leaderboard || [];
    console.log("leaderboard", leaderboard);
    var allData = [];
    var allUserNFTs =
      user?.nfts?.filter((nft) => nft.category === "Ponyjacks") || [];
    for (var i = 0; i < leaderboard.length; i++) {
      var ldata = leaderboard[i];
      var nft_id = ldata.nft_id;
      var nft = allNFTs.find((nft) => nft.tokenId.toString() === nft_id);
      console.log("nft", nft);

      if (nft) {
        var ownerName =
          allUsers?.find(
            (user) =>
              user.owner_wallet_id.toLowerCase() ==
              nft.owner_wallet_id.toLowerCase()
          ) || null;
        allData.push({
          image: nft.image,
          owner:
            ownerName?.owner_name ||
            nft.owner_wallet_id.slice(0, 6) +
              "..." +
              nft.owner_wallet_id.slice(-6) ||
            "Account",
          title: nft.title || "Ponyjack",
          avatar: item3,
          totalBattles: parseInt(ldata.total_battles),
          lostBattles: parseInt(ldata.total_losses),
          wonBattles: parseInt(ldata.total_wins),
          totalEarnings: parseInt(ldata.total_amount_won),
          totalLosses: parseInt(ldata.total_amount_lost),
          type: "1v1",
          duration: "All Time",
          ltype: "Ponyjacks",
          fullData: nft,
        });
      }
    }

    allData = allData.sort((a, b) => b.totalEarnings - a.totalEarnings);
    return allData;
  };

  const getPlayerData = () => {
    console.log("leaderboardData", leaderboardData);
    var playerboard = leaderboardData.playerboard || [];
    console.log("leaderboard", playerboard);
    var allData = [];

    for (var i = 0; i < playerboard.length; i++) {
      var ldata = playerboard[i];
      var ownerName =
        allUsers?.find(
          (user) =>
            user.owner_wallet_id.toLowerCase() ==
            ldata.owner_wallet_id.toLowerCase()
        ) || null;
      if (ownerName) {
        allData.push({
          image: item3,
          owner:
            ownerName?.owner_wallet_id.slice(0, 4) +
            "..." +
            ownerName?.owner_wallet_id.slice(-4),
          title: ownerName?.owner_name || "Account",
          avatar: null,
          totalBattles: parseInt(ldata.total_battles),
          lostBattles: parseInt(ldata.total_losses),
          wonBattles: parseInt(ldata.total_wins),
          totalEarnings: parseInt(ldata.total_amount_won),
          totalLosses: parseInt(ldata.total_amount_lost),
          type: "1v1",
          duration: "All Time",
          ltype: "Players",
        });
      }
    }

    allData = allData.sort((a, b) => b.totalEarnings - a.totalEarnings);
    return allData;
  };

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-24 bg-[url('../../assets/images/Ponyjacks/Goldjack.png')] bg-top bg-no-repeat">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Top Players
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Ponyjacks</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Leaderboard
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <section className="relative md:py-24 py-16 min-h-screen">
        <div className=" mx-auto px-4 mb-4 text-center text-3xl font-bold dark:text-white text-slate-900">
          {duration} Top {selectedOwner}{" "}
        </div>
        <div className="container">
          <div className="md:flex md:space-x-4 space-y-4 md:space-y-0">
            <CollectionTwo data={AllBattleData()} />
            <div className="sticky top-20 w-full">
              <h5 className="text-lg font-semibold mt-6">Type</h5>
              <ul className="list-none mt-4">
                <li className="inline-block m-2">
                  <button
                    className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                      ${
                        selectedOwner === "Ponyjacks"
                          ? "bg-violet-600 dark:bg-violet-800 "
                          : "bg-gray-50 dark:bg-slate-800 "
                      } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                    onClick={() => setSelectedOwner("Ponyjacks")}
                  >
                    <i className="uil uil-wallet me-1"></i> Ponyjacks
                  </button>
                </li>
                <li className="inline-block m-2">
                  <button
                    className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                      ${
                        selectedOwner === "Players"
                          ? "bg-violet-600 dark:bg-violet-800 "
                          : "bg-gray-50 dark:bg-slate-800 "
                      } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                    onClick={() => setSelectedOwner("Players")}
                  >
                    Players
                  </button>
                </li>
              </ul>
              <h5 className="text-lg font-semibold mt-6">Duration</h5>
              {durationData.map((item, index) => (
                <li key={index} className="inline-block m-2">
                  <button
                    className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                        ${
                          duration === item.type
                            ? "bg-violet-600 dark:bg-violet-800 "
                            : "bg-gray-50 dark:bg-slate-800 "
                        } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                    onClick={() => setDuration(item.type)}
                  >
                    {item.icon && <i className={`${item.icon} me-1`}></i>}{" "}
                    {item.type}
                  </button>
                </li>
              ))}
              {/* <h5 className="text-lg font-semibold mt-6"> Type</h5>
              <ul className="list-none mt-4">
                {buttonData.map((item, index) => (
                  <li key={index} className="inline-block m-2">
                    <button
                      className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white 
                        ${
                          selectedType === item.type
                            ? "bg-violet-600 dark:bg-violet-800 "
                            : "bg-gray-50 dark:bg-slate-800 "
                        }
                        ${
                          item.icon ? "bg-violet-50" : "bg-gray-50"
                        } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                      onClick={() => setSelectedType(item.type)}
                    >
                      {item.icon && <i className={`${item.icon} me-1`}></i>}{" "}
                      {item.type}
                    </button>
                  </li>
                ))}
              </ul> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}

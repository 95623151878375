import React, { useContext, useEffect, useState } from "react";
import Nfts from "../../components/nfts";
import { UserContext } from "../../store/UserContext";

export default function NewEvent() {
  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    connectMetaMask,
    showModal,
    setShowModal,
    disconnect,
  } = useContext(UserContext);
  const eventTypes = [
    "1v1",
    "2v2",
    "3v3",
    "4v4",
    "5v5",
    "Boss Battle",
    "Onslaught",
    "Ponywar (12v12)",
    "Tournament",
  ];
  const visibilities = ["Open", "Blind"];
  const prizetypes = ["ETH", "Star Horseshoes"];
  const teamsTypes = ["Set", "Random"];
  const payoutTypes = ["Winners", "Survivors"];
  const [eventType, setEventType] = useState("1v1");
  const [visibility, setVisibility] = useState("Open");
  const [entryFee, setEntryFee] = useState(0);
  const [prizeType, setPrizeType] = useState("ETH");
  const [teams, setTeams] = useState("Random");
  const [payout, setPayout] = useState("Winners");
  const [nftPopup, setNftPopup] = useState(false);
  const [nftPopup2, setNftPopup2] = useState(false);

  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [selectedNFTs2, setSelectedNFTs2] = useState([]);

  const handleRemoveNFT = (nft) => {
    setSelectedNFTs(
      selectedNFTs.filter((item) => item.tokenId !== nft.tokenId)
    );
  };

  const handleRemoveNFT2 = (nft) => {
    setSelectedNFTs2(
      selectedNFTs2.filter((item) => item.tokenId !== nft.tokenId)
    );
  };

  var eventData = [
    {
      name: "1v1",
      type: "1v1",
      opponent: "Self",
      visibility: "Open",
      entryFee: 1.34,
      prizeType: "ETH",
      startTime: "3D: 5H: 30M: 20S",
      teams: "Random",
      payout: "Winners",
    },
    {
      name: "1v1",
      type: "1v1",
      opponent: "BigBull",
      visibility: "Open",
      entryFee: 1.67,
      prizeType: "ETH",
      startTime: "1D: 5H: 30M: 20S",
      teams: "Random",
      payout: "Winners",
    },
  ];

  eventData = eventData.concat(
    eventData
      .concat(eventData)
      .concat(eventData)
      .concat(eventData)
      .map((event) => ({
        ...event,
        opponent: Math.floor(Math.random() * 1000) > 500 ? "Self" : "BigBull",
        entryFee: Math.floor(Math.random() * 100) / 100,
      }))
  );

  const allNFTs = () => {
    var sortedNFTs = user?.nfts || [];
    sortedNFTs = sortedNFTs.filter((nft) => {
      return nft.category === "Ponyjacks";
    });
    return sortedNFTs;
  };

  return (
    <div className="grid  items-center mt-4 justify-center dark:text-white text-black">
      {/* Form to create events */}
      <form className="grid grid-cols-3 gap-4">
        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2">
          <label>Event Type</label>
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900  text-xl p-3 space-x-2"
          >
            {eventTypes.map((type) => (
              <option value={type} className="text-green-500 ">
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2">
          <label>Visibility</label>
          <select
            value={visibility}
            onChange={(e) => setVisibility(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900  text-xl p-3 space-x-2"
          >
            {visibilities.map((type) => (
              <option value={type} className="text-green-500 ">
                {type}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2">
          <label>Prize Type</label>
          <select
            value={prizeType}
            onChange={(e) => setPrizeType(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900  text-xl p-3 space-x-2"
          >
            {prizetypes.map((type) => (
              <option value={type} className="text-green-500 ">
                {type}
              </option>
            ))}
          </select>
          <div className="relative flex items-center space-x-2">
            {/* <div className=" text-xl">Rake </div>
            <input
              value={rake}
              onChange={(e) => setRake(e.target.value)}
              className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900  text-xl p-3"
              type="number"
            />
            <span className="mr-2 "> %</span> */}
          </div>
        </div>
        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2">
          <label> Teams</label>
          <select
            value={teams}
            onChange={(e) => setTeams(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900  text-xl p-3 space-x-2"
          >
            {teamsTypes.map((type) => (
              <option value={type} className="text-green-500 ">
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2">
          <label> Payout Type</label>
          <select
            value={payout}
            onChange={(e) => setPayout(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900  text-xl p-3 space-x-2"
          >
            {payoutTypes.map((type) => (
              <option value={type} className="text-green-500 ">
                {type}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group mt-4 relative flex items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2">
          <label> Entry Fee</label>
          <input
            value={entryFee}
            onChange={(e) => setEntryFee(e.target.value)}
            className="w-full rounded-md shadow-sm border-gray-300 dark:border-gray-800 focus:border-violet-300 focus:ring focus:ring-violet-200 focus:ring-opacity-50 bg-gray-100 dark:bg-slate-900  text-xl p-3 flex items-center space-x-2"
            type="number"
          />
          <div className="mr-2  text-xl flex items-center space-x-2">
            ETH <i className="mdi mdi-ethereum"> </i>
          </div>
        </div>

        {/*make a input for setting rake %*/}

        {/* Other form fields */}
      </form>
      <div className="flex-col items-center justify-center mt-4">
        <div className="form-group mt-4 relative flex-col items-center bg-slate-900 justify-center rounded-md shadow-sm   text-xl p-3 space-x-2 ml-4 text-center overflow-scroll  overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-700 overflow-y-hidden">
          <div className="relative flex items-center justify-center text-center space-x-2">
            <h2 className="text-2xl font-semibold ">Player 1</h2>
            <button
              className="btn btn-primary bg-violet-600 hover:bg-violet-700 transition-all duration-300 rounded shadow-lg border-violet-700 hover:border-violet-80  p-2 px-4"
              onClick={() => setNftPopup(!nftPopup)}
            >
              Select NFTs
            </button>{" "}
          </div>
          <div className="flex flex-wrap justify-center items-center">
            {selectedNFTs &&
              selectedNFTs.map((item, index) => (
                <div
                  className="relative overflow-hidden rounded-lg   transition-all duration-500  dark:shadow-gray-700  max-h-[150px] max-w-[300px]
              flex justify-center items-center bg-green-300 dark:bg-slate-900 p-4 m-2 rounded-lg
              "
                >
                  <img
                    src={item.image}
                    alt=""
                    className="rounded-lg max-h-[80px] max-w-[80px]"
                  />
                  <div className="mt-6">
                    <h3 className="text-xl font-semibold  dark:text-white">
                      {item.title}
                    </h3>
                    <p className="dark:text-gray-300 text-slate-500">
                      {item.tokenId.toString().slice(0, 3) +
                        "..." +
                        item.tokenId.toString().slice(-3)}
                    </p>
                    <button onClick={() => handleRemoveNFT(item)}>
                      <i className="mdi mdi-close text-red-500"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          {nftPopup && (
            <section className="relative py-4">
              <Nfts
                data={allNFTs()}
                selectedNFTs={selectedNFTs}
                setSelectedNFTs={setSelectedNFTs}
                battleType={eventType}
              />
            </section>
          )}{" "}
        </div>
        <div className="form-group mt-4 relative flex-col items-center bg-slate-900 justify-center rounded-md shadow-sm   text-xl p-3 space-x-2 ml-4 text-center overflow-scroll  overflow-x-auto scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-700 overflow-y-hidden">
          <div className="relative flex items-center justify-center text-center space-x-2">
            <h2 className="text-2xl font-semibold ">
              Player 2 (leave blank for open lobby)
            </h2>
            <button
              className="btn btn-primary bg-violet-600 hover:bg-violet-700 transition-all duration-300 rounded shadow-lg border-violet-700 hover:border-violet-80  p-2 px-4"
              onClick={() => setNftPopup2(!nftPopup2)}
            >
              Select NFTs
            </button>{" "}
          </div>
          <div className="flex flex-wrap justify-center items-center">
            {selectedNFTs2 &&
              selectedNFTs2.map((item, index) => (
                <div
                  className="relative overflow-hidden rounded-lg   transition-all duration-500  dark:shadow-gray-700  max-h-[150px] max-w-[300px]
              flex justify-center items-center bg-green-300 dark:bg-slate-900 p-4 m-2 rounded-lg
              "
                >
                  <img
                    src={item.image}
                    alt=""
                    className="rounded-lg max-h-[80px] max-w-[80px]"
                  />
                  <div className="mt-6">
                    <h3 className="text-xl font-semibold  dark:text-white">
                      {item.title}
                    </h3>
                    <p className="dark:text-gray-300 text-slate-500">
                      {item.tokenId.toString().slice(0, 3) +
                        "..." +
                        item.tokenId.toString().slice(-3)}
                    </p>
                    <button onClick={() => handleRemoveNFT2(item)}>
                      <i className="mdi mdi-close text-red-500"></i>
                    </button>
                  </div>
                </div>
              ))}
          </div>
          <div className="relative">
            {nftPopup2 && (
              <section className="relative py-4">
                <Nfts
                  data={allNFTs()}
                  selectedNFTs={selectedNFTs2}
                  setSelectedNFTs={setSelectedNFTs2}
                  battleType={eventType}
                />
              </section>
            )}{" "}
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="btn btn-primary mt-4 px-4 bg-violet-600 hover:bg-violet-700 transition-all duration-300 rounded shadow-lg border-violet-700 hover:border-violet-80  p-2 px-4"
        /*onClick={createEvent}*/
      >
        Create Event
      </button>
      <div className="form-group mt-4 relative flex-col items-center rounded-md shadow-sm bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2 ml-4 text-center overflow-scroll max-h-[500px] overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-700 overflow-x-hidden">
        {/* List of created events */}

        <h2 className="text-2xl font-semibold mb-4  border-b-2 border-gray-300 pb-2 ">
          Upcoming Events
        </h2>
        <table className="table-auto w-full text-center shadow-lg rounded-lg overflow-scroll mt-4 bg-gray-600 dark:bg-slate-900  text-xl p-3 space-x-2">
          <thead>
            <tr>
              <th className="px-4 py-2">Name</th>
              <th className="px-4 py-2">V/S</th>
              <th className="px-4 py-2">Type</th>
              <th className="px-4 py-2">Start Time</th>
              <th className="px-4 py-2">Entry Fee</th>
              <th className="px-4 py-2">Prize Type</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300 bg-gray-700 text-green-300 gap-2">
            {eventData.map((event) => (
              <tr
                key={event.name}
                className="border-b-2 border-gray-300 bg-gray-700 text-green-300"
              >
                <td className="px-4 py-2 text-center border-r-2 border-gray-300 bg-gray-700 mr-2">
                  {event.name}
                </td>
                <td className="px-4 py-2 text-center border-r-2 border-gray-300 bg-gray-700">
                  {event.opponent}
                </td>
                <td className="px-4 py-2 text-center border-r-2 border-gray-300 bg-gray-700">
                  {event.type}
                </td>
                <td className="px-4 py-2 text-center border-r-2 border-gray-300 bg-gray-700">
                  {event.startTime}
                </td>
                <td className="px-4 py-2 text-center border-r-2 border-gray-300 bg-gray-700">
                  {event.entryFee}
                </td>
                <td className="px-4 py-2 text-center border-r-2 border-gray-300 bg-gray-700">
                  {event.prizeType}
                </td>
                <td className="px-4 py-2 text-center border-r-2 border-gray-300 bg-gray-700  shadow-lg flex justify-center items-center space-x-4">
                  <button className="btn btn-primary mt-4 bg-violet-600 hover:bg-violet-700 transition-all duration-300 rounded shadow-lg border-violet-700 hover:border-violet-800">
                    Edit
                  </button>
                  <button className="btn ml-4 btn-primary mt-4 bg-violet-600 hover:bg-violet-700 transition-all duration-300 rounded shadow-lg border-violet-700 hover:border-violet-800">
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

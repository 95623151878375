import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Switcher from "../../components/switcher";
// ... Inside your Auction component or another parent component
import ArenaScene from "./Animator/ArenaScene";
import { UserContext } from "../../store/UserContext";
import { BattleContext } from "../../store/BattleContext";
export default function BattleScreen() {
  const {
    user,
    selectedBattle,
    setSelectedBattle,
    getBattleHistory,
    battleHistory,
    getAllUsers,
  } = useContext(BattleContext);
  const { allUsers, checkForAutoLogin, allNFTs } = useContext(UserContext);
  const id = new URLSearchParams(window.location.search).get("battle_id");
  const reload = async () => {
    console.log(allUsers);
    await checkForAutoLogin();
    await getAllUsers();
    if (allUsers?.length > 0 && allNFTs?.length > 0) await getBattleHistory();
  };
  useEffect(() => {
    if (battleHistory.length === 0) {
      reload();
    } else {
      const battleToReplay = battleHistory?.find(
        (battle) => battle.id === parseInt(id)
      );
      console.log("battleToReplay", battleToReplay);
      setFinalBattle(battleToReplay);
    }
  }, [allUsers]);

  //url looks like http://localhost:3000/battlescreen?battle_id=86
  const [finalBattle, setFinalBattle] = useState(selectedBattle);

  return (
    <>
      <Navbar />
      <div className="relative py-24">
        {finalBattle && <ArenaScene battleData={finalBattle} />}
      </div>
      <Switcher />
    </>
  );
}

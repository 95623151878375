import React from "react";
import { Link } from "react-router-dom";
import { Hexagon } from "react-feather";

export default function Feature() {
  return (
    <div className="container md:mt-24 mt-16">
      <div className="grid grid-cols-1 text-center">
        <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">
          Welcome to the Ponyverse
        </h3>

        <p className="text-slate-400 max-w-xl mx-auto">
          A web3 world bursting with unique and powerful Ponyjacks. Each
          Ponyjack has its own story to tell and destiny to fulfill, shaped by
          its traits, jacktype, and elements.
        </p>
      </div>

      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">
        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon className="h-28 w-28 fill-green-500 mx-auto rotate-[30deg]"></Hexagon>
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-trophy"></i>
            </div>
          </div>

          <div className="mt-6">
            <Link
              to="#"
              className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600"
            >
              Battles & Races
            </Link>
            <p className="text-slate-400 transition duration-500 ease-in-out mt-3">
              Compete and win ETH, prizes, new NFTs, and glory for your stable
            </p>
          </div>
        </div>
        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon className="h-28 w-28 fill-green-500 mx-auto rotate-[30deg]"></Hexagon>
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-layers"></i>
            </div>
          </div>

          <div className="mt-6">
            <Link
              to="#"
              className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600"
            >
              Collect & Trade
            </Link>
            <p className="text-slate-400 transition duration-500 ease-in-out mt-3">
              Build a supreme stable of rare and strong horses to suit your
              strategy
            </p>
          </div>
        </div>
        <div className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
          <div className="relative overflow-hidden text-transparent -m-3">
            <Hexagon className="h-28 w-28 fill-green-500 mx-auto rotate-[30deg]"></Hexagon>
            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
              <i className="uil uil-star"></i>
            </div>
          </div>

          <div className="mt-6">
            <Link
              to="#"
              className="text-lg h5 font-semibold transition duration-500 ease-in-out hover:text-violet-600"
            >
              Free Genesis Tourneys
            </Link>
            <p className="text-slate-400 transition duration-500 ease-in-out mt-3">
              Get drafted to earn passive prizes and ETH simply for owning one
              of the first 1000 Genesis
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

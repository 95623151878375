import React, { useEffect, useContext, useState } from "react";
import Feature from "../components/feature";
import Roadmap from "../components/roadmap";
import { ElementFeature, RarityFeature } from "../components/rarityFeature";
import {
  showSuccessToast,
  showErrorToast,
} from "../components/NotificationService";

import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../store/UserContext";
import { BattleContext } from "../store/BattleContext";
export default function TransactionPopup(props) {
  const { name, image, details, price, show, setShow, next, jsonData } = props;
  const { user, account } = useContext(UserContext);
  const {
    createBattle,
    cancelBattle,
    startBattle,
    getBattleHistory,
    getPendingBattles,
    signWithdraw,
  } = useContext(BattleContext);
  const navigate = useNavigate(); // <-- Add this line

  async function onConfirm() {
    try {
      const result = await CompleteTransaction(name);
      if (!result) showErrorToast("Transaction failed");
      else showSuccessToast(name + " transaction confirmed");

      setShow(false);
      //if (next) navigate(next);
    } catch (error) {
      showErrorToast("Transaction failed");
      setShow(false);
    }
  }

  async function CompleteTransaction(action) {
    console.log("Completing transaction for " + action);
    console.log(jsonData);
    switch (action) {
      case "Start Battle": {
        const maxTokenIDs = jsonData.player1.ponyjacks.length;
        const isActiveData =
          jsonData.lowestElementValue != 1 ||
          jsonData.highestElementValue != 8 ||
          jsonData.lowestRarityValue != 1 ||
          jsonData.highestRarityValue != 8;
        const actionData = {
          _tokenIds: jsonData.player1.ponyjacks.map((nft) => nft.tokenId), // An array of uint256
          _betToken: "0x66E5CfA3487ea1DBd08Ec85955070Ea60eff16EF",
          _betAmount: jsonData.price * 10 ** 18, // A uint256
          _maxEntry: 2, // A uint8
          _maxMultiEntry: maxTokenIDs, // A uint8
          _battleConstraints: {
            isActive: isActiveData, // A bool
            minJackType: 1, // A uint8
            maxJackType: 130, // A uint8
            mixRarity: jsonData.lowestRarityValue,
            maxRarity: jsonData.highestRarityValue,
            minKingdom1: jsonData.lowestElementValue,
            maxKingdom1: jsonData.highestElementValue,
            minKingdom2: 1, // A uint8
            maxKingdom2: 8,
            minBreed: 1, // A uint8
            maxBreed: 130, // A uint8
            minGen: 0, // A uint8
            maxGen: 2, // A uint8
          },
        };
        console.log(actionData);
        const response = await createBattle(actionData);
        console.log(response);
        return response;
      }

      case "Join Battle":
        const joinData = {
          _tokenIds: jsonData.player1.ponyjacks.map((nft) => nft.tokenId), // An array of uint256
          _battleId: jsonData.battleId,
          team1: jsonData.player1.ponyjacks.map((nft) => nft.tokenId),
          team2: jsonData.player2.ponyjacks.map((nft) => nft.tokenId),
          betAmount: jsonData.price,
          betToken: jsonData.betToken,
        };
        console.log(joinData);
        const response2 = await startBattle(joinData);
        return response2;

      case "Cancel Battle":
        const actionData2 = {
          _battleId: jsonData.battleId,
        };
        const response3 = await cancelBattle(actionData2);
        return response3;

      case "Withdraw":
        const withdrawData = {
          betAmount: jsonData.price,
          betToken: jsonData.betToken,
          account: account,
          battle_id: jsonData.battle_id,
          battle: jsonData,
        };
        console.log(withdrawData);
        const response4 = await signWithdraw(withdrawData);
        await getBattleHistory();
        return response4;

      default:
        return null;
    }
  }

  //make an animation component that displays the loading spinner
  const LoadingAnimation = () => {
    return (
      <div className="mt-4 flex flex-col justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
        <h3 className="text-3xl font-bold text-center text-black dark:text-white mt-4">
          Confirm transaction in your wallet
        </h3>
      </div>
    );
  };

  return !show ? (
    <></>
  ) : (
    <div className="fixed  inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center w-full h-screen bg-gray-500">
      <div className="bg-white dark:bg-slate-900 rounded-lg  flex flex-col justify-center items-center p-4">
        <div className="text-4xl font-bold text-center text-black dark:text-white mb-2">
          Transaction Pending
        </div>
        <div className="text-2xl text-center text-black dark:text-white">
          {name}
        </div>
        {name == "Withdraw" && (
          <div className="text-2xl text-center text-red-500">
            Warning: Make sure you have enough gas before confirming.
            <div className="text-xl text-center text-red-500">
              Do not close this window until transaction is confirmed!
            </div>
          </div>
        )}{" "}
        <div className="text-3xl text-center text-black dark:text-white mt-4">
          {details}
        </div>
        <LoadingAnimation />
        <div className="flex justify-center items-center">
          <button
            className="mt-4 mr-4 p-2 bg-white dark:bg-red-900 rounded-full shadow dark:shadow-gray-700 btn btn-circle btn-lg mt-7"
            onClick={() => setShow(false)}
          >
            Cancel
          </button>{" "}
          <button
            className="mt-4 mr-4 p-2 bg-white dark:bg-green-900 rounded-full shadow dark:shadow-gray-700 btn btn-circle btn-lg mt-7"
            onClick={() => onConfirm()}
          >
            Confirm
          </button>
        </div>
        <div className="text-xl text-center text-black dark:text-white">
          {price}
        </div>
        <div className="text-xl text-center text-black dark:text-white">
          {image}
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { UserContext } from "../../store/UserContext";

import BattleLobby from "./battleLobby";
import BattleTournaments from "./battleTournaments";
import BattleResults from "./battleResults";
import BattleWinnings from "./battleWinnings";
import StartBattle from "./startBattle";

export default function ExploreTwo() {
  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    connectMetaMask,
    showModal,
    setShowModal,
    disconnect,
  } = useContext(UserContext);

  const headerOptions = [
    "New Battle",
    "Battle Lobby",
    "Tournaments",
    //"Events",
    "Results",
    "Winnings",
  ];

  const [selectedPage, setSelectedPage] = useState("Battle Lobby");

  return (
    <>
      <Navbar />

      <section className="relative table w-full py-24 bg-[url('../../assets/images/Ponyjacks/Titanjack.png')] bg-top bg-no-repeat">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Arena
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Ponyjacks</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Lobby
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className=" mx-auto ">
        <div
          className="grid justify-center mb-8 p-8 w-full dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 rounded-md shadow dark:shadow-gray-700 transition-all duration-500 text-slate-900 dark:text-slate-200
       grid-cols-2 md:grid-cols-2 lg:grid-cols-5 gap-6  "
        >
          {headerOptions.map((option, index) => (
            <div className="flex items-center justify-center text-center text-sm">
              <button
                className={`btn btn-lg  rounded-full hover:bg-violet-600 hover:text-white dark:hover:bg-violet-600 dark:hover:text-white transition-all duration-500 border-slate-600 dark:border-slate-300
                 ${
                   selectedPage === option
                     ? "bg-violet-600 text-white dark:bg-violet-600 dark:text-white"
                     : ""
                 }`}
                onClick={() => setSelectedPage(option)}
              >
                {option}
              </button>
            </div>
          ))}
        </div>
      </div>
      {selectedPage === "Battle Lobby" && <BattleLobby />}
      {selectedPage === "Tournaments" && <BattleTournaments />}
      {selectedPage === "Results" && <BattleResults />}
      {selectedPage === "Winnings" && <BattleWinnings />}
      {selectedPage === "New Battle" && <StartBattle />}
      <Footer />
      <Switcher />
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import image from "../../assets/images/avatar/1.png";
import Collections from "../../components/nfts";
import TransactionPopup from "../../components/transactionPopup";
import { Icon } from "@iconify/react";
import { UserContext } from "../../store/UserContext";
import { BattleContext } from "../../store/BattleContext";
import {
  showSuccessToast,
  showErrorToast,
} from "../../components/NotificationService";

export default function StartBattle() {
  const { user, selectedItem, setSelectedBattle, adminConfigs } =
    useContext(UserContext);
  const { activeBattles } = useContext(BattleContext);
  const nftNotUsed = (nft) => {
    return (
      activeBattles.filter((battle) => {
        return (
          battle.tokenIds.find((item) => item.tokenId === nft.tokenId) &&
          battle.isActive
        );
      }).length === 0
    );
  };

  const [jsonData, setJsonData] = useState(null);
  const [createPrice, setCreatePrice] = useState(0);
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState(null);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [selectedNFTs, setSelectedNFTs] = useState([]);
  const [expandConstraints, setExpandConstraints] = useState(false);
  const [_battleConstraints, setBattleConstraints] = useState([
    { name: "No Constraints", value: 0 },
  ]);
  const [battleType, setBattleType] = useState("1v1");
  const battleTypes = [
    "1v1",
    "Free 1v1",
    "2v2",
    "3v3",
    "4v4",
    "5v5",
    "Paid Tourney",
    "Free Tourney",
  ];

  const RarityMap = [
    {
      name: "Common",
      value: 1,
    },
    {
      name: "Rare",
      value: 2,
    },
    {
      name: "Super Rare",
      value: 3,
    },
    {
      name: "Legendary",
      value: 4,
    },
    {
      name: "Unique",
      value: 5,
    },
  ];

  const ElementMap = [
    {
      name: "Earth",
      value: 1,
    },
    {
      name: "Life",
      value: 2,
    },
    {
      name: "Aqua",
      value: 3,
    },
    {
      name: "Fire",
      value: 4,
    },
    {
      name: "Sky",
      value: 5,
    },
    {
      name: "Dark",
      value: 6,
    },
    {
      name: "Mythic",
      value: 7,
    },
    {
      name: "Classic",
      value: 8,
    },
  ];

  const onCreateBattle = async () => {
    //check if user has selected nfts, match battle type
    if (!selectedNFTs || selectedNFTs.length === 0)
      return showErrorToast("Please select at least one NFT");
    if (!battleType) return showErrorToast("Please select a battle type");
    if (!createPrice && !battleType.includes("Free"))
      return showErrorToast("Please enter a price");
    var nftsRequired = battleType.split("v")[1];
    if (
      selectedNFTs.length !== parseInt(nftsRequired) &&
      !battleType.includes("Tourney")
    )
      return showErrorToast(nftsRequired + " NFTs required for " + battleType);
    var RarityConstraints = _battleConstraints.filter(
      (constraint) =>
        RarityMap.filter((rarity) => rarity.name === constraint.name).length > 0
    );
    var ElementConstraints = _battleConstraints.filter(
      (constraint) =>
        ElementMap.filter((element) => element.name === constraint.name)
          .length > 0
    );
    var lowestRarityValue = 1;
    var highestRarityValue = 0;
    var lowestElementValue = 1;
    var highestElementValue = 0;
    if (RarityConstraints.length === 0) {
      lowestRarityValue = RarityMap[0].value;
      highestRarityValue = RarityMap[RarityMap.length - 1].value;
    }
    if (ElementConstraints.length === 0) {
      lowestElementValue = ElementMap[0].value;
      highestElementValue = ElementMap[ElementMap.length - 1].value;
    } else {
      RarityConstraints.sort((a, b) => {
        return a.value - b.value;
      });
      ElementConstraints.sort((a, b) => {
        return a.value - b.value;
      });
      lowestRarityValue = RarityConstraints[0].value;
      highestRarityValue =
        RarityConstraints[RarityConstraints.length - 1].value;
      lowestElementValue = ElementConstraints[0].value;
      highestElementValue =
        ElementConstraints[ElementConstraints.length - 1].value;
    }

    var battle = {
      player1: {
        name: user?.owner_name || "John",
        avatar: user?.avatar || image,
        ponyjacks: selectedNFTs,
      },
      player2: {
        name: "Awaiting Opponent",
        avatar: image,
        ponyjacks: [],
      },
      price: createPrice,
      replays: [],
      type: battleType,
      lowestRarityValue: lowestRarityValue,
      highestRarityValue: highestRarityValue,
      lowestElementValue: lowestElementValue,
      highestElementValue: highestElementValue,
    };
    setSelectedBattle(battle);
    setShow(true);
    setDetails(
      "Battle " +
        battleType +
        " creation for open lobby. Entry price: " +
        createPrice +
        " ETH"
    );
    setJsonData(battle);

    //navigate to battle page, with selected battle
  };

  const handlePriceChange = (event) => {
    const inputValue = event.target.value;

    // Check if the input is a valid decimal number
    if (/^\d*\.?\d*$/.test(inputValue)) {
      // Valid decimal, update state or perform other actions
      setCreatePrice(inputValue);
    } else {
      // Invalid input, you can handle this case accordingly (show an error message, etc.)
      showErrorToast("Enter valid price");
    }
  };

  const handleRemoveNFT = (nft) => {
    setSelectedNFTs(
      selectedNFTs.filter((item) => item.tokenId !== nft.tokenId)
    );
  };

  // This component allows the player to create a new battle.
  const CreateBattleOption = () => {
    return (
      <div className="mb-4 flex justify-center flex-col items-center">
        <h4 className="text-lg font-semibold mb-4 text-gray-700 border-b-2 border-gray-300 pb-2 dark:text-white ">
          Battle Types
        </h4>
        <div className="flex space-x-4 justify-center items-center flex-wrap mb-4 p-2">
          {battleTypes.map((type) => (
            <button
              className={`btn text-white transition-all duration-300 rounded shadow-lg border-2
              ${
                battleType === type
                  ? "bg-violet-700 border-violet-800"
                  : "bg-slate-900 border-gray-300"
              }
              hover:bg-violet-600 hover:border-violet-700`}
              key={type}
              onClick={() => {
                setBattleType(type);
              }}
            >
              {type}
            </button>
          ))}
        </div>
        <BattleConstraintsHandler />
      </div>
    );
  };

  const handleConstraints = (constraint, type) => {
    if (
      _battleConstraints[0]?.name === "No Constraints" ||
      _battleConstraints.length === 0
    ) {
      setBattleConstraints([constraint]);
    } else {
      const finalConstraint = constraint;
      //check if constraint already exists, and only allow setting a <= constraint
      //get value of this contraint from reference map
      const newConstraints = [];
      const arraymap = type === "Rarity" ? RarityMap : ElementMap;
      const previousConstraints = _battleConstraints.filter(
        (constraint) =>
          arraymap.filter((rarity) => rarity.name === constraint.name).length >
          0
      );
      //get battle constraints - previous constraints
      const remainingConstraints = _battleConstraints.filter(
        (constraint) =>
          arraymap.filter((rarity) => rarity.name === constraint.name)
            .length === 0
      );
      newConstraints.push(...remainingConstraints);

      const value = constraint.value;
      //get the lowest and highest value from the previous constraints
      var lowest = 0;
      var highest = 0;
      if (previousConstraints.length > 0) {
        lowest = previousConstraints[0].value;
        highest = previousConstraints[0].value;
        previousConstraints.forEach((constraint) => {
          if (constraint.value < lowest) lowest = constraint.value;
          if (constraint.value > highest) highest = constraint.value;
        });
      }
      if (value < lowest) lowest = value;
      if (value > highest) highest = value;

      //only allow removing this constraint if its already selected and is the highest or lowest
      if (
        previousConstraints.filter(
          (constraint) => constraint.name === finalConstraint.name
        ).length > 0
      ) {
        //check if its the lowest or highest
        if (lowest === value) {
          //remove lowest
          lowest += 1;
        } else if (highest === value) {
          //remove highest
          highest -= 1;
        }
      }
      //select all from lowest to highest in the arraymap
      if (lowest !== 0)
        arraymap.forEach((item) => {
          if (item.value >= lowest && item.value <= highest) {
            newConstraints.push(item);
          }
        });
      else newConstraints.push(finalConstraint);

      setBattleConstraints(newConstraints);
    }
  };
  const getConstraintExplanation = (type) => {
    const arraymap = type === "Rarities" ? RarityMap : ElementMap;
    const constraints = _battleConstraints?.filter(
      (constraint) =>
        arraymap.filter((rarity) => rarity.name === constraint.name).length > 0
    );

    var lowestElement = null;
    var highestElement = null;
    constraints.forEach((constraint) => {
      if (!lowestElement) lowestElement = constraint;
      if (!highestElement) highestElement = constraint;
      if (constraint.value < lowestElement.value) lowestElement = constraint;
      if (constraint.value > highestElement.value) highestElement = constraint;
    });
    if (
      constraints.length === 0 ||
      (lowestElement.value === arraymap[0].value &&
        highestElement.value === arraymap[arraymap.length - 1].value)
    ) {
      return "Open to all " + type;
    }
    if (lowestElement.value === highestElement.value) {
      return "Only " + lowestElement.name;
    } else {
      return (
        "Only " +
        lowestElement.name +
        " to " +
        highestElement.name +
        " " +
        type +
        " (" +
        lowestElement.value +
        " to " +
        highestElement.value +
        ")"
      );
    }
  };
  const BattleConstraintsHandler = () => {
    return (
      <div className="flex justify-center flex-col items-center">
        <h4 className="text-lg font-semibold mb-4 text-gray-700 border-b-2 border-gray-300 pb-2 dark:text-white ">
          Battle Constraints
        </h4>
        <div className="flex flex-col space-x-4 justify-center items-center flex-wrap mb-4 space-y-2 p-2">
          <button
            className={`btn text-white transition-all duration-300 rounded shadow-lg border-2
              ${
                _battleConstraints[0]?.name === "No Constraints"
                  ? "bg-violet-700 border-violet-800"
                  : "bg-slate-900 border-gray-300"
              }
              hover:bg-violet-600 hover:border-violet-700`}
            onClick={() => {
              setBattleConstraints([{ name: "No Constraints", value: 0 }]);
              setExpandConstraints(false);
            }}
          >
            No Constraints
          </button>
          {expandConstraints && (
            <>
              <div className="flex flex-row space-x-4 justify-center items-center flex-wrap mb-4 space-y-2 p-2">
                <h1 className="text-2xl">Rarity{"< or ="}</h1>
                {RarityMap.map((rarity) => (
                  <button
                    key={rarity.name}
                    className={`btn text-white transition-all duration-300 rounded shadow-lg border-2
              ${
                _battleConstraints.filter(
                  (constraint) => constraint.name === rarity.name
                ).length > 0
                  ? "bg-violet-700 border-violet-800"
                  : "bg-slate-900 border-gray-300"
              }
              hover:bg-violet-600 hover:border-violet-700`}
                    onClick={() => {
                      handleConstraints(rarity, "Rarity");
                    }}
                  >
                    {rarity.name}
                  </button>
                ))}
              </div>
              <div className="flex flex-row space-x-4 justify-center items-center flex-wrap mb-4 space-y-2 p-2">
                <h1 className="text-2xl">
                  Element {"< or ="}
                  <p>(Kingdom 1 or 2)</p>{" "}
                </h1>
                {ElementMap.map((element) => (
                  <button
                    key={element.name}
                    className={`btn text-white transition-all duration-300 rounded shadow-lg border-2
              ${
                _battleConstraints.filter(
                  (constraint) => constraint.name === element.name
                ).length > 0
                  ? "bg-violet-700 border-violet-800"
                  : "bg-slate-900 border-gray-300"
              }
              hover:bg-violet-600 hover:border-violet-700`}
                    onClick={() => {
                      handleConstraints(element, "Element");
                    }}
                  >
                    {element.name}
                  </button>
                ))}
              </div>
            </>
          )}
          <div className="flex space-x-4 justify-center items-center flex-wrap mb-4 p-2 bg-gray-100 dark:bg-slate-900 rounded-lg">
            <h2 className="text-lg">{getConstraintExplanation("Rarities")}</h2>{" "}
            <h2 className="text-lg">{getConstraintExplanation("Elements")}</h2>{" "}
          </div>
          {!expandConstraints && (
            <button
              className={`btn  transition-all duration-300 rounded shadow-lg border-2
              hover:bg-violet-600 hover:border-violet-700`}
              onClick={() => {
                setExpandConstraints(true);
              }}
            >
              <i className={`mdi mdi-plus`}>Add</i>{" "}
            </button>
          )}
        </div>
      </div>
    );
  };

  const StartNewBattle = () => {
    return (
      <div className="mb-6 mx-auto flex justify-center flex-col items-center">
        <h4 className="text-2xl font-semibold  text-center">
          Select Assets to Create Event
        </h4>
        {user && user.nfts && user.nfts.length > 0 ? (
          <Collections
            data={user.nfts.filter(
              (nft) => nft.category === "Ponyjacks" && nftNotUsed(nft)
            )}
            setSelectedNFTs={setSelectedNFTs}
            selectedNFTs={selectedNFTs}
            battleType={battleType}
          />
        ) : (
          <div className="flex justify-center items-center h-64">
            No NFTs found
          </div>
        )}
        {selectedNFTs && selectedNFTs.length > 0 && (
          <div className="flex flex-col items-center justify-center flex-wrap">
            <h3 className="text-lg font-semibold mb-4 text-center">
              {" "}
              Selected Assets{" "}
            </h3>
            <div className="grid grid-cols-4 gap-4 flex-col items-center mb-4 w-['90%'] mx-auto">
              {selectedNFTs.map((item, index) => (
                <div
                  key={index}
                  className="flex-col items-center justify-center flex rounded-lg shadow-md dark:shadow-gray-800 p-4 dark:bg-slate-900 bg-opacity-60 dark:bg-opacity-60 bg-green-300"
                >
                  <div className="group relative overflow-hidden rounded-lg shadow dark:shadow-gray-800">
                    <img
                      src={item.image}
                      className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500 w-32 h-32"
                      alt=""
                    />
                    <h3 className="text-lg font-semibold mb-4 text-center">
                      {item.title}
                    </h3>
                    <button
                      className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white absolute top-0 right-0"
                      onClick={() => handleRemoveNFT(item)}
                    >
                      <i className="mdi mdi-close text-3xl"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const getAdminRake = () => {
    var allConfigs =
      adminConfigs?.filter((config) => config.event === battleType) || [];
    if (allConfigs.length === 0) return 0;
    return allConfigs[0].rake;
  };

  return (
    <>
      <TransactionPopup
        setShow={setShow}
        name="Start Battle"
        show={show}
        details={details}
        next="/battlescreen"
        jsonData={jsonData}
      />

      <section className="relative flex justify-top items-center flex-col min-h-screen">
        {user?.owner_name != "" && <StartNewBattle />}
        <CreateBattleOption />
        <div className="flex justify-center mb-4 flex-col items-center">
          {!battleType.includes("Free") && (
            <div className="flex justify-center flex-col items-center">
              <div className="flex justify-center  items-center">
                <h3 className="text-2xl font-semibold text-center text-gray-700 dark:text-white mr-2">
                  Price:
                </h3>
                <input
                  className="input  text-center font-semibold text-2xl border-2 border-gray-300 rounded focus:border-violet-600 focus:ring-0 text-slate-900 shadow-sm"
                  placeholder="Enter Price (PONYJACK)"
                  value={createPrice}
                  onChange={handlePriceChange}
                  type="text"
                />
                <Icon
                  icon="cryptocurrency:eth"
                  className="text-5xl ms-2"
                  color="#8247E5"
                />
              </div>
              <h3 className="text-xl font-semibold text-center text-gray-700 dark:text-white ml-2">
                Fee: {getAdminRake() + "%"}
                {" (From Total Winnings)"}
              </h3>
            </div>
          )}
          {user?.owner_name != "" && (
            <button
              className="btn rounded-full mt-8 bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-2xl text-white shadow-lg"
              onClick={() => onCreateBattle()}
            >
              Create Battle
            </button>
          )}{" "}
        </div>
      </section>
    </>
  );
}

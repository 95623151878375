import React, { useState } from "react";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../components/attributeDisplay";

export default function ArenaSwitcher(props) {
  const { selectedArena, setSelectedArena } = props;
  const handleArenaChange = (arena) => {
    setSelectedArena(arena);
    setOnArenaChange(false);
  };
  const [onArenaChange, setOnArenaChange] = useState(false);

  const arenas = [
    "Sky",
    "Classic",
    "Dark",
    "Life",
    "Earth",
    "Fire",
    "Mythic",
    "Aqua",
  ];

  return (
    <div className="">
      {onArenaChange && (
        <div className="flex absolute z-50 top-0 left-0 p-8 space-x-4 justify-center items-center flex-wrap mb-4  w-full bg-slate-600 bg-opacity-80 rounded-xl shadow-lg">
          {arenas.map((arena) => (
            <div className="flex justify-center items-center mb-4 p-2">
              <button
                className="btn p-2 m-2 text-white bg-blue-500 hover:bg-violet-600 transition-all duration-300 rounded shadow-lg border-blue-600 hover:border-violet-700"
                onClick={() => handleArenaChange(arena)}
              >
                {arena}
              </button>
              {<AttributeIcon value={arena} size={20} />}
            </div>
          ))}
        </div>
      )}
      {selectedArena ? (
        <div className="mb-4 flex justify-center items-center">
          <button
            className="btn p-2 m-2 text-white bg-blue-500 hover:bg-violet-600 transition-all duration-300 rounded shadow-lg border-blue-600 hover:border-violet-700"
            onClick={() => setOnArenaChange(!onArenaChange)}
          >
            Switch Arena
          </button>
          <AttributeIcon value={selectedArena} size={12} />
          <span
            className="p-2 ms-3 text-white bg-violet-500 hover:bg-violet-600 transition-all duration-300 rounded shadow-lg border-blue-600 hover:border-violet-700
            text-lg font-semibold
            "
          >
            {selectedArena}
          </span>
        </div>
      ) : (
        <div className="mb-4 flex justify-center items-center">
          <button
            className="btn p-2 text-white bg-blue-500 hover:bg-violet-600 transition-all duration-300 rounded shadow-lg border-blue-600 hover:border-violet-700"
            onClick={() => setOnArenaChange(!onArenaChange)}
          >
            Pledge Kingdom
          </button>
          <i className="mdi mdi-arrow-right-bold text-4xl ml-2 text-gray-600"></i>
        </div>
      )}
    </div>
  );
}

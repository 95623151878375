import React, { useContext, useEffect, useState } from "react";
const attributeIcon = (trait) => {
  switch (trait) {
    // case "Jacktype":
    //   return {
    //     icon: "chess-king",
    //     className: "absolute top-0 left-0 p-2",
    //   };
    case "Diamond Horseshoe":
      return {
        icon: "diamond",
        className: "absolute top-0 right-0 p-2",
      };
    case "Reroll":
      return {
        icon: "dice-6",
        className: "absolute bottom-0 left-0 p-2",
      };
    // case "Gen":
    //   return {
    //     icon: "dna",
    //     className: "absolute bottom-10 left-0 p-2",
    //   };

    case "Rarity":
      return {
        icon: "diamond",
        className: "absolute top-0 right-0 p-2",
      };
    case "Kingdom 1":
      return {
        icon: "castle",
        className: "absolute bottom-0 left-0 p-2",
      };
    case "Kingdom 2":
      return {
        icon: "flag",
        className: "absolute bottom-0 right-0 p-2",
      };

    case "Early Access":
      return {
        icon: "star",
        className: "absolute top-0 right-0 p-2",
      };
    case "Mystery Box":
      return {
        icon: "gift",
        className: "absolute top-0 left-0 p-2",
      };
    case "Wave":
      return {
        icon: "wave",
        className: "absolute top-0 left-0 p-2",
      };
    default:
      return {
        icon: "",
        className: "absolute top-0 left-0 p-2",
      };
  }
};
const attributeImage = (value) => {
  let checkbyName;
  try {
    const module = require(`../assets/images/Ponyjacks/${value}.png`);
    checkbyName = module ? module : false;
  } catch (error) {
    checkbyName = false;
  }

  if (checkbyName) {
    return { icon: checkbyName };
  } else {
    return { icon: "" };
  }
};

const RarityData = (rarity) => {
  let color = "";
  switch (rarity) {
    case "Common":
      color = "#10B981"; // green-400
      break;
    case "Rare":
      color = "#3B82F6"; // blue-400
      break;
    case "Super Rare":
      color = "#EF4444"; // red-500
      break;
    case "Legendary":
      color = "#FBBF24"; // yellow-400
      break;
    case "Unique":
      color = "#EC4899"; // pink-500
      break;
    default:
      color = "#4B5563"; // gray-600
  }
  return color;
};

const KingdomData = (kingdom) => {
  let color = "";
  switch (kingdom) {
    case "Life":
      color = "#10B981"; // green-400
      break;
    case "Fire":
      color = "#EF4444"; // red-500
      break;
    case "Earth":
      color = "#D97706"; // yellow-600
      break;
    case "Sky":
      color = "#3B82F6"; // blue-400
      break;
    case "Classic":
      color = "#F87171"; // red-400
      break;
    case "Dark":
      color = "#1F2937"; // slate-900
      break;
    case "Aqua":
      color = "#14B8A6"; // teal-400
      break;
    case "Mythic":
      color = "#FECACA"; // red-200
      break;
    default:
      color = "#4B5563"; // gray-600
  }
  return color;
};

export const RarityGradient = ({ rarity, kingdom1, kingdom2 }) => {
  const rarityColor = RarityData(rarity);
  const kingdom1Color = KingdomData(kingdom1);
  const kingdom2Color = KingdomData(kingdom2);

  const gradientColors = `linear-gradient(to right, ${rarityColor}, ${kingdom1Color}, ${kingdom2Color})`;

  return (
    <div
      style={{
        background: gradientColors,
      }}
      className="absolute inset-0 rounded-lg -ms-1 -mt-1 group-hover:-mt-2 group-hover:-ms-2 h-[98%] w-[98%] -z-2 transition-all duration-500"
    ></div>
  );
};

export const AttributeIcon = ({ trait, value, stat, size }) => {
  var hasImage = false;

  hasImage = attributeImage(value).icon ? true : false;

  return (
    <div className="relative z-3 flex gap-2 items-center justify-center">
      <div
        className={`relative z-3 rounded-full bg-gray-500 bg-opacity-80
  dark:bg-slate-800 dark:bg-opacity-90 hover:dark:bg-opacity-100 flex items-center justify-center
  hover:bg-opacity-100 transition-all duration-300  ${
    size ? `w-${size} h-${size}` : "w-12 h-12"
  }`}
      >
        <img
          src={attributeImage(value).icon}
          alt=""
          className={`${
            stat && size
              ? `w-${size} h-${size} object-cover rounded-full opacity-70`
              : ""
          }`}
        />
      </div>
      {stat && <h4 className="font-bold text-white dark:text-black">{stat}</h4>}{" "}
    </div>
  );
};

export default function AttributeDisplay({ attributes, iconSize }) {
  var size = iconSize ? iconSize : 10;
  return (
    <div className="flex justify-between w-full flex-wrap">
      {attributes
        ?.filter((attr) => attributeIcon(attr.trait_type).icon !== "")
        .map((attribute, index) => (
          <AttributeIcon
            trait={attribute.trait_type}
            value={attribute.value}
            stat={attribute.stat}
            size={size}
            key={index}
          />
        ))}
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { UserContext } from "../../store/UserContext";
import { BattleContext } from "../../store/BattleContext";
import {
  AttributeIcon,
  default as AttributeDisplay,
  RarityGradient,
} from "../../components/attributeDisplay";

import HistoryComponent from "../../components/historyComponent";

export default function ItemDetail() {
  const [activeIndex, setIndex] = useState(0);
  const [ponyBool, setPonyBool] = useState(false);
  const { user, allUsers, selectedItem, selectItemDetails } =
    useContext(UserContext);
  const { leaderboardData } = useContext(BattleContext);

  const shiftIndex = (direction) => {
    var allItems = user?.nfts || [];
    var currentIndex = allItems.findIndex(
      (item) => item.tokenId === selectedItem.tokenId
    );
    var newIndex = currentIndex;
    if (direction === "left") {
      if (currentIndex === 0) {
        newIndex = allItems.length - 1;
      } else {
        newIndex = currentIndex - 1;
      }
    } else {
      if (currentIndex === allItems.length - 1) {
        newIndex = 0;
      } else {
        newIndex = currentIndex + 1;
      }
    }
    console.log(allItems[newIndex]);
    selectItemDetails(allItems[newIndex]);
  };

  console.log(selectedItem);

  const getLeaderboardData = () => {
    var allData = leaderboardData?.leaderboard || [];
    var currentData = allData?.find(
      (item) => selectedItem.tokenId.toString() === item.nft_id.toString()
    );
    if (!currentData) return null;
    var finalData = [
      {
        trait_type: "Total Battles",
        value: currentData.total_battles,
        stat: "battles",
        color: "text-violet-600",
      },
      {
        trait_type: "Wins",
        value: currentData.total_wins,
        stat: "wins",
        color: "text-green-500",
      },
      {
        trait_type: "Amount Lost",
        value: currentData.total_amount_lost,
        stat: "Amount Lost",
        color: "text-red-500",
        icon: "mdi mdi-ethereum",
      },
      {
        trait_type: "Amount Won",
        value: currentData.total_amount_won,
        stat: "Amount Won",
        color: "text-green-500",
        icon: "mdi mdi-ethereum",
      },
      {
        trait_type: "Win Rate",
        value:
          (currentData.total_wins / currentData.total_battles) * 100 + " %",
        stat: "win_rate",
        color: "text-yellow-500",
      },
    ];
    return finalData;
  };

  const getOwnerData = (owner_wallet_id) => {
    var ownerName =
      allUsers?.find(
        (user) =>
          user.owner_wallet_id.toLowerCase() == owner_wallet_id.toLowerCase()
      ) || null;
    return ownerName;
  };

  return !selectedItem ? (
    <>
      <Navbar />
      <section className="relative pt-28 md:pb-24 pb-16 min-h-screen">
        <div className="container mx-auto px-4 mt-8 mb-8 text-center">
          <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white text-center">
            No item selected
          </h3>
          <Link
            to="/mystable"
            className="btn btn-lg mt-4 rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white ms-2"
          >
            <i className="mdi mdi-open-in-new"></i>
            Back to Stable
          </Link>
        </div>
      </section>
    </>
  ) : (
    <>
      <Navbar />
      <section className="relative pt-28 md:pb-24 pb-16 min-h-screen">
        <div className="container">
          <button
            className="absolute top-1/4 left-0 mt-4 mr-4 p-2 bg-white dark:bg-slate-900 rounded-full shadow dark:shadow-gray-700"
            onClick={() => shiftIndex("left")}
          >
            <i className="mdi mdi-chevron-left text-2xl text-gray-800 dark:text-white"></i>
          </button>{" "}
          <button
            className="absolute top-1/4 right-0 mt-4 mr-4 p-2 bg-white dark:bg-slate-900 rounded-full shadow dark:shadow-gray-700"
            onClick={() => shiftIndex("right")}
          >
            <i className="mdi mdi-chevron-right text-2xl text-gray-800 dark:text-white"></i>
          </button>{" "}
          <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 gap-[30px]">
            <div className="lg:col-span-5">
              <div
                className="group relative p-2 rounded-lg bg-slate-200 dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700
                 transition-all duration-500 "
              >
                <RarityGradient
                  rarity={selectedItem?.rarity}
                  kingdom1={selectedItem?.arena_type}
                  kingdom2={selectedItem?.sub_arena_type}
                />

                <div className="relative overflow-hidden rounded-lg">
                  <img
                    src={selectedItem.image}
                    className="rounded-md shadow dark:shadow-gray-700"
                    alt=""
                  />
                </div>
              </div>{" "}
              <div className="bg-gray-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-800 mt-[30px] p-6">
                <div>
                  <span className="font-medium text-slate-400 block mb-1">
                    Contract Address
                  </span>
                  <a
                    className="text-slate-600 underline block hover:text-violet-700"
                    href={`https://polygonscan.com/address/${"0xf00193fa8f4049260268e459d42db202982f5c7d"}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {selectedItem.contractAddress || "0x000000 "}
                  </a>
                </div>

                <div className="mt-4">
                  <span className="font-medium text-slate-400 block mb-1">
                    Token ID
                  </span>
                  <a
                    href={`https://testnets.opensea.io/assets/mumbai/${"0xf00193fa8f4049260268e459d42db202982f5c7d"}/${
                      selectedItem.tokenId
                    }`}
                    target="_blank"
                    className="text-slate-600 underline block hover:text-violet-700"
                    rel="noreferrer"
                  >
                    {selectedItem.tokenId}
                  </a>
                </div>

                <div className="mt-4">
                  <span className="font-medium text-slate-400 block mb-1">
                    Owner
                  </span>
                  {selectedItem.owner_wallet_id &&
                    getOwnerData(selectedItem.owner_wallet_id) && (
                      <span className="text-slate-200 text-md">
                        {" "}
                        {getOwnerData(selectedItem.owner_wallet_id).owner_name}
                      </span>
                    )}{" "}
                  <a
                    href={`https://testnets.opensea.io/${selectedItem.owner_wallet_id}`}
                    target="_blank"
                    className="text-slate-600 underline block hover:text-violet-700"
                    rel="noreferrer"
                  >
                    {selectedItem.owner_wallet_id || "Ponyjacks"}
                  </a>
                </div>

                <div className="mt-4">
                  <span className="font-medium text-slate-400 block mb-1">
                    Blockchain
                  </span>
                  <span className="font-medium block">Polygon</span>
                </div>
              </div>
            </div>

            <div className="lg:col-span-7 lg:ms-8">
              <h5 className="md:text-2xl text-xl font-semibold">
                {selectedItem.title}
              </h5>

              <p className="mt-4 dark:text-violet-400">
                @ {selectedItem.owner || "Ponyjacks"}
              </p>
              <p className="dark:text-gray-300 text-slate-500 mt-4">
                {selectedItem.subtext}
              </p>

              {selectedItem.price ? (
                <div className="mt-4">
                  <span className="text-lg font-medium text-slate-400 block">
                    Market Price
                  </span>
                  <span className="tmd:text-2xl text-xl font-semibold block mt-2">
                    <i className="mdi mdi-ethereum"></i> 3.5 ETH = $ 4,659.75
                  </span>
                </div>
              ) : (
                <></>
              )}

              <div className="mt-6">
                {selectedItem.category === "Ponyjacks" ? (
                  <>
                    <button
                      data-modal-toggle="NftBid"
                      className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"
                    >
                      <Link to="/lobby">
                        <i className="mdi mdi-sword-cross"></i>
                        Start Battle
                      </Link>{" "}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      data-modal-toggle="NftBid"
                      className="btn rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"
                    >
                      <Link to="/mint">
                        <i className="mdi mdi-gift"></i>
                        Unpack
                      </Link>{" "}
                    </button>
                  </>
                )}
                <button
                  data-modal-toggle="NftBid"
                  className="btn rounded-full bg-blue-500 hover:bg-blue-600 border-blue-600 hover:border-blue-700 text-white ms-2"
                >
                  <i className="mdi mdi-open-in-new"></i>
                  View on Opensea
                </button>
              </div>

              <div
                className="md:grid p-6 bg-gray-200 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6 
              grid-cols-2 gap-4"
              >
                {selectedItem.attributes?.map((attr) => (
                  <div className="flex items-center border border-gray-300 dark:border-gray-700 p-2 rounded-lg">
                    <div className="flex flex-col items-center">
                      <div className="text-s font-bold dark:text-white text-slate-800 mr-2">
                        {attr.trait_type}
                      </div>
                      <div className="text-s font-normal dark:text-red-500 text-slate-800 mr-2">
                        {attr.value}{" "}
                      </div>
                    </div>
                    <div className="relative inline-block bg-gray-100 dark:bg-slate-700 p-2 rounded-lg">
                      <AttributeIcon
                        trait={attr.trait_type}
                        value={attr.value}
                        stat={attr.stat}
                        size={16}
                      />
                    </div>
                  </div>
                ))}
              </div>
              <div
                className="md:grid p-6 bg-gray-200 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700 mt-6 
              grid-cols-2 gap-4"
              >
                {getLeaderboardData()?.map((attr) => (
                  <div className="flex items-center border border-gray-300 dark:border-gray-700 p-2 rounded-lg justify-between">
                    <div className="text-lg font-normal dark:text-white text-slate-800 mr-2">
                      {attr.trait_type}
                    </div>
                    <div className={`text-lg font-bold mr-2 ${attr.color}`}>
                      {attr.value}{" "}
                      {attr.icon && (
                        <i className={`${attr.icon} text-lg ${attr.color}`}></i>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              {/* <HistoryComponent /> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}

import React, { useContext, useState } from "react";
import { UserContext } from "../store/UserContext";
import { BattleContext } from "../store/BattleContext";
import TransactionPopup from "../components/transactionPopup";
import Collections from "../components/nfts";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../components/attributeDisplay";
import { Link, useNavigate } from "react-router-dom";
import { showErrorToast } from "../components/NotificationService";
import { showSuccessToast } from "../components/NotificationService";

const ConfirmationPopup = ({ show, handleClose, battle }) => {
  console.log("Battle", battle);
  const navigate = useNavigate(); // <-- Add this line
  const { user, setSelectedBattle } = useContext(UserContext);
  const { activeBattles } = useContext(BattleContext);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [showx, setShowx] = useState(false);
  const [details, setDetails] = useState(null);
  const [jsonData, setJsonData] = useState(null);
  const battleType = battle?.type || "2v2";
  const numPlayers = battle?.player2?.ponyjacks?.length || 2;

  const [selectedNFTs, setSelectedNFTs] = useState([]);

  const [showModal, setShowModal] = useState(false);

  const nftNotUsed = (nft) => {
    return (
      activeBattles.filter((battle) => {
        return battle.tokenIds.find((item) => item.tokenId === nft.tokenId);
      }).length === 0
    );
  };
  const RarityMap = [
    {
      name: "Common",
      value: 1,
    },
    {
      name: "Rare",
      value: 2,
    },
    {
      name: "Super Rare",
      value: 3,
    },
    {
      name: "Legendary",
      value: 4,
    },
    {
      name: "Unique",
      value: 5,
    },
  ];

  const ElementMap = [
    {
      name: "Earth",
      value: 1,
    },
    {
      name: "Life",
      value: 2,
    },
    {
      name: "Aqua",
      value: 3,
    },
    {
      name: "Fire",
      value: 4,
    },
    {
      name: "Sky",
      value: 5,
    },
    {
      name: "Dark",
      value: 6,
    },
    {
      name: "Mythic",
      value: 7,
    },
    {
      name: "Classic",
      value: 8,
    },
  ];

  const getConstraintExplanation = (type, _battleConstraints) => {
    const arraymap = type === "Rarities" ? RarityMap : ElementMap;
    const constraints = _battleConstraints;

    var lowestElement = null;
    var highestElement = null;
    if (type === "Rarities") {
      lowestElement = constraints?.mixRarity?.toString()?.split("n")?.[0];
      highestElement = constraints?.maxRarity?.toString()?.split("n")?.[0];
    } else {
      lowestElement = constraints?.minKingdom1?.toString()?.split("n")?.[0];
      highestElement = constraints?.maxKingdom1?.toString()?.split("n")?.[0];
    }

    lowestElement = arraymap.filter(
      (element) => element.value === parseInt(lowestElement)
    )?.[0];
    highestElement = arraymap.filter(
      (element) => element.value === parseInt(highestElement)
    )?.[0];

    if (
      lowestElement.value === arraymap[0].value &&
      highestElement.value === arraymap[arraymap.length - 1].value
    ) {
      return "Open to all " + type;
    }
    if (lowestElement.value === highestElement.value) {
      return "Only " + lowestElement.name;
    } else {
      return "Only " + lowestElement.name + " to " + highestElement.name;
    }
  };

  const matchConstraints = (nft) => {
    const constraints = battle?.constraints;
    const lowestRarity = constraints?.mixRarity?.toString()?.split("n")?.[0];
    const highestRarity = constraints?.maxRarity?.toString()?.split("n")?.[0];
    const lowestElement = constraints?.minKingdom1?.toString()?.split("n")?.[0];
    const highestElement = constraints?.maxKingdom1
      ?.toString()
      ?.split("n")?.[0];

    const nftRarityValue = RarityMap.filter(
      (element) => element.name.toLowerCase() === nft.rarity.toLowerCase()
    )?.[0]?.value;
    const nftElementValue = ElementMap.filter(
      (element) => element.name.toLowerCase() === nft.arena_type.toLowerCase()
    )?.[0]?.value;

    if (
      nftRarityValue >= parseInt(lowestRarity) &&
      nftRarityValue <= parseInt(highestRarity) &&
      nftElementValue >= parseInt(lowestElement) &&
      nftElementValue <= parseInt(highestElement)
    ) {
      return true;
    }
  };

  const handleConfirm = () => {
    console.log("Confirming battle", battle);
    var battlex = {
      player1: {
        name: battle?.player1?.name || "John",
        avatar: battle?.player1?.avatar || null,
        ponyjacks: selectedNFTs,
      },
      player2: battle?.player2 || {
        name: user?.owner_name || "John",
        avatar: user?.avatar || null,
        ponyjacks: [selectedNFT],
      },
      price: battle?.price || 0.1,
      replays: [],
      battleId: battle?.battleId || 0,
      betToken: battle?.betToken || "",
    };
    console.log(battlex);
    setSelectedBattle(battlex);

    setDetails(
      "Joining " +
        selectedNFTs?.length +
        "v" +
        battlex?.player2?.ponyjacks?.length +
        "battle for " +
        battle?.price +
        " ETH " +
        " vs " +
        battle?.player2?.name
      //+ " /" +
      // parseFloat(parseFloat(battle?.price) * 1.98).toFixed(2) +
      // " ETH"
    );
    setShowx(true);
    setJsonData(battlex);

    //navigate to battle page, with selected battle
  };

  const removeItem = (item) => {
    setSelectedNFTs(selectedNFTs.filter((nft) => nft.tokenId !== item.tokenId));
  };

  return show ? (
    <div
      className={` fixed inset-0 w-full mt-20 flex items-center justify-center z-10 overflow-x-hidden overflow-y-auto 
       md:justify-center`}
    >
      <TransactionPopup
        setShow={setShowx}
        show={showx}
        details={details}
        jsonData={jsonData}
        name="Join Battle"
        next="/battlescreen"
      />
      <div className="fixed inset-0 bg-gray-700 z-10 bg-opacity-90"></div>
      <div
        className={`flex container flex-col items-center justify-center z-30 bg-slate-200 dark:bg-slate-900 rounded-lg ${
          showx ? "" : ""
        }`}
      >
        <p className="text-xl font-bold mb-4 text-center">
          Select {numPlayers} Assets to join event{" "}
        </p>
        {user && user.nfts ? (
          showModal ? (
            <div className="fixed flex-col items-center justify-center flex inset-0 bg-gray-700 bg-opacity-90 z-30">
              <Collections
                data={user.nfts.filter(
                  (nft) =>
                    nft.category === "Ponyjacks" &&
                    nftNotUsed(nft) &&
                    matchConstraints(nft)
                )}
                selectedNFTs={selectedNFTs}
                setSelectedNFTs={setSelectedNFTs}
                battleType={battleType}
              />
              <button
                className="btn btn-secondary bg-red-500 rounded-full flex items-center justify-center font-bold m-4 z-50"
                onClick={() => setShowModal(false)}
              >
                <i className="mdi mdi-close text-xl"></i> Close
              </button>{" "}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center">
              <button
                className="btn btn-secondary bg-green-500 rounded-full flex items-center justify-center font-bold"
                onClick={() => setShowModal(true)}
              >
                Select Assets
              </button>
            </div>
          )
        ) : (
          <div className="flex justify-center items-center h-64">
            No NFTs found
          </div>
        )}
        {/*<div className={`grid mt-4 gap-2 items-center justify-center lg:grid-cols-${numPlayers} md:grid-cols-1`}>*/}
        <div
          className={`flex p-4 items-start justify-start  gap-4 mt-2 max-w-[90%] overflow-x-auto`}
        >
          {selectedNFTs &&
            selectedNFTs.length > 0 &&
            selectedNFTs.map((item, index) => (
              <div className="group relative flex flex-col items-center justify-center bg-gray-100 dark:bg-slate-800 p-2 rounded-lg mx-auto rounded max-w-2xl">
                <RarityGradient
                  rarity={item?.rarity}
                  kingdom1={item?.arena_type}
                  kingdom2={item?.sub_arena_type}
                />

                <div className="flex flex-col items-center justify-center rounded-full  w-full h-full  text-slate-900 dark:text-white">
                  <div className="relative overflow-hidden rounded-xl">
                    <img
                      src={item?.image}
                      alt={item?.title}
                      className="w-32 h-32"
                    />
                  </div>
                  <AttributeDisplay
                    attributes={item?.attributes}
                    iconSize={8}
                  />
                  <div className="flex  items-center justify-center gap-2 ">
                    <div className="text-xl dark:text-white font-semibold">
                      {item?.title}
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center flex font-bold mt-2">
                  <button
                    className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white "
                    onClick={() => removeItem(item)}
                  >
                    <i className="mdi mdi-close text-s"></i>
                  </button>{" "}
                </div>
              </div>
            ))}
        </div>
        <p className="text-center  text-2xl mt-3 text-red-600  rounded-2xl mx-auto max-w-xs italic">
          <i className="mdi mdi-sword-cross"></i> {"  "}
          V/S{" "}
        </p>
        <h5 className="text-xl font-bold text-center text-black dark:text-white">
          Constraints
        </h5>{" "}
        {battle.constraints && (
          <div className="flex items-center mt-4 mb-4 justify-around gap-4">
            <span className="text-s text-slate-600  font-semibold dark:text-slate-400">
              {getConstraintExplanation("Rarities", battle.constraints)}
            </span>
            <span className="text-s text-slate-600  font-semibold dark:text-slate-400">
              {getConstraintExplanation("Elements", battle.constraints)}
            </span>
          </div>
        )}{" "}
        <div className="flex flex-col items-center mt-4  justify-center bg-gray-100 dark:bg-slate-800  rounded-lg mx-auto rounded overflow-x-auto">
          <div className="text-xl font-semibold flex items-center rounded mr-14 p-2 text-slate-900 dark:text-white">
            <div className="flex items-center justify-center rounded-full mr-2">
              <img
                src={battle?.player2?.avatar}
                alt="Player 2"
                className="w-12 h-12 rounded-full mr-2"
              />{" "}
              {battle?.player2?.name}
            </div>
          </div>
        </div>
        <div
          className={`flex p-4 items-start justify-start gap-4 mt-2 max-w-[90%] overflow-x-auto`}
        >
          {battle?.player2?.ponyjacks &&
            battle?.player2?.ponyjacks.length > 0 &&
            battle?.player2?.ponyjacks.map((item, index) => (
              <div className="group relative flex flex-col items-center justify-center bg-gray-100 dark:bg-slate-800 p-2 rounded-lg mx-auto rounded max-w-2xl">
                <RarityGradient
                  rarity={item?.rarity}
                  kingdom1={item?.arena_type}
                  kingdom2={item?.sub_arena_type}
                />

                <div className="flex flex-col items-center justify-center rounded-full  w-full h-full  text-slate-900 dark:text-white">
                  <div className="relative overflow-hidden rounded-xl z-10">
                    <img
                      src={item?.image}
                      alt={item?.title}
                      className="w-32 h-32"
                    />
                  </div>
                  <AttributeDisplay
                    attributes={item?.attributes}
                    iconSize={8}
                  />
                  <div className="text-xl dark:text-white font-semibold">
                    {item?.title}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="text-center font-bold text-2xl mt-2 bg-gray-100 dark:bg-slate-800 p-2 rounded-lg mx-auto space-x-4 flex items-center justify-center">
          <div className="text-xl font-bold ">Entry Price </div>{" "}
          <div className="text-2xl font-bold text-violet-600">
            {battle?.price} ETH
          </div>{" "}
          <i className="mdi mdi-ethereum text-4xl text-violet-500"></i>
        </div>
        <div className="text-center font-bold text-2xl mt-2 bg-gray-100 dark:bg-slate-800 p-2 rounded-lg mx-auto space-x-4 flex flex-wrap items-center justify-center">
          <div className="text-xl font-bold ">Winner payout </div>{" "}
          <div className="text-2xl font-bold text-violet-600 flex items-center justify-center">
            {parseFloat(parseFloat(battle?.price) * 1.98).toFixed(2)} ETH
            <i className="mdi mdi-ethereum text-4xl text-violet-500"></i>
            <div className="text-xl font-bold "> (2% fee)</div>
          </div>{" "}
        </div>
        <div className="flex justify-around m-4">
          <button
            type="button"
            className="btn btn-lg bg-green-500 rounded-full flex items-center justify-center font-bold"
            onClick={handleConfirm}
            disabled={selectedNFTs.length < numPlayers}
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-lg bg-red-500 rounded-full flex items-center justify-center font-bold ml-4"
            onClick={handleClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  ) : null;
};

export default ConfirmationPopup;

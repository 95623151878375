import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";

const TournamentDetail = ({ tournament, show, setShow }) => {
  return !show ? (
    <></>
  ) : (
    <>
      <section className="fixed top-0 left-0 inset-0 z-50 flex items-center justify-center backdrop-blur-md">
        <div className="container py-6 bg-white dark:bg-slate-900 rounded-lg shadow-md">
          <div className="grid lg:grid-cols-12 grid-cols-1 gap-6">
            <div className="lg:col-span-7">
              <h1 className="text-3xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                {tournament.name}
              </h1>
              <div className="bg-white dark:bg-slate-900 rounded-lg shadow-md overflow-hidden">
                <img
                  src={tournament.image}
                  alt={tournament.name}
                  className="w-full h-auto"
                />
              </div>
            </div>
            <div className="lg:col-span-5">
              <div className="bg-white dark:bg-slate-900 rounded-lg shadow-md p-6">
                <div className="mb-4">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                    Tournament Details
                  </h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    Date: {tournament.date}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400">
                    Entry Fee: {tournament.entryFee} ETH
                    <i className="mdi mdi-ethereum text-2xl ml-2"></i>
                  </p>
                  <p className="text-gray-600 dark:text-gray-400">
                    Prize Pool: {tournament.prizePool} ETH
                    <i className="mdi mdi-ethereum text-2xl ml-2"></i>
                  </p>
                  <p className="text-gray-600 dark:text-gray-400">
                    Type: {tournament.type}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400">
                    Format: {tournament.format}
                  </p>
                  <p className="text-gray-600 dark:text-gray-400">
                    Status: {tournament.status}
                  </p>
                </div>
                <div className="mt-4">
                  <button className="btn bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-full transition duration-300">
                    Join Tournament
                  </button>{" "}
                  <button
                    onClick={() => setShow(false)}
                    className="btn bg-gray-400 hover:bg-gray-500 text-white py-2 px-4 rounded-full transition duration-300"
                  >
                    Back to All Tournaments
                  </button>
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-200 mb-4">
                  Participants
                </h2>
                {/* Include your participants grid component here */}
                {/* Replace the following placeholder */}
                <div className="grid grid-cols-2 gap-4">
                  {tournament.participants?.map((participant) => (
                    <div className="bg-white dark:bg-slate-900 rounded-lg shadow-md p-6">
                      <div className="mb-4 flex items-center justify-center space-x-4">
                        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                          {participant.name}
                          <p className="text-gray-600 dark:text-gray-400 text-xs">
                            {participant.address}
                          </p>
                        </h2>
                        <img
                          src={participant.avatar}
                          alt={participant.avatar}
                          className="w-16  h-16 rounded-full"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </section>
    </>
  );
};

export default TournamentDetail;

import Attacks from "./Attacks.json";
export const AttackFinder = ({ currentRound, teamID, data }) => {
  const JsonData = currentRound && JSON.parse(currentRound?.json_data);
  console.log(JsonData);
  console.log(data);
  const atk_damageArr = JsonData?.atk_damageArr;
  const def_damageArr = JsonData?.def_damageArr;
  const attackingTeamID = parseInt(JsonData?.basic?.attackingTeamID);
  const damage_received =
    teamID === attackingTeamID ? atk_damageArr : def_damageArr;
  console.log(atk_damageArr);
  console.log(def_damageArr);
  console.log(damage_received);
  var finalArray = [];
  const atk_team_roster = JsonData?.atk_team_roster;
  const def_team_roster = JsonData?.def_team_roster;
  const team_roster =
    teamID === attackingTeamID ? atk_team_roster : def_team_roster;
  const active_team_pony = team_roster?.[0]?.active;
  console.log(active_team_pony);
  const rollResultsArray = JsonData?.rollResults || [];
  const getAllRolls = (rollResultsArray) => {
    const allRolls = [];

    rollResultsArray.forEach((obj) => {
      const key = Object.keys(obj)[0]; // Extract the key (like 'attack', 'dodge', ...)
      const { team_id, result } = obj[key]; // Extract 'team_id' and 'result' from the inner object
      if (team_id === teamID)
        allRolls.push({ diceRolls: result, team_id, type: key });
    });

    return allRolls;
  };

  const allRolls = getAllRolls(rollResultsArray);
  const successDodge = allRolls.filter(
    (item) =>
      item.type === "dodge" &&
      item.team_id === teamID &&
      item.diceRolls.overallTotal >= 1
  );
  if (successDodge.length > 0) {
    var dodgeAnim = Attacks.filter((item) => item.name === "Dodge")?.[0]?.ltr;
    finalArray.push(dodgeAnim);
  }

  var attackingKingdom1 = data?.name || "Dark";
  console.log(attackingKingdom1);
  if (attackingKingdom1 == "Arena") {
    //find next highest value in data?.allBonus
    var allBonus = data?.allBonus;
    var bonus = allBonus
      ?.sort((a, b) => b.value - a.value)
      ?.filter((itemx) => itemx.name != attackingKingdom1)?.[0];
    attackingKingdom1 = bonus?.name;
  }

  for (var i = 0; i < damage_received.length; i++) {
    var dmgTaken = damage_received[i].dmgTaken;
    var dammage_type = damage_received[i].dammage_type;
    var baseAnimation = Attacks.filter((item) => item.name === "Kick")?.[0];
    if (dmgTaken === 0) continue;

    if (dammage_type == "super") {
      baseAnimation = Attacks.filter(
        (item) => item.name === `${attackingKingdom1}_Super`
      )?.[0];
    } else if (dmgTaken === 1)
      baseAnimation = Attacks.filter((item) => item.name === "Kick")?.[0];
    else if (dmgTaken === 2)
      baseAnimation = Attacks.filter((item) => item.name === "Horseshoe")?.[0];
    else if (dmgTaken >= 3)
      baseAnimation = Attacks.filter(
        (item) => item.name === attackingKingdom1
      )?.[0];
    var finalGifFile = teamID === 2 ? baseAnimation?.ltr : baseAnimation?.rtl;
    if (finalGifFile) finalArray.push(finalGifFile);
  }

  console.log("finalArray", finalArray);
  return finalArray;

  //   if (teamID === attackingTeamID && !isSpeedRoll) {
  //     var nft = sortedData[0];
  //     //get kingdom 1
  //     var kingdom1 = nft.arena_type;
  //     var allAnimations = Attacks.filter((item) => item.name === kingdom1);
  //     if (allAnimations.length === 0)
  //       allAnimations = Attacks.filter((item) => item.name === "Classic");
  //     var animation = allAnimations[0];
  //     var gifFile = attackingTeamID === 1 ? animation.ltr : animation.rtl;
  //     return gifFile;
  //   }
  //   return null;
};

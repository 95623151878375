import React from "react";
import logo_white from "../assets/images/logo-white.png";
import app from "../assets/images/app.png";
import playstore from "../assets/images/playstore.png";
import { Link } from "react-router-dom";
import { Mail, Phone } from "react-feather";

export default function Footer() {
  return (
    <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
      <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
        <div className="container text-center">
          <div className="grid md:grid-cols-2 items-center gap-6">
            <div className="md:text-start text-center">
              <p className="mb-0 text-gray-300">
                © {new Date().getFullYear()} Ponyjacks
              </p>
            </div>

            <ul className="list-none md:text-end text-center">
              {/* <li className="inline"><Link to="https://1.envato.market/Ponyjacks-react" target="_blank" className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i className="uil uil-shopping-cart align-middle" title="Buy Now"></i></Link></li> */}

              <li className="inline">
                <Link
                  to="https://discord.gg/ponyjacks"
                  target="_blank"
                  className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                >
                  <i
                    className="uil uil-discord align-middle"
                    title="discord"
                  ></i>
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://twitter.com/ponyjacks"
                  target="_blank"
                  className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                >
                  <i
                    className="uil uil-twitter align-middle"
                    title="twitter"
                  ></i>
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="https://telegram.me/ponyjacks"
                  target="_blank"
                  className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                >
                  <i
                    className="uil uil-telegram align-middle"
                    title="telegram"
                  ></i>
                </Link>
              </li>
              <li className="inline">
                <Link
                  to="mailto:ponyjacks"
                  className="btn btn-icon btn-sm border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                >
                  <i
                    className="uil uil-envelope align-middle"
                    title="email"
                  ></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

import React from "react";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../components/attributeDisplay";

const elementData = [
  {
    title: "CLASSIC",
    iconClass: "mdi mdi-counter ",
    description: "Easy-tempered, balanced, and neutral",
    example: "Samuraijack",
  },
  {
    title: "LIFE",
    iconClass: "mdi mdi-leaf",
    description: "Vivacious, adaptable, and wild with high health",
    example: "Cheetahjack",
  },
  {
    title: "EARTH",
    iconClass: "mdi mdi-earth",
    description: "Hearty, strong, and tenacious with high defense",
    example: "Marblejack",
  },
  {
    title: "AQUA",
    iconClass: "mdi mdi-water",
    description: "Fast, emotional, and relentless with high speed",
    example: "Icejack",
  },
  {
    title: "FIRE",
    iconClass: "mdi mdi-fire",
    description: "Aggressive, powerful, and fierce with high attack",
    example: "Lavajack",
  },
  {
    title: "SKY",
    iconClass: "mdi mdi-cloud",
    description: "Smart, calm, and creative with high intelligence",
    example: "Cloudjack",
  },
  {
    title: "DARK",
    iconClass: "mdi mdi-moon-waning-crescent",
    description: "Mischievous, tricky, and mysterious with high evasion",
    example: "Shadowjack",
  },
  {
    title: "MYTHIC",
    iconClass: "mdi mdi-sword-cross text-white rounded-full",
    description: "Mythical, heroic, and magical with high special",
    example: "Dragonjack",
  },
];

export function ElementFeature() {
  return (
    <div className="container md:mt-24 mt-16">
      <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold text-center">
        Elements
      </h3>

      <p className="text-slate-800 max-w-1/2 mx-auto text-lg font-semibold text-center dark:text-slate-300">
        8 Kingdoms, 1 Ponyverse - Each Ponyjack is born with 2 Element Types.
        These Elements represent the Forces of Nature within the Ponyverse and
        shape the bodies, souls, and destinies of each Ponyjack uniquely,
        helping to define their traits, Jacktype, artwork, and stats. Beyond
        basic rarity, the elements have rarity too.
      </p>

      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">
        {elementData.map((item, index) => (
          <div
            key={index}
            className="group relative px-2 py-2 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center bg-violet-300 dark:bg-violet-700"
          >
            <div
              className="bg-white dark:bg-slate-900 rounded-lg p-2 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 hover:scale-105 ease-in-out duration-500
            flex flex-col justify-center items-center space-y-3"
            >
              <AttributeIcon
                trait={"Element"}
                value={
                  item.title.toString().slice(0, 1) +
                  item.title.toString().slice(1).toLowerCase()
                }
              />
              <h4 className="text-lg h5 font-semibold text-gray-800 dark:text-white text-center ">
                {item.title}
              </h4>
              <p className="text-gray-600 dark:text-gray-300 transition duration-500 ease-in-out mt-2 text-center">
                {item.description}
              </p>
              {/* <p className="text-gray-600 dark:text-gray-300 transition duration-500 ease-in-out mt-2 text-left">
                {item.example}
              </p> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const rarityData = [
  {
    title: "Common",
    percentage: "50%",
    logoUrl: require("../assets/images/Ponyjacks/Common.png"),
  },
  {
    title: "Rare",
    percentage: "28%",
    logoUrl: require("../assets/images/Ponyjacks/Rare.png"),
  },
  {
    title: "Super Rare",
    percentage: "14%",
    logoUrl: require("../assets/images/Ponyjacks/Super Rare.png"),
  },
  {
    title: "Legendary",
    percentage: "7%",
    logoUrl: require("../assets/images/Ponyjacks/Legendary.png"),
  },
  {
    title: "Unique (1 of 1)",
    percentage: "1%",
    logoUrl: require("../assets/images/Ponyjacks/Unique.png"),
  },
];

export function RarityFeature() {
  return (
    <div className="container md:mt-24 mt-16">
      <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold text-center">
        Rarity
      </h3>

      <p className="text-slate-800 max-w-1/2 mx-auto text-lg font-semibold text-center dark:text-slate-200">
        Each Ponyjack is a custom-designed artwork varying in elite rarity based
        on its traits, elements, Gen, and Jacktype. Even our "commons" have only
        21 mints each. And while Legs are 7%, there's only 3 mints of each
        Ponyjack. There are 8 Unique 1 of 1 Ponyjacks, extremely rare and
        extremely powerful. There are only 1000 Gen 0 Ponyjacks, the first and
        flagship edition! For Early Access, we'll only be minting up to 300 or
        so, and your Ponyjack automatically gets you in to custom tourney events
        for prizes!
      </p>

      <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 mt-10 gap-4">
        {rarityData.map((item, index) => (
          <div
            key={index}
            className="group relative p-2 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center bg-violet-200 dark:bg-violet-900"
          >
            <div className=" bg-white dark:bg-slate-900 rounded-lg p-2 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-800 hover:scale-105 ease-in-out duration-500">
              <img
                src={item.logoUrl}
                alt={item.title}
                className="h-20 w-20 mx-auto mb-2"
              />
              <h4 className="text-lg h5 font-semibold text-gray-800 dark:text-white">
                {item.title}
              </h4>
              <p className="text-gray-600 dark:text-gray-300 transition duration-500 ease-in-out mt-2 text-center">
                {item.percentage}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

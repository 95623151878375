import React, {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useAccount, useConnect, useSignMessage, useDisconnect } from "wagmi";
import axios from "axios";
import { MetaMaskConnector } from "@wagmi/core/connectors/metaMask";
import tokenAbi from "./tokenAbi.json";
import Web3 from "web3"; // Import Web3 library

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    owner_name: "",
    nfts: [],
  });
  const [allUsers, setAllUsers] = useState(null);
  const [account, setAccount] = useState(null);
  const [balance, setBalance] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [allNFTs, setAllNFTs] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [adminConfigs, setAdminConfigs] = useState(null);
  const tokenContractAddress = "0x66E5CfA3487ea1DBd08Ec85955070Ea60eff16EF";
  const web3 = new Web3("https://80002.rpc.thirdweb.com");
  const tokenContract = new web3.eth.Contract(tokenAbi, tokenContractAddress);

  //const apiUrl = "http://localhost:3306/api/";
  const apiUrl = "https://backend.ponyjacks.app/api/"; // Update the endpoint
  const api = axios.create({
    baseURL: apiUrl, // Your API base URL
    withCredentials: true, // Enable sending and receiving cookies
    headers: {
      "Content-Type": "application/json",
    },
  });

  const { connectAsync } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { isConnected, address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const [accountConnected, setAccountConnected] = useState(false);
  const [selectedBattle, setSelectedBattle] = useState(null);
  const connector = new MetaMaskConnector({
    options: {
      shimDisconnect: false,
    },
  });

  useEffect(() => {
    if (user?.owner_name === "") {
      checkForAutoLogin();
    }
  }, [user?.owner_name]);

  const setAdmin = async (adminConfig) => {
    try {
      const url = apiUrl + "users/admin";
      console.log(adminConfig);
      const result = await api.post(url, { adminConfig });
      console.log(result);
      if (result.status === 200) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const getAdminConfigs = async () => {
    try {
      const url = apiUrl + "users/adminConfigs";
      const result = await api.get(url);
      if (result.status === 200) {
        setAdminConfigs(result.data);
      }
      return result.data;
    } catch (error) {
      return null;
    }
  };
  const testwb = async () => {
    try {
      const url = "http://localhost:3300/nfts/webhook";
      const data = {
        event: {
          activity: [
            {
              fromAddress: "0xD837A2D83a12ab362D439FF7a87f88B7EA14f092",
              toAddress: "0x7fdbaa06860ba67d6c9d61486af8afcf8bf68779",
              contractAddress: "0x495f947276749ce646f68ac8c248420045cb7b5e",
              erc1155Metadata: [
                {
                  tokenId: 107,
                },
              ],
            },
          ],
        },
      };
      const result = await axios.post(url, data);
      console.log(result);
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  };

  const checkForAutoLogin = useCallback(async () => {
    try {
      const { ethereum } = window;

      if (ethereum && user?.owner_name === "") {
        const accounts = await ethereum.request({ method: "eth_accounts" });
        if (accounts.length > 0) {
          const formattedAddress = accounts[0];
          initialLoad(formattedAddress);
          setAccount(formattedAddress);
          setAccountConnected(true);
          return true;
        } else {
          const { account } = await connectAsync({
            connector: connector,
          });
          setAccount(account);
          setAccountConnected(true);
          initialLoad(account);
        }
      }
      return false;
    } catch (error) {
      console.error("Error fetching user details:", error);
      return false;
    }
  }, []);

  const connectMetaMask = async (forced) => {
    try {
      //disconnects the web3 provider if it's already active
      checkForAutoLogin();

      if (isConnected) {
        setAccountConnected(true);
        console.log("addy", address);
        setAccount(address);

        const userDetails = await fetchUserDetailsFromBackend(address);
        setUser(userDetails);
      } else {
        console.log("addyx", address);
        if (isConnected && forced) {
          await disconnectAsync({
            connector: connector,
          });
        }
        // enabling the web3 provider metamask
        const { account } = await connectAsync({
          connector: connector,
        });
        console.log(account);
        setAccount(account);
        setAccountConnected(true);
        const userDetails = await fetchUserDetailsFromBackend(account);
        if (userDetails.owner_name === "") {
          const userData = { address: account, chain: 1 };
          // making a post request to our 'request-message' endpoint

          const { data } = await api.post(`users/request-message`, userData);
          // Process the response data as needed
          console.log("Response data:", data);

          const message = data.message;
          // signing the received message via metamask
          const signature = await signMessageAsync({ message });

          const verifyResult = await api.post(`${apiUrl}users/verify`, {
            message,
            signature,
          });

          const authenticateResult = await api.post(`users/authenticate`);
          console.log("authenticated", authenticateResult);
          const addressVerified = authenticateResult?.data.address;
          console.log(addressVerified);
        }
        setUser(userDetails);
      }
    } catch (error) {
      console.error("Error logging in: ", error);
    }
    // redirect to /user
  };

  const getEthBalance = async (account) => {
    try {
      // Retrieve balance in wei
      console.log(account);
      const balanceMethod = tokenContract.methods.balanceOf(account);
      const Balance = await balanceMethod.call();
      // Convert wei balance to ETH
      console.log(Balance);
      var ethBalance = web3.utils.fromWei(Balance, "ether");
      console.log(ethBalance);
      ethBalance = parseFloat(ethBalance).toFixed(4);
      return addCommas(ethBalance);
    } catch (error) {
      console.error("Error fetching balance: ", error);
      return null;
    }
  };

  function addCommas(number) {
    //dont add commas after the decimal point
    var nStr = number + "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  const logout = async () => {
    try {
      await api.post(`${apiUrl}users/logout`, {
        withCredentials: true,
      });
      setUser({
        owner_name: "",
        nfts: [],
      });
      setAccountConnected(false);
      setAccount(null);
    } catch (error) {
      console.error("Error logging out: ", error);
      return null;
    }
  };

  const initialLoad = async (address) => {
    try {
      const userDetails = await fetchUserDetailsFromBackend(address);
      setUser(userDetails);
      getAdminConfigs();
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  };

  const fetchUserDetailsFromBackend = async (address) => {
    try {
      const url = apiUrl + `users`;
      var userAddress = address.toString().toLowerCase();

      const response = await api.get(url);
      const balancex = await getEthBalance(address);
      console.log("blc", balancex);
      if (balancex !== null) {
        setBalance(balancex);
      }
      if (response.status === 200) {
        var allUserData = await response.data;
        setAllUsers(allUserData);

        var user = allUserData.filter(
          (userx) => userx?.owner_wallet_id.toLowerCase() === userAddress
        )?.[0] || { owner_name: "", nfts: [] };
        console.log("userFound", user);
        var nfts = await fetchNFTsFromDB(userAddress);
        setAllNFTs(nfts);
        var userNFTs = nfts?.filter(
          (nft) => nft?.owner_wallet_id.toLowerCase() === userAddress
        );
        user.nfts = userNFTs;
        console.log(user);
        return user;
      } else {
        // Handle the case where the user is not found
        var address = "0x726F21CDD2Aa618e2a28E6A15BE2A82111a0EcC6";
        var nfts = await fetchNFTsFromDB(address);
        var userNFTs = nfts.filter(
          (nft) =>
            nft?.owner_wallet_id?.toString() ===
            "0x726F21CDD2Aa618e2a28E6A15BE2A82111a0EcC6"
        );

        return { owner_name: "", nfts: userNFTs };
      }
    } catch (error) {
      console.error("Error fetching user details:", error);
      return null;
    }
  };

  async function updateUserDetails(walletAddress, updatedDetails) {
    try {
      const token = account; // Replace with received token
      const encodedWalletAddress = encodeURIComponent(walletAddress);
      const url = apiUrl + `users/update/${encodedWalletAddress}`;

      console.log(updatedDetails);
      const response = await api.put(url, updatedDetails);
      console.log(response);
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  }

  const createUser = async (userData) => {
    try {
      const token = account; // Replace with received token
      const url = apiUrl + "users/"; // Update the endpoint

      const response = await api.post(url, userData);

      console.log(userData);
      if (response.ok) {
        // Successfully created user
        console.log(response);
        // Refresh user data or handle accordingly
      } else {
        console.error("Error creating user:", response.statusText);
      }
    } catch (error) {
      console.error("Error creating user:", error);
    }
  };

  const formatTitle = (title) => {
    let formattedTitle = title
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
    return formattedTitle;
  };

  const fetchNFTsFromDB = async (address) => {
    try {
      var address = `0x726F21CDD2Aa618e2a28E6A15BE2A82111a0EcC6`;
      const url = apiUrl + `users/nfts/${address}`;
      const response = await api.get(url);
      const data = await response.data;
      console.log(data);
      var nfts = [];
      console.log(data);
      for (var i = 0; i < data.length; i++) {
        var nft = data[i];
        var kingdom1 = nft.arena_type.toLowerCase();
        var kingdom2 = nft.arena_sub_type.toLowerCase();

        //get kingdom 1 value
        var kingdom1Value = nft[kingdom1];
        //get kingdom 2 value
        var kingdom2Value = nft[kingdom2];

        var baseLife = nft.life;

        var allAttributes = [
          {
            trait_type: "Rarity",
            value: nft.rarity,
          },
          {
            trait_type: "Kingdom 1",
            value: nft.arena_type,
            stat: kingdom1Value,
          },
          {
            trait_type: "Kingdom 2",
            value: nft.arena_sub_type,
            stat: kingdom2Value,
          },
        ];

        // if (kingdom1 !== "life" && kingdom2 !== "life") {
        //   allAttributes.push({
        //     trait_type: "Base Life",
        //     value: "Life",
        //     stat: baseLife,
        //   });
        // }

        nfts.push({
          tokenId: nft.nft_id,
          image: nft.image,
          title: nft.pony_name,
          subtext: nft.description,
          items: 25,
          avatar: nft.image,
          gen: nft.gen,
          breed: nft.breed,
          owner_wallet_id: nft.owner_wallet_id,
          attributes: allAttributes,
          traits: [
            {
              trait_type: "life",
              value: nft.life,
            },
            {
              trait_type: "aqua",
              value: nft.aqua,
            },
            {
              trait_type: "fire",
              value: nft.fire,
            },
            {
              trait_type: "earth",
              value: nft.earth,
            },
            {
              trait_type: "sky",
              value: nft.sky,
            },
            {
              trait_type: "dark",
              value: nft.dark,
            },
            {
              trait_type: "mythic",
              value: nft.mythic,
            },
            {
              trait_type: "classic",
              value: nft.classic,
            },
          ],
          rarity: nft.rarity,
          category: nft.category || "Ponyjacks",
          arena_type: nft.arena_type,
          sub_arena_type: nft.arena_sub_type,
        });
      }
      console.log(nfts);
      return nfts; // Returning the array of NFT assets
    } catch (error) {
      console.error("Error fetching NFTs:", error);
      return [];
    }
  };

  const disconnect = useCallback(() => {
    // Reset user-related state
    setUser(null);
    setAccount(null);
    setBalance(null);

    // Optionally, if you're storing the user's address in local storage or cookies, remove it
    // localStorage.removeItem('userAddress');

    // Inform user they are "disconnected" in your app's context (maybe using a toast, modal, etc.)
    console.log("Disconnected from app");
  }, []);

  const selectItemDetails = (item) => {
    setSelectedItem(item);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        allNFTs,
        allUsers,
        selectedBattle,
        account,
        balance,
        showModal,
        selectedItem,
        adminConfigs,
        setUser,
        selectItemDetails,
        connectMetaMask,
        setShowModal,
        updateUserDetails,
        createUser,
        disconnect,
        checkForAutoLogin,
        setSelectedBattle,
        setAdmin,
        getAdminConfigs,
        testwb,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React, { useState, useEffect, useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useSpring, animated } from "react-spring";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../../../components/attributeDisplay";
import ExampleJson from "./Examplejson.json";
import ShowDice from "./DiceRoller";
import AttackAnimator from "./AttackAnimator";
import { AttackFinder } from "./AttackFinder";
import { BonusFinder } from "./BonusFinder";
import DamagePopup from "./DamagePopup";
import BonusPopup from "./BonusPopup";
import BonusAnimator from "./BonusAnimator";

export default function ArenaScene(props) {
  var { battleData } = props;
  console.log(battleData);
  const [roundIndex, setRoundIndex] = useState(0);
  const rounds =
    battleData?.rounds?.sort((a, b) => a.sort_id - b.sort_id) || [];
  const currentRound = rounds[roundIndex];
  console.log(currentRound);
  const [currentPage, setCurrentPage] = useState(0);
  const roundsPerPage = 6;

  const startIndex = currentPage * roundsPerPage;
  const endIndex = startIndex + roundsPerPage;
  const displayedRounds = rounds.slice(startIndex, endIndex);

  const [autoPlay, setAutoPlay] = useState(true);
  const [diceAnimation, setDiceAnimation] = useState(false);
  const [delay, setDelay] = useState(20000);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (roundIndex < rounds.length - 1 && autoPlay) {
        setRoundIndex(roundIndex + 1);
        if (roundIndex >= currentPage * roundsPerPage + roundsPerPage - 1) {
          setCurrentPage(currentPage + 1);
        }
      }
    }, delay); // Change the delay as needed (in milliseconds)

    // Clean up the timeout when the component unmounts or roundIndex changes
    return () => clearTimeout(timer);
  }, [roundIndex]);

  const player1 = {
    name:
      battleData?.battleHistory?.filter((item) => item.team_id === 1)?.[0]
        ?.userInfo?.owner_name || "John",
    avatar: battleData?.player1?.avatar,
    ponyjacks:
      battleData?.battleHistory
        ?.filter((item) => item.team_id === 1)
        .map((item) => item.nftData) || [],
  };

  const player2 = {
    name:
      battleData?.battleHistory?.filter((item) => item.team_id === 2)?.[0]
        ?.userInfo?.owner_name || "Jane",
    avatar: battleData?.battleHistory?.[1]?.userInfo?.profile_image,
    ponyjacks:
      battleData?.battleHistory
        ?.filter((item) => item.team_id === 2)
        .map((item) => item.nftData) || [],
  };

  const getPonyStats = (activeArray, deadArray, ponyData, damageReceived) => {
    const ponyFoundInActive = activeArray?.find(
      (item) => item?.nft_id.toString() === ponyData?.tokenId.toString()
    ) || { current_life: 0 };
    const ponyFoundInDead = deadArray?.find(
      (item) => item?.nft_id.toString() === ponyData?.tokenId
    ) || { current_life: 0 };
    const current_stats = ponyFoundInActive?.stats || ponyFoundInDead?.stats;
    const max_life = current_stats?.split("(life):")[1]?.split(" (")[0] || 0;
    var current_life = current_stats?.split("[Life:")[1]?.split("]")[0] || 0;
    if (damageReceived > 0) current_life -= damageReceived;
    if (current_life < 0) current_life = 0;

    return {
      current_life: current_life,
      max_life: max_life,
      active: ponyFoundInActive?.current_life ? true : false,
      dead: ponyFoundInDead?.current_life ? true : false,
    };
  };
  const [showDefenderRolls, setShowDefenderRolls] = useState(false);
  var animationArray = [];

  const BattleNFT = ({ data, teamID }) => {
    const nftRefs = useRef([]);
    const JsonData = currentRound && JSON.parse(currentRound?.json_data);
    console.log(JsonData);
    const atk_current_life = JsonData?.basic?.atk_current_life;
    const def_current_life = JsonData?.basic?.def_current_life;
    const atk_damageArr = JsonData?.atk_damageArr;
    const def_damageArr = JsonData?.def_damageArr;
    const attackingTeamID = parseInt(JsonData?.basic?.attackingTeamID);
    const damage_received =
      teamID === attackingTeamID ? atk_damageArr : def_damageArr;
    console.log(damage_received);
    const damageArr =
      JsonData.def_damageArr?.[0]?.team_id === teamID
        ? JsonData?.def_damageArr
        : JsonData?.atk_damageArr;
    const defenderNft = damageArr;
    const current_life =
      teamID === attackingTeamID ? atk_current_life : def_current_life;
    const atk_team_roster = JsonData?.atk_team_roster;
    const def_team_roster = JsonData?.def_team_roster;
    const defendingTeamID = JsonData?.defendingTeamID;
    const team_roster =
      teamID === attackingTeamID ? atk_team_roster : def_team_roster;
    const active_team_pony = team_roster?.[0]?.active;
    const dead_team_pony = team_roster?.[0].dead;
    const jobArray = JsonData?.jobArr;
    const isSpeedRoll = jobArray?.filter((item) => item === "speed").length > 0;
    const rollResultsArray = JsonData?.rollResults || [];
    const attackerNFTID = JsonData?.atk_who_nftID || [];
    const [damage, setDamage] = useState(0);

    const getAllRolls = (rollResultsArray) => {
      const allRolls = [];

      rollResultsArray.forEach((obj) => {
        const key = Object.keys(obj)[0]; // Extract the key (like 'attack', 'dodge', ...)
        const { team_id, result } = obj[key]; // Extract 'team_id' and 'result' from the inner object
        if (team_id === teamID)
          allRolls.push({ diceRolls: result, team_id, type: key });
      });

      return allRolls;
    };

    const allRolls = getAllRolls(rollResultsArray);
    const attackerRolls = allRolls.filter(
      (roll) => roll.team_id === attackingTeamID
    );
    const defenderRolls = allRolls.filter(
      (roll) => roll.team_id !== attackingTeamID
    );

    //if attacking team, show the attackerNFT first (1st team right most, 2nd team left most)
    let idToCompare = teamID === attackingTeamID ? attackerNFTID : defenderNft;

    const sortedData = data?.sort(
      //sort if found in active_team_pony first
      (a, b) => {
        var aFound = active_team_pony?.find(
          (item) => item?.nft_id.toString() === a.tokenId.toString()
        );
        var bFound = active_team_pony?.find(
          (item) => item?.nft_id.toString() === b.tokenId.toString()
        );
        if (aFound && bFound) return 0;
        if (bFound) return teamID === attackingTeamID ? 1 : -1;
        if (aFound) return teamID === attackingTeamID ? -1 : 1;
        return 0;
      }
    );
    console.log("active_team_pony", active_team_pony);
    console.log("SORTED DATA", sortedData);
    var bonusAnim = null;
    var allBonus = [];

    if (currentRound && data) {
      var bonusData = BonusFinder({
        currentRound,
        teamID,
      });
      bonusAnim = bonusData;
      allBonus = bonusData?.allBonus;
    }

    const [defendComplete, setDefendComplete] = useState(false);
    const isLastRound = roundIndex === rounds.length - 1;

    return (
      <div className={`flex flex-col justify-center items-center relative `}>
        <div className={`flex  justify-center items-center relative `}>
          {isLastRound ? (
            current_life > 0 ? (
              <i
                className={`mdi mdi-trophy text-8xl  top-0 -${
                  teamID === 1 ? "right" : "left"
                }-20`}
              ></i>
            ) : (
              <i
                className={`mdi mdi-skull-outline text-8xl  top-0 -${
                  teamID === 1 ? "right" : "left"
                }-20`}
              ></i>
            )
          ) : isSpeedRoll ? (
            <i
              className={`mdi mdi-lightning-bolt text-8xl  top-0 -${
                teamID === 1 ? "right" : "left"
              }-20`}
            ></i>
          ) : teamID === attackingTeamID ? (
            <i
              className={`mdi mdi-sword text-8xl  top-0 -${
                teamID === 1 ? "right" : "left"
              }-20`}
            ></i>
          ) : (
            teamID !== attackingTeamID && (
              <i
                className={`mdi mdi-shield-outline  text-8xl  top-0 -${
                  teamID === 1 ? "right" : "left"
                }-20`}
              ></i>
            )
          )}{" "}
          <div
            className={`p-4 mb-4  rounded-lg relative $
          ${teamID !== attackingTeamID ? "bg-red-500" : "bg-green-500"}`}
          >
            <h1 className="text-xl font-semibold">
              {teamID === attackingTeamID ? "Attacker" : "Defender"}
              {" - "}
              {teamID === 1 ? player1.name : player2.name}
            </h1>
            <h2 className="text-lg font-semibold">
              Total Life: {current_life}{" "}
            </h2>
            {damage_received?.length > 0 && defendComplete && (
              <h2 className="text-lg font-semibold">
                Damage Received:{" "}
                {damage_received
                  ?.reduce((acc, item) => {
                    const found = acc.find((x) => x.nft_id === item.nft_id);
                    if (found) {
                      found.dmgTaken += item.dmgTaken;
                    } else {
                      acc.push({ ...item });
                    }
                    return acc;
                  }, [])
                  .map(
                    (item) => item?.dmgTaken + " to " + item?.nft_name + " "
                  )}
              </h2>
            )}
          </div>
        </div>
        <div className="bg-gray-100 dark:bg-slate-800 p-4 mb-4  w-fit rounded-lg">
          <h2 className="text-2xl font-semibold">
            {" "}
            {isSpeedRoll ? "Speed Roll" : ""}
          </h2>
          <div className="flex flex-row justify-center items-center">
            {attackerRolls.map((item, i) => (
              <div
                key={i}
                className="flex flex-col justify-center items-center"
              >
                <h3 className="text-lg font-semibold">{item.type}</h3>
                <ShowDice
                  diceRolls={item.diceRolls}
                  setFinalAnimation={setDefendComplete}
                  bonusColor={bonusAnim ? bonusAnim.fileName.toLowerCase() : ""}
                  type={item.type}
                />
              </div>
            ))}
            {defenderRolls.map((item, i) => (
              <div
                key={i}
                className="flex flex-col justify-center items-center"
              >
                <h3 className="text-lg font-semibold">{item.type}</h3>
                <ShowDice
                  diceRolls={item.diceRolls}
                  setFinalAnimation={setDefendComplete}
                  bonusColor={bonusAnim ? bonusAnim.fileName.toLowerCase() : ""}
                  type={item.type}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className={`grid grid-cols-${
            sortedData.length == 5
              ? 3
              : sortedData.length == 4
              ? 2
              : sortedData.length
          }  gap-4 mb-4 w-full justify-items-center items-center`}
        >
          {sortedData?.map((nft, index) =>
            !nft ? (
              <div className="text-center flex-col items-center justify-center mt-28">
                Awaiting Ponyjack
              </div>
            ) : (
              <>
                <div
                  key={index}
                  className={`group relative rounded-lg  border border-gray-100 dark:border-gray-800 hover:shadow-md  hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 
                  flex flex-col p-2 items-center justify-center w-fit 
                  ${
                    nft?.tokenId.toString() === attackerNFTID.toString()
                      ? "bg-green-900"
                      : nft?.tokenId.toString() ===
                        damage_received
                          ?.find((item) => item?.nft_id === nft.tokenId)
                          ?.nft_id.toString()
                      ? "bg-red-900"
                      : "bg-slate-200 dark:bg-slate-900"
                  }
                  `}
                  style={{
                    animation: "glow 1s infinite alternate",
                    "--glow-color": bonusAnim ? getColour(bonusAnim, nft) : "",
                    //use custom coor for glow
                  }}
                >
                  <RarityGradient
                    rarity={nft.rarity}
                    kingdom1={nft.arena_type}
                    kingdom2={nft.sub_arena_type}
                  />
                  {damage_received.length > 0 &&
                    damage_received?.find(
                      (item) => item?.nft_id === nft.tokenId
                    ) &&
                    defendComplete && (
                      <DamagePopup
                        damage={damage_received
                          ?.filter((item) => item?.nft_id === nft.tokenId)
                          ?.reduce((a, b) => a + b?.dmgTaken, 0)}
                      />
                    )}{" "}
                  <img
                    src={nft?.image}
                    alt="Image error"
                    ref={(ref) => (nftRefs.current[index] = ref)}
                    className={`rounded-lg shadow-md dark:shadow-gray-700 transition-all duration-500 object-cover object-center max-w-40 max-h-40`}
                    id={
                      nft?.tokenId.toString() === attackerNFTID.toString()
                        ? "attackingNFT"
                        : nft?.tokenId.toString() === defenderNft.toString()
                        ? "defendingNFT"
                        : ""
                    }
                  />
                  <h4 className="text-md font-semibold mt-2">
                    {nft?.title}
                    <small>
                      {" #"}
                      {nft?.tokenId}
                    </small>{" "}
                  </h4>{" "}
                  <AttributeDisplay attributes={nft.attributes} iconSize={8} />
                  <HealthBar
                    current_life={
                      getPonyStats(
                        active_team_pony,
                        dead_team_pony,
                        nft,
                        defendComplete &&
                          defenderNft?.find(
                            (item) => item?.nft_id.toString() === nft.tokenId
                          )?.dmgTaken
                          ? damage_received
                              ?.filter(
                                (item) =>
                                  item?.nft_id.toString() ===
                                  nft.tokenId.toString()
                              )
                              ?.reduce((a, b) => a + b?.dmgTaken, 0)
                          : 0 || 0
                      ).current_life
                    }
                    max_life={
                      getPonyStats(active_team_pony, dead_team_pony, nft)
                        .max_life
                    }
                  />
                  <h4 className="text-md font-semibold mt-2">
                    {teamID === 1 ? player1.name : player2.name}
                  </h4>{" "}
                </div>
                {bonusAnim && (
                  <>
                    {" "}
                    {allBonus.map((bonus, i) => (
                      <BonusPopup
                        value={bonus.value}
                        name={bonus.name}
                        index={i}
                        bonusColor={
                          bonusAnim ? bonusAnim.fileName.toLowerCase() : ""
                        }
                      />
                    ))}
                  </>
                )}
              </>
            )
          )}
        </div>
      </div>
    );
  };

  const HealthBar = ({ current_life, max_life }) => {
    const percentage = (current_life / max_life) * 100;
    return (
      <div className="relative mt-4 w-full bg-red-500 rounded-lg">
        <div
          className={`shadow-none flex flex-col text-center text-lg rounded-lg whitespace-nowrap text-white justify-center 
        transition-all duration-500`}
        >
          <div
            style={{ width: `${percentage}%` }}
            className={`bg-green-500 h-full absolute top-0 left-0 rounded-lg z-0`}
          />
          <h4 className="z-10 relative">
            Life: {current_life}/{max_life}
          </h4>
        </div>
      </div>
    );
  };

  const setCurrentRound = (index) => {
    setRoundIndex(index);
  };

  const getArenaName = (name) => {
    //remove spaces from name
    name = name?.replace(/\s/g, "");
    if (name === "Waterfall") name = "Aqua";
    return (name ? name : "Cloud") + "Arena";
  };

  //get x, y position for middle of screen
  const getCenterPosition = () => {
    const x = window.innerWidth / 4;
    const y = window.innerHeight / 4;
    return { x: x, y: y };
  };

  const attackingNFTBoundingBox = document
    .getElementById("attackingNFT")
    ?.getBoundingClientRect();
  const defendingNFTBoundingBox = document
    .getElementById("defendingNFT")
    ?.getBoundingClientRect();

  const getAllMsg = (data) => {
    const allMsg = [];
    const msgToColor = require("./msgToColor.json");

    data.forEach((obj) => {
      const key = Object.keys(obj)[0]; // Extract the key (like 'attack', 'dodge', ...)
      const { msg, team_id } = obj[key]; // Extract 'team_id' and 'result' from the inner object
      //find color
      const color =
        msgToColor.filter((item) => item.type === key)[0]?.color || "red-500";
      allMsg.push({ msg: msg, team_id, type: key, color: color });
    });

    return allMsg;
  };

  const getColour = (bonus, nft) => {
    var arena_type = nft.arena_type;
    var arena_sub_type = nft.sub_arena_type;
    var arena_name = battleData?.battleHistory?.[0]?.arena_name;
    var highestBonus = null;
    bonus.allBonus.forEach((bonus) => {
      if (
        (bonus.name === nft.arena_type ||
          bonus.name === nft.sub_arena_type ||
          (bonus.name === "Arena" &&
            (arena_name.includes(nft.arena_type) ||
              arena_name.includes(nft.sub_arena_type)))) &&
        (highestBonus === null || bonus.value > highestBonus.value)
      ) {
        highestBonus = bonus;
      }
    });
    if (highestBonus === null) return null;

    var name = highestBonus.fileName.toLowerCase();
    switch (name) {
      case "slate":
        return "#d9e2ec"; // Subtle blue
      case "red":
        return "#ff6666"; // Subtle red
      case "green":
        return "#66cc66"; // Subtle green
      case "blue":
        return "#6699cc"; // Subtle blue
      case "yellow":
        return "#ffff99"; // Subtle yellow
      case "purple":
        return "#cc99ff"; // Subtle purple
      case "orange":
        return "#ffcc99"; // Subtle orange
      case "brown":
        return "#cc9966"; // Subtle brown
      default:
        return "#ff6666"; // Default to subtle red
    }
  };
  const maxSpeed = 20000;
  const winnerNFTComponent = (nft) => {
    return (
      <div
        className={`group relative rounded-lg  border border-gray-100 dark:border-gray-800 hover:shadow-md  hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 
                  flex flex-col p-2 items-center justify-center w-fit  relative  bg-slate-200 dark:bg-slate-900 z-10
                  `}
      >
        <img
          src={nft?.image}
          alt="Image error"
          className={`rounded-lg shadow-md dark:shadow-gray-700 transition-all duration-500 object-cover object-center max-h-40 max-w-40`}
        />
        <h4 className="text-md font-semibold mt-2">
          {nft?.title}
          <small>
            {" #"}
            {nft?.tokenId}
          </small>{" "}
        </h4>{" "}
        <AttributeDisplay attributes={nft.attributes} iconSize={8} />
      </div>
    );
  };

  const WinnerModal = () => {
    console.log("XJSHD", battleData);

    return (
      <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex z-10 justify-center items-center">
        <div className="bg-gray-100 dark:bg-slate-800 p-4 rounded-lg justify-center items-center flex flex-col">
          <h1 className="text-4xl font-semibold">Winner</h1>
          {battleData?.winner && (
            <h2 className="text-4xl font-semibold">
              {battleData?.winner?.toLowerCase() ===
              player1?.name?.toLowerCase()
                ? player1.name
                : player2.name}
            </h2>
          )}{" "}
          <div className="flex gap-4 justify-center z-10 items-center ">
            {battleData?.winner?.toLowerCase() === player1?.name?.toLowerCase()
              ? player1.ponyjacks.map((nft) => winnerNFTComponent(nft))
              : player2.ponyjacks.map((nft) => winnerNFTComponent(nft))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className=" mx-auto overflow-hidden justify-center items-center flex flex-col">
      <div className="text-4xl absolute top-0 bg-gray-100 dark:bg-slate-800 p-4 mt-[10vh] rounded-lg w-fit text-center">
        Round {roundIndex + 1}
      </div>{" "}
      <img
        src={require(`../../../assets/images/Ponyjacks/Arenas/${getArenaName(
          battleData?.battleHistory?.[0]?.arena_name
        )}.png`)}
        alt="Arena"
        className="rounded-lg fixed top-0 left-0 w-full h-full z-[-2]z-1
        object-cover object-center  filter blur-md"
      />{" "}
      {roundIndex === rounds.length - 1 && <WinnerModal />}
      <div className="relative w-full py-2 h-full text-center flex justify-center items-center">
        <div className="py-2 container">
          <BattleNFT data={player1.ponyjacks} teamID={1} />
        </div>
        {/* Round info */}
        <div className=" w-full h-full flex justify-center items-center">
          <AttackAnimator
            startPosition={attackingNFTBoundingBox || getCenterPosition()}
            endPosition={defendingNFTBoundingBox || getCenterPosition()}
            attackBool={false}
            gifFile={AttackFinder({
              currentRound,
              teamID: 1,
              data: BonusFinder({
                currentRound,
                teamID: 2,
              }),
            })?.concat(
              AttackFinder({
                currentRound,
                teamID: 2,
                data: BonusFinder({
                  currentRound,
                  teamID: 1,
                }),
              })
            )}
          />
        </div>
        <div className="py-4 container">
          <BattleNFT data={player2.ponyjacks} teamID={2} />
        </div>
      </div>
      <div className="py-8 container z-10 flex justify-center items-center flex-col w-full">
        <div
          className="text-sm font-semibold bg-gray-100 dark:bg-slate-800 p-4 rounded-lg w-fit flex
        justify-center items-center gap-2
        text-center"
        >
          <span>Speed</span> <span>{(maxSpeed / delay).toFixed(1)}</span>
          <input
            type="range"
            min={maxSpeed / 2}
            max={maxSpeed}
            value={maxSpeed - delay}
            onChange={(e) => setDelay(maxSpeed - e.target.value)}
            className="slider"
          />
        </div>
        <button
          onClick={() => setAutoPlay(!autoPlay)}
          className={`btn btn-primary m-2 bg-${
            autoPlay ? "green-600" : "red-500"
          }`}
        >
          AutoPlay
          <i className={`mdi mdi-${autoPlay ? "pause" : "play"}`}></i>
        </button>{" "}
        <div className="grid lg:grid-cols-6 md:grid-cols-3 sm:grid-cols-2 gap-2">
          {displayedRounds.map((item, i) => (
            <div key={i} className="text-center">
              <button
                className={`btn btn-primary m-2
            ${startIndex + i === roundIndex ? "bg-green-500" : "bg-gray-500"}`}
                onClick={() => setCurrentRound(startIndex + i)}
              >
                Round {startIndex + i + 1}
              </button>
            </div>
          ))}
        </div>
        <div className=" text-center">
          <button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 0}
            className="btn btn-primary m-2 bg-green-500"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={endIndex > rounds.length - 1}
            className="btn btn-primary m-2 bg-green-500"
          >
            Next
          </button>
        </div>
        <h2 className="text-xl text-white font-semibold bg-gray-500 dark:bg-slate-800 p-4 rounded-lg w-fit text-center">
          Page {currentPage + 1} / {Math.ceil(rounds.length / roundsPerPage)}
        </h2>
      </div>
      <div className="py-8 z-10 w-fit">
        <AudioPlayer
          autoPlay={localStorage.getItem("audio") === "true"} // Set to true to play audio in a
          loop
          src="/battle.mp3"
          customAdditionalControls={[]}
          layout="horizontal"
          className="my-audio-player rounded-full bg-gray-100 dark:bg-slate-800"
          onPause={(e) => localStorage.setItem("audio", !e.returnValue)}
          onPlay={(e) => localStorage.setItem("audio", e.returnValue)}
        />
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import { UserContext } from "../../store/UserContext";
import { Link } from "react-router-dom";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import { BattleContext } from "../../store/BattleContext";

export default function BattleWinnings() {
  const [page, setPage] = useState(1);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedBattle, setSelectedBattle] = useState(null);

  const {
    user,
    connectMetaMask, // Import other necessary context values here
  } = useContext(UserContext);
  const { battleHistory } = useContext(BattleContext);
  const FilteredData = () => {
    var finalData = battleHistory || [];
    console.log("final data", finalData);
    //date is in format = 2023-10-20T11:45:42.000Z
    //sorting by date
    finalData.sort((a, b) => {
      return new Date(b.modified) - new Date(a.modified);
    });

    //sort by pending bool status
    finalData.sort((a, b) => {
      return a.pending && !b.pending ? -1 : !a.pending && b.pending ? 1 : 0;
    });
    console.log("final data with pending", finalData);
    var finalTransactionData = finalData.map((item) => {
      var battleHistory = item?.battleHistory;
      var otherPlayer = "";
      if (battleHistory) {
        for (let i = 0; i < battleHistory.length; i++) {
          var userInfo = battleHistory[i].userInfo;
          if (userInfo.owner_name !== item.winner) {
            otherPlayer = userInfo.owner_name;
            break;
          }
        }
      }
      if (
        item.winner !== user?.owner_name &&
        otherPlayer !== user?.owner_name
      ) {
        return null;
      }

      var data = {
        id: item.battle_id,
        sender: item.winner,
        receiver: otherPlayer || item.winner,
        amount: item.price || 0,
        eventType: "Battle",
        date: item.modified,
        result: item.winner === user?.owner_name ? "Win" : "Loss",
        description: "Battle Winnings",
        txnHistory: item.pendingBattle?.txn_history,
      };
      return data;
    });

    return finalTransactionData.filter((item) => item !== null);
  };

  const [selectedEventType, setSelectedEventType] = useState("All");
  const [showOutgoing, setShowOutgoing] = useState(false);

  const eventImages = {
    Tournament: "/tournament-image.jpg",
    Race: "/race-image.jpg",
    // Add more event types and corresponding images
  };

  const shiftPage = (direction) => {
    if (direction === "left") {
      if (page > 1) {
        setPage(page - 1);
      }
    } else {
      if (page < Math.ceil(FilteredData().length / 9)) {
        setPage(page + 1);
      }
    }
  };

  return (
    <div className="container mx-auto px-4 min-h-screen ">
      <ConfirmationPopup
        show={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        battle={selectedBattle}
      />
      <section className="mx-auto max-w-6xl">
        <h1 className="text-3xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
          Transaction History
        </h1>
        <div className="mb-4 grid grid-cols-2 lg:grid-cols-4  gap-4">
          {/* Filter by event type */}
          <button
            className={`btn hover:bg-purple-700 text-slate-900 dark:text-white py-2 px-4 rounded-full transition duration-300 ${
              selectedEventType === "All"
                ? "bg-purple-600 dark:bg-purple-700 text-white"
                : ""
            }`}
            onClick={() => setSelectedEventType("All")}
          >
            All Events
          </button>
          <button
            className={`btn hover:bg-purple-700 text-slate-900 dark:text-white py-2 px-4 rounded-full transition duration-300 ${
              selectedEventType === "Battle"
                ? "bg-purple-600 dark:bg-purple-700 text-white"
                : ""
            }`}
            onClick={() => setSelectedEventType("Battle")}
          >
            Battle
          </button>
          <button
            className={`btn hover:bg-purple-700 text-slate-900 dark:text-white py-2 px-4 rounded-full transition duration-300 ${
              selectedEventType === "Tournament"
                ? "bg-purple-600 dark:bg-purple-700 text-white"
                : ""
            }`}
            onClick={() => setSelectedEventType("Tournament")}
          >
            Tournaments
          </button>
          <button
            className={`btn hover:bg-purple-700 text-slate-900 dark:text-white py-2 px-4 rounded-full transition duration-300 ${
              selectedEventType === "Race"
                ? "bg-purple-600 dark:bg-purple-700 text-white"
                : ""
            }`}
            onClick={() => setSelectedEventType("Race")}
          >
            Races
          </button>
          {/* Add more event type filters */}
          {/* Toggle between incoming and outgoing transactions */}
          <div className="flex items-center ms-4 text-slate-900 dark:text-slate-200">
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                className="form-checkbox text-purple-600 dark:text-purple-300"
                checked={showOutgoing}
                onChange={() => setShowOutgoing(!showOutgoing)}
              />
              <span>Show Outgoing</span>
            </label>
          </div>
        </div>
        <div className="table-responsive overflow-x-auto flex-grow items-center justify-center">
          <table className="table bg-white dark:bg-slate-900 rounded-lg shadow-md max-w-full">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Sender
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Receiver
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Amount (ETH)
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Event Type
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Date
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Result
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Description
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Transaction
                </th>
              </tr>
            </thead>
            <tbody>
              {FilteredData()
                .filter((transaction) =>
                  selectedEventType === "All"
                    ? true
                    : transaction.eventType === selectedEventType
                )
                .filter((transaction) =>
                  showOutgoing ? transaction.sender === user.owner_name : true
                )
                .slice((page - 1) * 9, page * 9)
                .map((transaction) => (
                  <tr
                    key={transaction.id}
                    className="border-b dark:border-gray-700 last:border-b-0"
                  >
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      {transaction.sender}
                    </td>
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      {transaction.receiver}
                    </td>
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      {transaction.amount}
                    </td>
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      <div className="flex items-center space-x-2">
                        {transaction.eventType}
                      </div>
                    </td>
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      {transaction.date}
                    </td>
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      {transaction.result === "Win" ? (
                        <span className="text-green-600">Win</span>
                      ) : (
                        <span className="text-red-600">Loss</span>
                      )}
                    </td>
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      {transaction.description}
                    </td>
                    <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                      {transaction.txnHistory && (
                        <a
                          href={`https://mumbai.polygonscan.com/tx/${transaction.txnHistory}`}
                          target="_blank"
                          rel="noreferrer"
                          className="text-purple-600 hover:underline"
                        >
                          {transaction.txnHistory.slice(0, 10)}...
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {FilteredData().length > 9 && (
          <div className="mt-8 text-center">
            <nav>
              <ul className="inline-flex -space-x-px">
                <li>
                  <button
                    onClick={() => shiftPage("left")}
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300"
                  >
                    <i className="mdi mdi-chevron-left text-xl"></i>
                  </button>
                </li>
                {Array.from({
                  length: Math.ceil(FilteredData().length / 9),
                }).map((item, index) => (
                  <li key={index}>
                    <button
                      className={`w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-200 bhover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300 ${
                        page === index + 1
                          ? "bg-purple-600 dark:bg-purple-700 "
                          : "bg-white dark:bg-slate-900"
                      }`}
                      onClick={() => setPage(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={() => shiftPage("right")}
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300"
                  >
                    <i className="mdi mdi-chevron-right text-xl"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </section>
    </div>
  );
}

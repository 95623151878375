import React, { useEffect, useState, useContext } from "react";
import logo_dark from "../../assets/images/logo-pony.png";
import { useNavigate } from "react-router-dom";
import Switcher from "../../components/switcher";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import metmask from "../../assets/images/metamask.svg";
import { UserContext } from "../../store/UserContext";
import AnimatedBackground from "../../components/animatedBackground";
import { useAccount, useConnect, useSignMessage, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

export default function Login() {
  const { user, account, connectMetaMask, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { disconnectAsync } = useDisconnect();

  useEffect(() => {}, [account]);
  const [userFound, setUserFound] = useState(true);

  const handleConnect = async () => {
    setUser({
      owner_name: "Connecting...",
    });
    await connectMetaMask(true);

    if (user.owner_name == "") {
      setUserFound(false);
      navigate("/profile-edit");
    } else {
      navigate("/");
    }
  };

  return (
    <>
      <section className="relative  overflow-hidden h-screen flex justify-center items-center">
        <div className="absolute top-0 start-0 w-full h-full z-0 pointer-events-none overflow-hidden">
          {" "}
          <AnimatedBackground />
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
        <div className="container">
          <div className="flex justify-center">
            <div className="max-w-[40%] w-full m-auto p-6 bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-lg">
              <div className="mb-6 text-center flex justify-center items-center">
                <img
                  src={logo_dark}
                  className="mx-auto h-14 block rounded-md"
                />
                <h1 className=" font-bold lg:leading-snug leading-snug text-4xl lg:text-6xl text-slate dark:text-white/7">
                  Pony
                  <span className="bg-gradient-to-r from-green-300 to-green-800 text-transparent bg-clip-text">
                    Jacks
                  </span>
                </h1>
              </div>
              <h5 className="my-6 text-3xl font-semibold dark:text-white text-center">
                Login{" "}
              </h5>
              <button
                className="mb-6 w-full flex justify-center items-center"
                onClick={() => handleConnect()}
              >
                <img
                  src={metmask}
                  alt=""
                  className="mx-auto h-12 block border border-gray-200 rounded-md p-2 bg-white dark:bg-slate-400 hover:bg-slate-500 dark:hover:bg-slate-500"
                />
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}

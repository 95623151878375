import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import NewEvent from "./newEvent";
import General from "./general";

export default function Admin() {
  /*	Type: 1v1, 2v2, 3v3, 4v4, 5v5, Boss Battle, Onslaught, Ponywar (12v12), Tournament
	Visibility: Open/Blind (Can or can’t see what other NFTs entered)
	Entry:	Free/Star Horseshoes/ETH (and set the cost amounts of entry)
Prizes/Rake: Star Horseshoes/ETH (sets a rate of return based on entry fees and a rake as well, example 5% rake)
	Payouts:  Winners/Survivors (winners all winners get prizes, survivors only surviving horses get prizes)
Teams:  Set/Random	(Set - Users can manually fill gates to decide teams, Random - All gates are randomized before battle to form teams)*.

*/

  const AdminTypes = ["Battles", "Races", "Tournaments", "Create new Event"];
  const [adminType, setAdminType] = useState("Battles");

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-24 bg-[url('../../assets/images/Ponyjacks/Bronzejack.png')] bg-top bg-no-repeat ">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Admin Setup
            </h3>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900"></div>
      </div>

      <section className="relative py-4 h-screen">
        <div className="container mx-auto px-4">
          {/*make a top bar with buttons to swithc between admin types*/}
          <div className="flex flex-row justify-center items-center space-x-4 text-white">
            {AdminTypes.map((type, index) => (
              <button
                key={index}
                className={`btn btn-primary mt-4 bg-slate-900 hover:bg-violet-700 transition-all 
                duration-300 rounded shadow-lg border-violet-700 hover:border-violet-800 ${
                  adminType === type ? "bg-violet-600" : ""
                }`}
                onClick={() => setAdminType(type)}
              >
                {type}
              </button>
            ))}
          </div>
          {adminType === "Create new Event" && <NewEvent />}
          {adminType === "Battles" && <General />}
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}

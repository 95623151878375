import React, { useContext, useEffect, useState } from "react";
import single from "../../assets/images/blog/single.jpg";
import image from "../../assets/images/avatar/1.png";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import Collections from "../../components/discover-items";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../../components/attributeDisplay";
import { UserContext } from "../../store/UserContext";
import FilterComponent from "../../components/filterDisplay";

export default function CreatorProfile() {
  const loadFile = (event) => {
    const image = document.getElementById(event.target.name);
    image.src = URL.createObjectURL(event.target.files[0]);
  };

  const { user, account, testwb } = useContext(UserContext);

  const [showStats, setShowStats] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [rateOption, setRateOption] = useState("All");
  const [buyOption, setBuyOption] = useState("All");
  const [option, setOption] = useState("All");
  const [toggle, setToggle] = useState(false);

  const ProfileStats = ({
    totalBattles,
    battlesWon,
    totalEarnings,
    creatorRanking,
    pledgedKingdom,
  }) => (
    <div className="container bg-purple-800/10 dark:bg-slate-900 p-2 rounded-lg shadow-lg max-w-[50%] mx-auto ">
      <div className="flex flex-wrap justify-center items-center space-x-4">
        <div className="text-center">
          <span className="text-2xl font-bold block text-green-500">
            {totalBattles}
          </span>
          <span className="text-base text-gray-600">Total Battles</span>
        </div>
        <div className="text-center">
          <span className="text-2xl font-bold block text-green-500">
            {battlesWon}
          </span>
          <span className="text-base text-gray-600">Battles Won</span>
        </div>
        <div className="text-center">
          <span className="text-2xl font-bold block text-blue-500">
            {totalEarnings} <i className="mdi mdi-ethereum"></i>
          </span>
          <span className="text-base text-gray-600">Total Earnings</span>
        </div>
        <div className="text-center">
          <span className="text-2xl font-bold block text-green-500">
            {creatorRanking}
          </span>
          <span className="text-base text-gray-600">Player Ranking</span>
        </div>
        <div className="text-center">
          <span className="text-2xl font-bold block flex items-center justify-center text-green-500">
            <AttributeIcon trait={"Element"} value={pledgedKingdom} size={8} />
            <span className="ml-2">{pledgedKingdom}</span>
          </span>
          <span className="text-base text-gray-600">Pledged Kingdom</span>
        </div>
      </div>
    </div>
  );

  const NFTComponent = () => {
    const allNFTs = user && user.nfts ? user.nfts : [];
    const filteredNFTs = allNFTs.filter((nft) => {
      return (
        nft.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        searchInput === ""
      );
    });

    const buyNFTs = filteredNFTs.filter((nft) => {
      return (
        nft.rarity?.toLowerCase().includes(buyOption.toLowerCase()) ||
        buyOption === "All"
      );
    });
    const categoryNFTs = buyNFTs.filter((nft) => {
      return (
        nft.arena_type?.toLowerCase().includes(option.toLowerCase()) ||
        option === "All"
      );
    });
    console.log("categoryNFTs", categoryNFTs);
    const sortedNFTs = categoryNFTs.sort((a, b) => {
      if (rateOption === "Kingdom1") {
        return (
          b.attributes?.filter((attr) => attr.trait_type === "Kingdom 1")?.[0]
            ?.stat -
          a.attributes?.filter((attr) => attr.trait_type === "Kingdom 1")?.[0]
            ?.stat
        );
      } else if (rateOption === "Kingdom2") {
        return (
          b.attributes?.filter((attr) => attr.trait_type === "Kingdom 2")?.[0]
            ?.stat -
          a.attributes?.filter((attr) => attr.trait_type === "Kingdom 2")?.[0]
            ?.stat
        );
      } else if (rateOption === "Highest Price") {
        return b.price - a.price;
      } else if (rateOption === "Lowest Price") {
        return a.price - b.price;
      }
    });
    const finalNFTs = sortedNFTs;

    return <>{user && user.nfts ? <Collections data={finalNFTs} /> : <></>}</>;
  };

  return (
    <>
      <Navbar />
      <section className="relative md:pb-8 pb-4 lg:mt-24 mt-4">
        <div className="lg:container container-fluid ">
          <div className="group profile-banner relative overflow-hidden text-transparent lg:rounded-xl shadow dark:shadow-gray-700">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="hidden"
              onChange={loadFile}
            />
            <div className="relative shrink-0">
              <img
                src="https://i.seadn.io/gcs/files/7d7683ba36ccdbda0efe939c52c0b32e.png?auto=format&dpr=1&w=3840"
                className="h-80 w-full object-cover"
                id="profile-banner"
                alt=""
              />
              <div className="absolute inset-0 bg-slate/10 group-hover:bg-slate-900/40 transition duration-500" />
            </div>
            <label
              className="absolute inset-0 cursor-pointer"
              htmlFor="pro-banner"
            ></label>
          </div>
        </div>
        <div className="md:flex justify-center ">
          <div className="md:w-full">
            <div className="relative -mt-[60px] text-center">
              <div className="group profile-pic w-[112px] mx-auto">
                <input
                  id="pro-img"
                  name="profile-image"
                  type="file"
                  className="hidden"
                  onChange={loadFile}
                />
                <div>
                  <div className="relative h-28 w-28 mx-auto rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                    <img
                      src={image}
                      className="rounded-full"
                      id="profile-image"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                    <label
                      className="absolute inset-0 cursor-pointer"
                      htmlFor="pro-img"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-6 container grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-3">
        <h5 className="text-3xl font-semibold text-center">
          {user?.owner_name ? user.owner_name : "Username"} &nbsp;
          <i className="mdi mdi-check-decagram text-emerald-600 align-middle text-lg"></i>
        </h5>
        <Link
          to="/profile-edit"
          className="btn rounded-full  bg-violet-600/5 hover:bg-violet-600 border-violet-600/10 hover:border-violet-600 text-violet-600 hover:text-white mx-1 "
        >
          <h5 className="text-xl font-semibold text-center">
            <i className="mdi mdi-cog "></i>
            Edit Profile
          </h5>
        </Link>
        <h5 className="text-l font-semibold text-center">
          Bio: {user?.bio ? user.bio : ""}
        </h5>{" "}
        <h5 className="text-l font-semibold text-center">
          <i className="mdi mdi-mail text-lg"></i>
          {user?.email ? user.email : "Email"}
        </h5>
        <div className="flex justify-center items-center space-x-4">
          <h5 className="text-l font-semibold text-center">
            {user?.owner_discord_handle || "Discord"}
            <img
              src="https://cdn-icons-png.flaticon.com/512/3670/3670157.png"
              alt=""
              className="w-6 h-6 inline-block m-2"
            />
            {user?.owner_twitter_handle || "Twitter"}
            <img
              src="https://seeklogo.com/images/T/twitter-x-logo-0339F999CF-seeklogo.com.png?v=638264860180000000"
              alt=""
              className="w-6 h-6 inline-block m-2"
            />
          </h5>
        </div>
        <p className="text-slate-400 text-[16px] mt-1 flex-col items-center justify-center flex text-center">
          <p className="text-slate-400 text-[16px] mt-1 flex-col items-center justify-center flex">
            Address
          </p>
          <a
            href={`https://polygonscan.com/address/${account}`}
            target="_blank"
            rel="noreferrer"
            className="text-violet-600 hover:underline text-center"
          >
            {account?.toString().slice(0, 6)}...
            {account?.toString().slice(-6)}
          </a>
        </p>
      </div>
      <div className="mt-4 text-center flex flex-col justify-center items-center space-y-2">
        <button
          onClick={() => setShowStats(!showStats)}
          className="btn btn-primary btn-sm rounded-full bg-violet-600/5 hover:bg-violet-600 border-violet-600/10 hover:border-violet-600 text-violet-600 hover:text-white mx-1"
        >
          {showStats ? (
            <i className="mdi mdi-chevron-up text-lg font-bold">Hide</i>
          ) : (
            <i className="mdi mdi-chevron-down text-lg font-bold">
              Battle Stats
            </i>
          )}
        </button>
        {showStats && user && user.nfts && (
          <ProfileStats
            totalBattles={100}
            battlesWon={60}
            battlesLost={40}
            totalEarnings={2000}
            activePonyjacks={5}
            creatorRanking={1}
            pledgedKingdom={"Fire"}
          />
        )}
      </div>
      <div className="mt-4 text-center flex justify-center items-center space-x-4">
        <FilterComponent
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setOption={setOption}
          setBuyOption={setBuyOption}
          setRateOption={setRateOption}
          toggle={toggle}
          setToggle={setToggle}
        />
      </div>
      <h3 className="text-3xl font-semibold text-center">
        {user?.nfts?.length} Ponyjacks
      </h3>
      <div className="mb-4 min-h-screen">
        <NFTComponent />
      </div>
      <Footer />
      <Switcher />
    </>
  );
}

import React, { useContext, useState } from "react";
import Navbar from "../../components/navbar";
import image from "../../assets/images/avatar/1.png";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link } from "react-router-dom";
import {
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
  Twitter,
  Discord,
} from "react-feather";
import { UserContext } from "../../store/UserContext";

import {
  showSuccessToast,
  showErrorToast,
} from "../../components/NotificationService";

export default function CreatorProfileEdit() {
  const loadFile = (event) => {
    const image = document.getElementById(event.target.name);
    image.src = URL.createObjectURL(event.target.files[0]);
  };
  const { user, account, updateUserDetails, createUser } =
    useContext(UserContext);
  const [formData, setFormData] = useState({
    owner_name: user?.owner_name,
    bio: user?.bio,
    email: user?.email,
    owner_discord_handle: user?.owner_discord_handle,
    owner_twitter_handle: user?.owner_twitter_handle,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateUserDetails(formData);
  };

  const handleCreateUser = (event) => {
    //check owner_name is not empty
    if (formData.owner_name === "") {
      alert("Please enter your name");
      return;
    }

    event.preventDefault();
    console.log(user);
    var userExists = user?.owner_wallet_id;
    var currentTime = new Date();
    currentTime = currentTime.toISOString();
    var data = {
      owner_name: formData.owner_name,
      owner_wallet_id: account.toString(),
      profile_image: "https://i.ibb.co/7YV9Y2p/1.jpg",
      background_image: "https://i.ibb.co/7YV9Y2p/1.jpg",
      email: formData.email,
      bio: formData.bio,
      owner_discord_handle: formData.owner_discord_handle,
      owner_twitter_handle: formData.owner_twitter_handle,
      creation_date: new Date().toISOString(),
    };
    if (!userExists) {
      createUser(data);
    } else {
      var data = {
        owner_wallet_id: account.toString(),
        owner_name: formData.owner_name,
        profile_image: "https://i.ibb.co/7YV9Y2p/1.jpg",
        background_image: "https://i.ibb.co/7YV9Y2p/1.jpg",
        bio: formData.bio,
        email: formData.email,
        owner_discord_handle: formData.owner_discord_handle,
        owner_twitter_handle: formData.owner_twitter_handle,
        creation_date: new Date().toISOString(),
      };
      updateUserDetails(account, data);
      showSuccessToast("Profile updated successfully");
    }
  };

  return (
    <>
      <Navbar />
      <section className="relative table w-full py-36 bg-[url('../../assets/images/bg/bg1.jpg')] bg-bottom bg-no-repeat">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Edit Profile / Settings
            </h3>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/mystable">My Stable</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              Settings
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container">
          <div className="grid md:grid-cols-12 gap-[30px]">
            <div className="lg:col-span-3 md:col-span-4">
              <div className="group profile-pic w-[112px]">
                <input
                  id="pro-img"
                  name="profile-image"
                  type="file"
                  className="hidden"
                  onChange={loadFile}
                />
                <div>
                  <div className="relative h-28 w-28 rounded-full shadow-md dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800 overflow-hidden">
                    <img
                      src={image}
                      className="rounded-full"
                      id="profile-image"
                      alt=""
                    />
                    <div className="absolute inset-0 group-hover:bg-slate-900/40 transition duration-500"></div>
                    <label
                      className="absolute inset-0 cursor-pointer"
                      htmlFor="pro-img"
                    ></label>
                  </div>
                </div>
              </div>

              <p className="text-slate-400 mt-3">
                We recommend an image of at least 400X400. GIFs work too.
              </p>
            </div>
            <div className="lg:col-span-9 md:col-span-8">
              <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                <h5 className="text-lg font-semibold mb-4">
                  Personal Detail :
                </h5>
                <form onSubmit={handleCreateUser}>
                  <div className="grid lg:grid-cols-2 grid-cols-1 gap-5">
                    <div>
                      <label className="form-label font-medium">
                        Stable Name : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                        placeholder={user?.owner_name}
                        name="owner_name"
                        value={formData.owner_name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div>
                      <label className="form-label font-medium">
                        Your Email : <span className="text-red-600">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-input w-full text-[15px] py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                        placeholder="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1">
                    <div className="mt-5">
                      <label className="form-label font-medium">Bio : </label>
                      <textarea
                        name="bio"
                        id="bio"
                        className="form-input w-full text-[15px] py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-2xl outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0 mt-2"
                        placeholder="Message :"
                        value={formData.bio}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                  </div>

                  <input
                    type="submit"
                    id="submit"
                    name="send"
                    className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full mt-5"
                    value="Save Changes"
                  />
                </form>
              </div>

              <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                <h5 className="text-lg font-semibold mb-6">
                  Social Profiles :
                </h5>

                <div className="md:flex">
                  <div className="md:w-1/3">
                    <span className="font-medium">Twitter</span>
                  </div>

                  <div className="md:w-2/3 mt-4 md:mt-0">
                    <form>
                      <div className="form-icon relative">
                        <Twitter className="w-4 h-4 absolute top-3 start-4"></Twitter>
                        <input
                          type="text"
                          className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0"
                          placeholder="Twitter Profile Name"
                          id="twitter_name"
                          name="owner_twitter_handle"
                          value={formData.owner_twitter_handle}
                          onChange={handleInputChange}
                        />
                      </div>
                    </form>

                    <p className="text-slate-400 mt-1">
                      Add your Twitter username
                    </p>
                  </div>
                </div>

                <div className="md:flex mt-8">
                  <div className="md:w-1/3">
                    <span className="font-medium">Discord</span>
                  </div>

                  <div className="md:w-2/3 mt-4 md:mt-0">
                    <form>
                      <div className="form-icon relative">
                        <img
                          //get discord icon
                          src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6a49cf127bf92de1e2_icon_clyde_blurple_RGB.png"
                          alt=""
                          className="w-4 h-4 absolute top-3 start-4"
                        ></img>
                        <input
                          type="text"
                          className="form-input w-full text-[15px] ps-12 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0"
                          placeholder="Discord Username"
                          id="discord_name"
                          name="owner_discord_handle"
                          value={formData.owner_discord_handle}
                          onChange={handleInputChange}
                        />
                      </div>
                    </form>

                    <p className="text-slate-400 mt-1">
                      Add your Discord username
                    </p>
                  </div>
                </div>
              </div>

              <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 mt-[30px]">
                <h5 className="text-lg font-semibold mb-5 text-red-600">
                  Delete Account :
                </h5>

                <p className="text-slate-400 mb-4">
                  Confirm Account Deletion. This action cannot be undone.
                </p>

                <button className="btn bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white rounded-full">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Switcher />
    </>
  );
}

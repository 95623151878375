import { Link, useNavigate } from "react-router-dom";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../../components/attributeDisplay";

export default function LobbyCard(props) {
  const { item, index, onJoinClick, onDetailsClick, onCancelClick, gameType } =
    props;
  const getGameTypeIcon = () => {
    switch (gameType) {
      case "2v2":
        return "uil uil-users-alt";
      case "3v3":
        return "uil uil-user-square";
      case "4v4":
        return "uil uil-user-circle";
      case "5v5":
        return "uil uil-tag";
      case "Paid Tourney":
        return "uil uil-trophy";
      case "Free Tourney":
        return "uil uil-gift";
      default:
        return "uil uil-user"; // Default icon
    }
  };
  const RarityMap = [
    {
      name: "Common",
      value: 1,
    },
    {
      name: "Rare",
      value: 2,
    },
    {
      name: "Super Rare",
      value: 3,
    },
    {
      name: "Legendary",
      value: 4,
    },
    {
      name: "Unique",
      value: 5,
    },
  ];

  const ElementMap = [
    {
      name: "Earth",
      value: 1,
    },
    {
      name: "Life",
      value: 2,
    },
    {
      name: "Aqua",
      value: 3,
    },
    {
      name: "Fire",
      value: 4,
    },
    {
      name: "Sky",
      value: 5,
    },
    {
      name: "Dark",
      value: 6,
    },
    {
      name: "Mythic",
      value: 7,
    },
    {
      name: "Classic",
      value: 8,
    },
  ];

  console.log("item", item);

  const getConstraintExplanation = (type, _battleConstraints) => {
    const arraymap = type === "Rarities" ? RarityMap : ElementMap;
    const constraints = _battleConstraints;

    var lowestElement = null;
    var highestElement = null;
    if (type === "Rarities") {
      lowestElement = constraints?.mixRarity?.toString()?.split("n")?.[0];
      highestElement = constraints?.maxRarity?.toString()?.split("n")?.[0];
    } else {
      lowestElement = constraints?.minKingdom1?.toString()?.split("n")?.[0];
      highestElement = constraints?.maxKingdom1?.toString()?.split("n")?.[0];
    }

    lowestElement = arraymap.filter(
      (element) => element.value === parseInt(lowestElement)
    )?.[0];
    highestElement = arraymap.filter(
      (element) => element.value === parseInt(highestElement)
    )?.[0];

    if (
      lowestElement.value === arraymap[0].value &&
      highestElement.value === arraymap[arraymap.length - 1].value
    ) {
      return "Open to all " + type;
    }
    if (lowestElement.value === highestElement.value) {
      return "Only " + lowestElement.name;
    } else {
      return "Only " + lowestElement.name + " to " + highestElement.name;
    }
  };

  const renderMainImage = (numPlayers, data) => {
    const commonImageClasses =
      "rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500 object-cover h-full w-full";

    // Calculate grid columns and max height based on the number of players
    let gridColumns = "grid-cols-1";
    let gridRows = "grid-rows-1";
    let maxImageHeight = "max-h-[200px] min-w-[200px]"; // Adjust this value as needed
    let maxContainerHeight = "max-h-[300px]"; // Adjust this value as needed

    if (numPlayers === 2) {
      gridColumns = "grid-cols-2";
      maxImageHeight = "min-h-[250px]"; // Adjust this value as needed
    } else if (numPlayers === 3) {
      gridColumns = "grid-cols-3";
      maxImageHeight = "min-h-[120px]"; // Adjust this value as needed
    } else if (numPlayers === 4) {
      gridColumns = "grid-cols-4";
      maxImageHeight = "min-h-[100px]"; // Adjust this value as needed
    } else if (numPlayers === 5) {
      gridColumns = "grid-cols-5";
      maxImageHeight = "min-h-[50px]"; // Adjust this value as needed
    }

    // Determine whether to show the main image or the grid
    const showMainImage = numPlayers === 1;
    return (
      <div
        className={`group relative rounded-lg ${maxContainerHeight} flex justify-center items-center`}
      >
        {
          <div className={`grid ${gridColumns} ${gridRows} gap-2 flex-wrap`}>
            {data.map((_, i) => (
              <div
                key={i}
                className="group relative mt-2 z-0 p-2 rounded-lg bg-slate-500 dark:bg-slate-800 bg-opacity-50 dark:bg-opacity-50 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500"
              >
                <div className="group relative flex flex-col items-center mb-2 justify-center mx-auto rounded">
                  <RarityGradient
                    rarity={data[i]?.rarity}
                    kingdom1={data[i]?.arena_type}
                    kingdom2={data[i]?.sub_arena_type}
                  />
                  <div className="relative overflow-hidden rounded-lg">
                    <img
                      src={data[i]?.image}
                      className={`${commonImageClasses} ${maxImageHeight}`}
                      alt=""
                    />{" "}
                  </div>
                </div>
                <AttributeDisplay
                  attributes={data[i]?.attributes}
                  iconSize={6}
                />
                <div className="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto z-10 text-center transition-all duration-500">
                  <div className="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white">
                    <button onClick={() => onDetailsClick(data[i])}>
                      <i className="mdi mdi-lightning-bolt"></i>
                      Details
                    </button>{" "}
                  </div>
                </div>
                <span>{/* Player Avatar or Initials */}</span>
              </div>
            ))}
          </div>
        }
      </div>
    );
  };

  return (
    <div
      key={index}
      className="group relative p-2 rounded-lg bg-slate-200 dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 h-fit"
    >
      <div className="relative overflow-hidden">
        <div className="relative overflow-hidden rounded-lg">
          {renderMainImage(item.ponyjacks.length, item.ponyjacks)}
        </div>
      </div>
      <div className="mt-3">
        <div className="flex items-center">
          <img src={item.avatar} className="rounded-full h-8 w-8" alt="" />
          <span className="text-sm dark:text-slate-400 font-semibold ms-2">
            {item.owner?.owner_name || item.owner?.owner_wallet_id || "BigBull"}
          </span>
        </div>

        <div className={`flex flex-wrap items-center m-2`}>
          {item.ponyjacks?.map((jack, index) => (
            <div
              key={index}
              className="text-s text-slate-600  font-semibold flex dark:text-slate-400"
            >
              {jack?.title}{" "}
              {index < item.ponyjacks.length - 1 && (
                <span className="mx-1">|</span>
              )}
            </div>
          ))}
        </div>
        {item.constraints && (
          <div className="flex items-center mt-4 mb-4 justify-around">
            <span className="text-s text-slate-600  font-semibold dark:text-slate-400">
              {getConstraintExplanation("Rarities", item.constraints)}
            </span>
            <span className="text-s text-slate-600  font-semibold dark:text-slate-400">
              {getConstraintExplanation("Elements", item.constraints)}
            </span>
          </div>
        )}
        <span className="text-[22px] font-semibold block mr-2 dark:text-gray text-slate-600">
          <i className={getGameTypeIcon()}></i> {gameType || " 1v1"}
        </span>
        <div className="flex justify-between p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
          <div>
            <span className="text-[16px] font-medium text-slate-400 block">
              Entry Price
            </span>
            <span className="text-[16px] font-semibold block">
              <i className="mdi mdi-ethereum"></i> {item.price || 1}
            </span>
            <span className="text-xs text-slate-400">
              {item.isActive ? "" : " (Cancelled)"}
            </span>
          </div>
        </div>
        {item.errored && (
          <button
            className="btn rounded-full ms-2 mt-2 bg-red-600 hover:bg-red-700 border-violet-600 hover:border-violet-700 text-white"
            onClick={() => onCancelClick(item)}
          >
            Retry
          </button>
        )}
        {item.isActive && (
          <div className="w-full flex justify-center">
            {onCancelClick !== null ? (
              <button
                className="btn rounded-full ms-2 mt-2 bg-red-600 hover:bg-red-700 border-violet-600 hover:border-violet-700 text-white"
                onClick={() => onCancelClick(item)}
              >
                Cancel
              </button>
            ) : (
              onJoinClick != null && (
                <button
                  className="btn rounded-full mt-2 bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"
                  onClick={() => onJoinClick(item)}
                >
                  Join
                </button>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

const BonusPopup = ({ value, name, index, teamID }) => {
  const [show, setShow] = useState(false);
  const animationProps = useSpring({
    color: show ? "white" : "transparent",
    transform: show ? "translateY(-50px)" : "translateY(0px)",
    config: { duration: 2000 },
  });
  const top = (index + 1) * 8; // adjust the multiplier as needed
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 2000); // Delay of 2 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    show && (
      <animated.div
        style={{ ...animationProps, bottom: `-${top}vh` }}
        className={`absolute  -${teamID == 1 ? "left" : "right"}-10
         damage-popup text-lg font-bold text-white text-center bg-purple-500 opacity-80 p-1 z-1000 rounded-md shadow border-2 border-white`}
      >
        + {value} {name} Bonus
      </animated.div>
    )
  );
};

export default BonusPopup;

import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";

const AttackAnimator = ({
  gifFile,
  startPosition,
  endPosition,
  attackBool,
}) => {
  const startX = startPosition.x;
  const startY = startPosition.y;
  const endX = endPosition.x;
  const endY = endPosition.y;

  const [props] = useSpring(() => ({
    from: { left: `${startX}px`, top: `${startY}px` },
    to: { left: `${endX}px`, top: `${endY}px` },
    config: { duration: 5000 },
  }));

  const [currentGifIndex, setCurrentGifIndex] = useState(0);
  const [renderGif, setRenderGif] = useState(false);

  useEffect(() => {
    if (gifFile && gifFile.length > 0) {
      setRenderGif(true);
    }
  }, [gifFile]);

  useEffect(() => {
    if (renderGif && gifFile) {
      const timer = setTimeout(() => {
        if (currentGifIndex >= gifFile.length - 1)
          setCurrentGifIndex((prevIndex) => prevIndex - prevIndex);
        else setCurrentGifIndex((prevIndex) => prevIndex + 1);
      }, 3000); // Adjust the delay as per the GIF duration
      console.log("currentGifIndex", currentGifIndex);

      return () => clearTimeout(timer);
    }
  }, [renderGif, currentGifIndex, gifFile]);

  return (
    renderGif &&
    gifFile && (
      <div className="absolute z-1">
        <div className="flex justify-center items-center flex-col">
          {gifFile[currentGifIndex] && (
            <animated.img
              style={props}
              key={currentGifIndex}
              src={require(`../../../assets/Attacks/${gifFile[currentGifIndex]}.gif`)}
              autoPlay={true}
              className="w-[900px] h-[900px] z-1"
              alt="attack"
              loop={true}
            />
          )}
        </div>
      </div>
    )
  );
};

export default AttackAnimator;

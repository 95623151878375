import React, { useState, useEffect } from "react";

const ShowDice = ({ diceRolls, setFinalAnimation, bonusColor, type }) => {
  const [currentRolls, setCurrentRolls] = useState([]);
  const [finalResult, setFinalResult] = useState(null);
  const [finalWithBonus, setFinalBonus] = useState(null);
  const [isAnimating, setIsAnimating] = useState(true);
  const [blurredIndex, setBlurredIndex] = useState(0);
  //if dicerolls.nrDice is like "2", convert it to 2
  if (diceRolls && diceRolls.nrDice) {
    diceRolls.nrDice = parseInt(diceRolls.nrDice);
  }
  useEffect(() => {
    let timer;
    if (diceRolls && currentRolls.length < parseInt(diceRolls.nrDice)) {
      setBlurredIndex(currentRolls.length); // set blurredIndex to the index of the dice that's about to be rolled
      if (currentRolls.length === 0) {
        setCurrentRolls([diceRolls.rollsArr[0]]);
        setBlurredIndex(0);
        if (diceRolls.nrDice === 1) {
          setTimeout(() => {
            setFinalResult(diceRolls.rolled);
            setFinalBonus(diceRolls.overallTotal);
            setIsAnimating(false);
            setBlurredIndex(-1);
          }, 1000);
        }
      } else {
        timer = setTimeout(() => {
          const newRolls = [
            ...currentRolls,
            diceRolls.rollsArr[currentRolls.length],
          ];
          setCurrentRolls(newRolls);
          setBlurredIndex(newRolls.length - 1);
          setFinalAnimation(true);

          if (newRolls.length === diceRolls.nrDice) {
            setTimeout(() => {
              setFinalResult(diceRolls.rolled);
              setFinalBonus(diceRolls.overallTotal);
              setIsAnimating(false);
              setFinalAnimation(true);
              setBlurredIndex(-1);
            }, 1000);
          }
        }, 1500);
      }
    }

    return () => clearTimeout(timer);
  }, [diceRolls, currentRolls]);

  return (
    <div className="bg-dark rounded-lg shadow-lg m-2 flex flex-col justify-center items-center">
      <div className="flex justify-center items-center ">
        {currentRolls.map((item, i) => (
          <div
            key={i}
            className={`text-4xl font-semibold transition-all  duration-1000 ${
              i === blurredIndex ? "" : ""
            }`}
          >
            {i === blurredIndex ? (
              <i className="mdi text-dark mdi-comment-question-outline text-4xl lg:text-4xl sm:text-xs"></i>
            ) : (
              <i
                className={`mdi mdi-dice-${item} text-4xl lg:text-4xl sm:text-xs ${
                  bonusColor !== ""
                    ? `text-${bonusColor}-500`
                    : "text-slate-900"
                }`}
              ></i>
            )}
          </div>
        ))}
      </div>
      {finalResult && !isAnimating && (
        <div className="flex justify-center items-center p-2 flex-col">
          {finalWithBonus !== finalResult &&
            !(type != null && type.toString().includes("super")) && (
              <h2 className="text-2xl text-dark font-semibold">
                Rolled: {finalResult}
              </h2>
            )}

          <h2 className="text-2xl text-dark font-semibold">
            Result:{" "}
            {type != null && type.toString().includes("dodge")
              ? finalWithBonus > 0
                ? "Success"
                : "Fail"
              : finalWithBonus}{" "}
          </h2>
        </div>
      )}
    </div>
  );
};

export default ShowDice;

import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

const DamagePopup = ({ damage }) => {
  const [show, setShow] = useState(false);
  const animationProps = useSpring({
    color: show ? "white" : "transparent",
    transform: show ? "translateY(-50px)" : "translateY(0px)",
    config: { duration: 5000 },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1000); // Delay of 2 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    show && (
      <animated.div
        style={animationProps}
        className="absolute damage-popup text-4xl font-bold text-white text-center bg-red-500 p-4 z-90 rounded-md shadow border-2 border-white"
      >
        -{damage}
      </animated.div>
    )
  );
};

export default DamagePopup;

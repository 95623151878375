import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../store/UserContext";
import {
  RarityGradient,
  default as AttributeDisplay,
} from "../components/attributeDisplay";

export default function DiscoverItems(props) {
  const { title, data } = props;
  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    connectMetaMask,
    showModal,
    setShowModal,
    disconnect,
  } = useContext(UserContext);

  const [page, setPage] = useState(1);
  const [maxPerPage, setMaxPerPage] = useState(24);

  const selectItem = (item) => {
    selectItemDetails(item);
  };

  const shiftPage = (direction) => {
    if (direction === "left") {
      if (page > 1) {
        setPage(page - 1);
      }
    } else {
      if (page < Math.ceil(data.length / maxPerPage)) {
        setPage(page + 1);
      }
    }
  };
  return (
    <>
      <div className="mx-auto px-32">
        <div className="grid xl:grid-cols-8 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-10 gap-[30px]">
          {data
            .slice((page - 1) * maxPerPage, page * maxPerPage)
            .map((item, index) => (
              <div
                key={index}
                className="group relative p-2 rounded-lg bg-slate-200 dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700
                 transition-all duration-500 hover:-mt-2"
              >
                <RarityGradient
                  rarity={item.rarity}
                  kingdom1={item.arena_type}
                  kingdom2={item.sub_arena_type}
                />

                <div className="relative overflow-hidden mb-2 rounded-lg">
                  <img
                    src={item.image}
                    className="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500"
                    alt=""
                  />
                </div>
                <AttributeDisplay attributes={item.attributes} />
                <div className="flex justify-center p-2 mt-1 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                  <Link to="/item-detail">
                    <button
                      className="text-l font-semibold block btn btn-link hover:text-violet-600"
                      onClick={() => selectItem(item)}
                    >
                      # {item.tokenId.toString()} - {item.title}
                    </button>
                  </Link>
                </div>
              </div>
            ))}
        </div>

        {data.length > maxPerPage ? (
          <div className="grid md:grid-cols-12 grid-cols-1 mt-8">
            <div className="md:col-span-12 text-center">
              <nav>
                <ul className="inline-flex items-center -space-x-px">
                  <li>
                    <button
                      onClick={() => shiftPage("left")}
                      className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                    >
                      <i className="uil uil-angle-left text-[20px]"></i>
                    </button>
                  </li>
                  {data.length > maxPerPage ? (
                    [...Array(Math.ceil(data.length / maxPerPage)).keys()].map(
                      (item, index) => (
                        <li key={index}>
                          <button
                            className={`w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400  dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600
                                    ${
                                      page === item + 1
                                        ? "text-white bg-violet-600 dark:bg-violet-600"
                                        : "bg-white dark:bg-slate-900"
                                    }`}
                            onClick={() => setPage(item + 1)}
                          >
                            {item + 1}
                          </button>
                        </li>
                      )
                    )
                  ) : (
                    <></>
                  )}
                  <li>
                    <button
                      className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-slate-400 bg-white dark:bg-slate-900 hover:text-white shadow-sm dark:shadow-gray-700 hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"
                      onClick={() => shiftPage("right")}
                    >
                      <i className="uil uil-angle-right text-[20px]"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        ) : (
          ""
        )}

        {title !== undefined ? (
          <div className="grid grid-cols-1 mt-6">
            <div className="text-center">
              <a
                className="btn btn-primary rounded-lg bg-blue-400 text-[16px] font-bold hover:text-violet-600 after:bg-violet-600 duration-500 ease-in-out"
                href="https://opensea.io/collection/ponyjacks"
                target="_blank"
              >
                <span className="text-white">
                  Collection on Opensea <i className="uil uil-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import image1 from "../../assets/images/avatar/1.png";
import image2 from "../../assets/images/items/2.gif";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { UserContext } from "../../store/UserContext";
import TournamentDetail from "../../pages/arena/tournament-detail";
import BattleHistory from "../../components/battlehistories";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../../components/attributeDisplay";
import { showSuccessToast } from "../../components/NotificationService";
import ConfirmationPopup from "../../components/ConfirmationPopup";
export default function BattleTournaments() {
  const [page, setPage] = useState(1);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedBattle, setSelectedBattle] = useState(null);
  const navigate = useNavigate(); // <-- Add this line

  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    connectMetaMask,
    showModal,
    setShowModal,
    disconnect,
  } = useContext(UserContext);

  const shiftPage = (direction) => {
    if (direction === "left") {
      if (page > 1) {
        setPage(page - 1);
      }
    } else {
      if (page < Math.ceil(tournaments.length / 8)) {
        setPage(page + 1);
      }
    }
  };

  const handleDetailsClick = (item) => {
    selectItemDetails(item);
    navigate("/item-detail");
    console.log(item);
  };

  const handleJoin = (item) => {
    var selectedBattle = {
      player1: {
        name: user?.owner_name || "John",
        avatar: user?.avatar || image2,
        ponyjacks: [],
      },
      player2: {
        name: item.owner || "John",
        avatar: item?.owner?.avatar || image1,
        ponyjacks: [item],
      },
      price: item.price || 1,
    };

    setSelectedBattle(selectedBattle);
    setShowConfirmationPopup(true);
  };

  const tournaments = [
    {
      id: 1,
      name: "Weekly 1v1",
      date: "2023-09-10T12:00:00",
      entryFee: 0.1,
      prizePool: 1.5,
      image:
        "https://i.seadn.io/gcs/files/61c633f7ea3df2b2d3cf233eaa091ea4.png?auto=format&dpr=1&w=512",
      type: "Free",
      format: "1v1",
      status: "Upcoming",
      participants: [
        {
          name: "John",
          avatar: image1,
          address: "0x1234567890",
        },
        {
          name: "John",
          avatar: image2,
          address: "0x1234567890",
        },
      ],
    },
    {
      id: 2,
      name: "Monthly Tourney",
      date: "2023-10-15T10:00:00",
      image:
        "https://i.seadn.io/gcs/files/61c633f7ea3df2b2d3cf233eaa091ea4.png?auto=format&dpr=1&w=512",
      entryFee: 0.5,
      prizePool: 5,
      type: "Paid",
      format: "5v5",
      status: "Open",
    },
  ];

  const sortOptions = ["Lowest Entry", "Lower Rated"];

  const [battleType, setBattleType] = useState("All");
  const [rarity, setRarity] = useState("All");
  const [sortOption, setSortOption] = useState("Lowest Entry");
  const [searchText, setSearchText] = useState("");

  const buttonData = [
    {
      type: "1v1",
      icon: "uil uil-user",
    },
    {
      type: "Free 1v1",
      icon: "uil uil-user-plus",
    },
    {
      type: "2v2",
      icon: "uil uil-users-alt",
    },
    {
      type: "3v3",
      icon: "uil uil-user-square", // General icon as a placeholder
    },
    {
      type: "4v4",
      icon: "uil uil-user-circle", // General icon as a placeholder
    },
    {
      type: "5v5",
      icon: "uil uil-tag", // General icon as a placeholder
    },
    {
      type: "Paid Tourney",
      icon: "uil uil-trophy",
    },
    {
      type: "Free Tourney",
      icon: "uil uil-gift",
    },
  ];

  const allNfts = () => {
    var nfts = tournaments;
    nfts = nfts.concat(nfts).concat(nfts);

    return nfts;
  };

  const [showDetails, setShowDetails] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);

  const handleDetailClick = (item) => {
    setSelectedTour(item);
    setShowDetails(true);
  };

  return (
    <>
      <Navbar />
      <ConfirmationPopup
        show={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        battle={selectedBattle}
      />
      <TournamentDetail
        tournament={selectedTour}
        show={showDetails}
        setShow={setShowDetails}
      />
      <section className="py-2 min-h-screen">
        <div className="container mx-auto p-4 flex flex-wrap justify-center items-center mb-4 dark:bg-slate-900 rounded-lg shadow-md">
          <div className="flex items-center space-x-4 justify-center text-center text-xl font-semibold space-x-4">
            <div className="text-gray-600 dark:text-gray-400">
              <span className="text-purple-600 dark:text-purple-300">
                {allNfts().length}
              </span>{" "}
              Tournaments Active
            </div>
            <div className="text-gray-600 dark:text-gray-400">
              <span className="text-purple-600 dark:text-red-300">12</span>{" "}
              Completed
            </div>
            <div className="text-gray-600 dark:text-gray-400">
              <span className="text-purple-600 dark:text-green-300">12</span>{" "}
              <i className="mdi mdi-ethereum ml-1"></i> Total Paid Out
            </div>
          </div>
        </div>
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {allNfts()
              .slice((page - 1) * 9, page * 9)
              .map((tournament, index) => (
                <div
                  key={index}
                  className="relative bg-white dark:bg-slate-900 rounded-lg shadow-md group overflow-hidden hover:shadow-lg transition duration-300"
                >
                  <img
                    src={tournament.image}
                    alt=""
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
                      {tournament.name}
                    </h3>
                    <p className="text-gray-600 dark:text-gray-400">
                      {tournament.date}
                    </p>
                    <div className="flex justify-between mt-3">
                      <div className="text-gray-600 dark:text-gray-400">
                        Entry Fee:{" "}
                        <span className="text-purple-600 dark:text-purple-300">
                          {tournament.entryFee} ETH
                        </span>
                        <i className="mdi mdi-ethereum ml-1"></i>
                      </div>
                      <div className="text-gray-600 dark:text-gray-400">
                        Prize Pool:{" "}
                        <span className="text-purple-600 dark:text-purple-300">
                          {tournament.prizePool} ETH
                        </span>
                        <i className="mdi mdi-ethereum ml-1"></i>
                      </div>
                    </div>
                    <div className="mt-3">
                      <p className="text-gray-600 dark:text-gray-400">
                        Type:{" "}
                        <span className="text-purple-600 dark:text-purple-300">
                          {tournament.type}
                        </span>
                      </p>
                      <p className="text-gray-600 dark:text-gray-400">
                        Format:{" "}
                        <span className="text-purple-600 dark:text-purple-300">
                          {tournament.format}
                        </span>
                      </p>
                      <p className="text-gray-600 dark:text-gray-400">
                        Status:{" "}
                        <span className="text-purple-600 dark:text-purple-300">
                          {tournament.status}
                        </span>
                      </p>
                    </div>
                    <div className="mt-4 flex justify-between">
                      <button
                        onClick={() => handleDetailClick(tournament)}
                        className="btn bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-full transition duration-300"
                      >
                        Details
                      </button>
                      <button
                        onClick={() => handleDetailClick(tournament)}
                        className="btn bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-full transition duration-300"
                      >
                        Join
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {allNfts().length > 9 && (
            <div className="mt-8 text-center">
              <nav>
                <ul className="inline-flex -space-x-px">
                  <li>
                    <button
                      onClick={() => shiftPage("left")}
                      className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300"
                    >
                      <i className="mdi mdi-chevron-left text-xl"></i>
                    </button>
                  </li>
                  {Array.from({ length: Math.ceil(allNfts().length / 9) }).map(
                    (item, index) => (
                      <li key={index}>
                        <button
                          className={`w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300 ${
                            page === index + 1
                              ? "text-white bg-purple-600 dark:bg-purple-700"
                              : ""
                          }`}
                          qqqqqqqqq
                          onClick={() => setPage(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    )
                  )}
                  <li>
                    <button
                      onClick={() => shiftPage("right")}
                      className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300"
                    >
                      <i className="mdi mdi-chevron-right text-xl"></i>
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";

const FilterComponent = React.memo(
  ({
    searchInput,
    setSearchInput,
    setOption,
    setBuyOption,
    setRateOption,
    getNFTs,
    toggle,
    setToggle,
  }) => {
    const options = [
      { value: "Sky", label: "Sky" },
      { value: "Classic", label: "Classic" },
      { value: "Dark", label: "Dark" },
      { value: "Life", label: "Life" },
      { value: "Earth", label: "Earth" },
      { value: "Fire", label: "Fire" },
      { value: "Mythic", label: "Mythic" },
      { value: "Aqua", label: "Aqua" },
      { value: "All", label: "All" },
    ];

    const buyOptions = [
      { value: "Common", label: "Common" },
      { value: "Rare", label: "Rare" },
      { value: "Super Rare", label: "Super Rare" },
      { value: "Legendary", label: "Legendary" },
      { value: "Unique", label: "Unique" },
      { value: "All", label: "All" },
    ];
    const rateOptions = [
      { value: "Kingdom1", label: "Kingdom1" },
      { value: "Kingdom2", label: "Kingdom2" },
      { value: "Highest Price", label: "Highest Price" },
      { value: "Lowest Price", label: "Lowest Price" },
      { value: "All", label: "All" },
    ];

    const NonToggle = () => {
      return (
        <div className="flex justify-center items-center mb-6">
          <button
            className="btn p-2 text-white bg-violet-900 hover:bg-violet-400 transition-all duration-300 rounded shadow-lg border-blue-600 hover:border-violet-700"
            onClick={() => setToggle(true)}
          >
            Filter <i className="mdi mdi-filter"></i>
          </button>
        </div>
      );
    };

    return !toggle ? (
      <NonToggle />
    ) : (
      <section className="relative mt-6 mb-6">
        <div className="container z-1">
          <div className="grid grid-cols-1">
            <form className="p-6 bg-white dark:bg-slate-900 rounded-xl shadow dark:shadow-gray-700">
              <button
                className="bg-violet-500 hover:bg-blue-600 text-white px-2 py-1 rounded-lg transition-colors duration-300
                    absolute top-0 right-0"
                onClick={() => setToggle(false)}
              >
                <i className="mdi mdi-chevron-up"></i>
              </button>
              <div className="registration-form text-dark text-start">
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                  <div>
                    <label className="form-label font-medium dark:text-white">
                      Search:
                    </label>
                    <div className="filter-search-form relative filter-border mt-2">
                      <i className="mdi mdi-magnify icons"></i>
                      <input
                        name="name"
                        type="text"
                        id="job-keyword"
                        className="form-input w-full filter-input-box bg-gray-50 dark:bg-slate-800 border-0 focus:ring-transparent text-white"
                        placeholder="Search your keywords"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="choices-catagory"
                      className="form-label font-medium dark:text-white"
                    >
                      Arena Type:
                    </label>
                    <div className="filter-search-form relative filter-border mt-2">
                      <i className="mdi mdi-castle icons"></i>
                      <Select
                        className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                        options={options}
                        onChange={(e) => setOption(e.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="choices-min-price"
                      className="form-label font-medium dark:text-white"
                    >
                      Rarity:
                    </label>
                    <div className="filter-search-form relative filter-border mt-2">
                      <i className="mdi mdi-diamond-stone icons"></i>
                      <Select
                        className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                        options={buyOptions}
                        onChange={(e) => setBuyOption(e.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="choices-max-price"
                      className="form-label font-medium dark:text-white"
                    >
                      Sort By:
                    </label>
                    <div className="filter-search-form relative mt-2">
                      <i className="uil uil-star icons"></i>
                      <Select
                        className="form-input z-2 filter-input-box bg-gray-50 dark:bg-slate-800 border-0"
                        options={rateOptions}
                        onChange={(e) => setRateOption(e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
);

export default FilterComponent;

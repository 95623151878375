import React, { useContext, useEffect, useState } from "react";

import Navbar from "../../components/navbar";
import axios from "axios";

import Footer from "../../components/footer";
import AuctionsTwo from "../../components/auctions-two";
import { Link } from "react-router-dom";
import Switcher from "../../components/switcher";
import { UserContext } from "../../store/UserContext";
import PonyDex from "./ponydex";
import TransactionPopup from "../../components/transactionPopup";
import BuyContainer from "./buyContainer";
import AnimatedBackground from "../../components/animatedBackground";
import { SlotMachine } from "react-random-slot-machine";
import {
  AttributeIcon,
  RarityGradient,
  default as AttributeDisplay,
} from "../../components/attributeDisplay";
import { all } from "axios";
export default function IndexNine() {
  const { user, allNFTs } = useContext(UserContext);
  const [images, setImages] = useState([]); // Use state to store the images
  useEffect(() => {
    document.documentElement.classList.add("light");
  }, []);
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState(null);
  const [name, setName] = useState(null);

  const handleUnpack = (item) => {
    setDetails("Unpacking " + item?.title);
    setName("Unpack Mystery Box");
    setShow(true);
    console.log(item);
  };

  const RarityData = [
    {
      name: "Common",
      text: "21X each 40%",
      color: "bg-gray-400",
    },
    {
      name: "Rare",
      text: "15X each 27%",
      color: "bg-blue-400",
    },
    {
      name: "Super Rare",
      text: "7X each 14%",
      color: "bg-yellow-400",
    },
    {
      name: "Legendary",
      text: "3X each 6%",
      color: "bg-red-400",
    },
    {
      name: "Unique",
      text: "1X each 0.8%",
      color: "bg-pink-400",
    },
  ];
  const ElementData = [
    {
      name: "Earth",
      text: "Good defense 19%",
      color: "bg-green-400",
    },
    {
      name: "Life",
      text: "Good health 16%",
      color: "bg-yellow-400",
    },
    {
      name: "Classic",
      text: "Good teamwork (doubles) 14%",
      color: "bg-blue-400",
    },
    {
      name: "Aqua",
      text: "Good speed 12%",
      color: "bg-blue-400",
    },
    {
      name: "Fire",
      text: "Good attack 12%",
      color: "bg-red-400",
    },
    {
      name: "Sky",
      text: "Good intelligence (rerolls) 12%",
      color: "bg-purple-400",
    },
    {
      name: "Dark",
      text: "Good dodge 7%",
      color: "bg-gray-400",
    },
    {
      name: "Mythic",
      text: "Good special (6s) 7%",
      color: "bg-pink-400",
    },
  ];

  var getAllData = () => {
    var allNFTs = user?.nfts || [];
    allNFTs = allNFTs.filter((nft) => nft.category !== "Ponyjacks");
    for (var i = 0; i < allNFTs.length; i++) {
      allNFTs[i].date = "2024-09-01T00:00:00.000Z";
    }

    return allNFTs;
  };

  const resultToParams = (resultData) => {
    console.log(resultData);
  };

  const ConfirmBuy = (amount, baseCost) => {
    console.log(amount);
    setDetails(
      "Buying " +
        amount +
        " Mystery Box" +
        (amount > 1 ? "es" : "") +
        " for " +
        parseFloat(amount * baseCost).toFixed(2) +
        " ETH"
    );
    setName(amount + " Mystery Box Purchased");
    setShow(true);
  };

  async function getAllImages() {
    var allImages = [];
    for (var i = 0; i < allNFTs.length; i++) {
      var nft = allNFTs[i];
      var image = axios.get(nft.image).then((res) => {
        return res.data;
      });
      allImages.push(image);
    }

    setImages(allImages);

    return allImages;
  }
  useEffect(() => {
    getAllImages();
  }, []);

  return (
    <>
      <Navbar />
      <TransactionPopup
        setShow={setShow}
        name={name}
        show={show}
        details={details}
      />
      <AnimatedBackground />

      <section className="px-4 relative mt-8 overflow-hidden">
        <div className="mb-8 flex-col items-center justify-center flex">
          <h2 className="text-3xl font-bold mb-4">Mystery Box 1.3</h2>
          <p className="text-gray-500 dark:text-gray-400 mb-4 text-3xl">
            A Mystery Box gives you a chance at any of the 940 released
            Ponyjacks with a random breed, rarity and 2 elements
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 flex-col items-center mb-4 w-['90%'] mx-auto">
            <div className="flex-col items-center justify-center flex rounded-lg shadow-md dark:shadow-gray-800 p-4 dark:bg-slate-900 bg-opacity-60 dark:bg-opacity-60 bg-green-300">
              <span className="text-2xl font-bold text-gray-800 dark:text-gray-300">
                RARITY, the rarer, the better at each breed's natural strength
              </span>
              <ul className="grid grid-cols-2 gap-4 flex-col items-center mb-4">
                {RarityData.map((item, index) => (
                  <li className="mt-4 flex items-center flex-col space-y-2 text-center w-36 ">
                    <span className="text-xl font-bold text-gray-800 dark:text-gray-100 flex items-center">
                      {item.name}
                    </span>
                    <AttributeIcon
                      trait={"Rarity"}
                      value={item.name}
                      size={14}
                    />
                    <span className="text-l font-bold text-gray-800 dark:text-gray-400 flex items-center">
                      {item.text}
                    </span>
                  </li>
                ))}
              </ul>{" "}
            </div>
            <img
              src="https://unicorn-cdn.b-cdn.net/9856bd08-312d-40ce-9227-f01b09a88e8a/mysterybox.png?width=590&height=590"
              className=" shadow-md dark:shadow-slate-800 bg-gray-50 bg-opacity-80 dark:bg-slate-500 dark:bg-opacity-90 rounded-lg w-full"
            />
            <div className="flex-col  items-center justify-center flex rounded-lg shadow-md dark:shadow-gray-800 p-4  dark:bg-slate-900 bg-opacity-80 dark:bg-opacity-60 bg-green-300">
              <span className="text-2xl font-bold text-gray-800 dark:text-gray-300 mt-4">
                Elements, each has its own benefit in battles
              </span>
              <ul className="grid grid-cols-3 md:grid-cols-3 gap-4 flex-col items-center mb-4">
                {ElementData.map((item, index) => (
                  <li className="mt-4 flex items-center flex-col space-y-2">
                    <span className="text-lg font-bold text-gray-800 dark:text-gray-100 flex items-center">
                      {item.name}
                    </span>
                    <AttributeIcon
                      trait={"Element"}
                      value={item.name}
                      size={14}
                    />
                    <span className="text-l font-bold text-gray-800 dark:text-gray-400 flex items-center justify-center">
                      {item.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <BuyContainer onConfirm={ConfirmBuy} />
          {/* <a
            className="btn btn-primary w-full md:w-auto rounded-full mt-4 bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white shadow-lg"
            href="https://opensea.io/collection/ponyjacks"
            target="_blank"
          >
            <i className="mdi mdi-link-variant"></i> Wave 1.3 Mint
          </a> */}
        </div>
      </section>
      <div className="flex items-center justify-center py-8 w-full overflow-hidden">
        <AuctionsTwo
          title="Unpack your Mystery Box"
          description="Unpack to get a  random Ponyjack with a random breed, rarity and elements"
          data={getAllData()}
          handleUnpack={handleUnpack}
        />
      </div>
      <div className="flex items-center justify-center w-full overflow-hidden">
        {images.length > 0 && (
          <SlotMachine
            firstList={images?.slice(0, 30)}
            secondList={images?.slice(30, 60)}
            thirdList={images?.slice(60, 90)}
            resultToParams={resultToParams}
          />
        )}
      </div>
      <div className="flex items-center justify-center w-full overflow-hidden">
        <PonyDex />
      </div>
      <Footer />
      <Switcher />
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { UserContext } from "../../store/UserContext";
import { Link } from "react-router-dom";
import ConfirmationPopup from "../../components/ConfirmationPopup";

export default function BattleResults() {
  const [page, setPage] = useState(1);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [selectedBattle, setSelectedBattle] = useState(null);

  const {
    user,
    connectMetaMask, // Import other necessary context values here
  } = useContext(UserContext);

  // Define your results data with previous battle results
  const results = [
    {
      id: 1,
      name: "Weekly 1v1 Tournament",
      date: "2023-09-10T12:00:00",
      entryFee: 0.1,
      prizePool: 1.5,
      eventType: "Tournament",
      type: "Paid",
      format: "1v1",
      status: "Completed",
      winner: "User123",
      owner: "User123",
    },
    {
      id: 2,
      name: "Monthly Race",
      date: "2023-09-15T14:00:00",
      entryFee: 0.05,
      prizePool: 2.0,
      eventType: "Race",
      type: "Paid",
      format: "Time Trial",
      status: "Ongoing",
      winner: "-",
      owner: "User123",
    },
    {
      id: 3,
      name: "Monthly Top Tourney",
      date: "2023-09-20T12:00:00",
      entryFee: 0.1,
      prizePool: 1.5,
      eventType: "Tournament",
      type: "Paid",
      format: "1v1",
      status: "Completed",
      winner: "User123",
      owner: "User123",
    },
    {
      id: 4,
      name: "Monthly Top Tourney",
      date: "2023-09-20T12:00:00",
      entryFee: 0.1,
      prizePool: 1.5,
      eventType: "Tournament",
      type: "Paid",
      format: "1v1",
      status: "Ongoing",
      winner: "-",
      owner: "User123",
    },
  ];

  const [owner, setOwner] = useState("All");

  const shiftPage = (direction) => {
    if (direction === "left") {
      if (page > 1) {
        setPage(page - 1);
      }
    } else {
      if (page < Math.ceil(results.length / 9)) {
        setPage(page + 1);
      }
    }
  };

  const FilteredResults = () => {
    if (owner === "All") {
      return results;
    } else {
      return results.filter((result) => result.owner === user.owner_name);
    }
  };

  return (
    <div className="container min-h-screen mx-auto px-4 ">
      <ConfirmationPopup
        show={showConfirmationPopup}
        handleClose={() => setShowConfirmationPopup(false)}
        battle={selectedBattle}
      />
      <section className="mx-auto max-w-6xl">
        <h1 className="text-3xl font-semibold text-gray-800 dark:text-gray-200 mb-4 text-center">
          Event Results
        </h1>
        <div className="mb-4 flex justify-center">
          <div className="flex items-center space-x-4 text-slate-900 dark:text-slate-200">
            <button
              className={`btn hover:bg-purple-700  py-2 px-4 rounded-full transition duration-300 ${
                owner === "All"
                  ? "bg-purple-600 dark:bg-purple-700 text-white"
                  : ""
              }`}
              onClick={() => setOwner("All")}
            >
              <i className="mdi mdi-account-outline"></i> All
            </button>
            <button
              className={`btn hover:bg-purple-700  py-2 px-4 rounded-full transition duration-300 ${
                owner !== "All"
                  ? "bg-purple-600 dark:bg-purple-700 text-white"
                  : ""
              }`}
              onClick={() => setOwner("My Events")}
            >
              <i className="mdi mdi-account-outline"></i> My Events
            </button>
          </div>
        </div>
        <div className="table-responsive overflow-x-auto">
          <table className="table bg-white dark:bg-slate-900 rounded-lg shadow-md max-w-full">
            {/* Table Header */}
            <thead>
              <tr>
                <th className="py-2 px-2 text-left bg-gray-200 dark:bg-gray-700">
                  Name
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Type
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Date
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Entry Fee (ETH)
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Prize Pool (ETH)
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Type
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Format
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Status
                </th>
                <th className="py-2 px-4 text-left bg-gray-200 dark:bg-gray-700">
                  Winner
                </th>
                <th className="py-2 px-4 bg-gray-200 dark:bg-gray-700"></th>
              </tr>
            </thead>
            {/* Table Body */}
            <tbody>
              {FilteredResults().map((tournament, index) => (
                <tr
                  key={index}
                  className="border-b dark:border-gray-700 last:border-b-0"
                >
                  <td className="py-2 px-2 text-gray-800 dark:text-gray-200">
                    {tournament.name}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.eventType}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.date}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.entryFee}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.prizePool}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.type}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.format}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.status}
                  </td>
                  <td className="py-2 px-4 text-gray-800 dark:text-gray-200">
                    {tournament.winner}
                  </td>
                  <td className="py-2 px-4 text-right flex justify-end space-x-4">
                    {tournament.status !== "Completed" && (
                      <button className="btn bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-full transition duration-300">
                        Join
                      </button>
                    )}
                    <button className="btn bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-full transition duration-300">
                      Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {results.length > 9 && (
          <div className="mt-8 text-center">
            <nav>
              <ul className="inline-flex -space-x-px">
                <li>
                  <button
                    onClick={() => shiftPage("left")}
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300"
                  >
                    <i className="mdi mdi-chevron-left text-xl"></i>
                  </button>
                </li>
                {Array.from({ length: Math.ceil(results.length / 9) }).map(
                  (item, index) => (
                    <li key={index}>
                      <button
                        className={`w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300 ${
                          page === index + 1
                            ? "text-white bg-purple-600 dark:bg-purple-700"
                            : ""
                        }`}
                        onClick={() => setPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  )
                )}
                <li>
                  <button
                    onClick={() => shiftPage("right")}
                    className="w-10 h-10 inline-flex justify-center items-center mx-1 rounded-full text-gray-600 dark:text-gray-300 bg-white dark:bg-slate-900 hover:text-white hover:bg-purple-600 dark:hover:bg-purple-700 transition duration-300"
                  >
                    <i className="mdi mdi-chevron-right text-xl"></i>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </section>
    </div>
  );
}

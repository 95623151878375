import React, { useEffect, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function BuyContainer(props) {
  const { onConfirm } = props;
  const [amount, setAmount] = useState(1);
  const baseCost = 0.05;

  const handleChanges = (type) => {
    var value = amount;
    if (type === "add") value++;
    if (type === "subtract") value--;
    if (value < 1) value = 1;
    if (value > 20) value = 10;
    setAmount(value);
  };

  return (
    <div className="container relative mx-auto px-4 text-center bg-white dark:bg-gray-900 py-4 rounded-lg shadow-lg">
      <h3 className="text-2xl font-bold mb-4">Buy Mystery Box 1.3</h3>
      <p className="text-gray-500 dark:text-gray-400 mb-4 text-3xl">
        Purchase Mystery Boxes using Ethereum
      </p>
      <div className="flex-col items-center justify-center flex rounded-lg ">
        <div className="flex flex-row space-x-4 justify-center items-center">
          <button
            className="ml-4 bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white font-bold py-2 px-4 rounded inline-flex items-center"
            onClick={() => handleChanges("subtract")}
          >
            <i className="mdi mdi-minus text-2xl"></i>
          </button>{" "}
          <span className="text-2xl font-bold text-gray-800 dark:text-gray-300 text-center">
            {amount} Mystery Box
            {amount > 1 ? "es" : ""}
          </span>
          <button
            className="ml-4 bg-gray-200 hover:bg-gray-300 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white font-bold py-2 px-4 rounded inline-flex items-center"
            onClick={() => handleChanges("add")}
          >
            <i className="mdi mdi-plus text-2xl"></i>
          </button>
        </div>
        <span className="text-2xl font-bold text-gray-800 dark:text-gray-300 mt-4">
          Total Cost
        </span>
        <span className="text-2xl font-bold text-gray-800 dark:text-gray-300">
          {parseFloat(amount * baseCost).toFixed(2)} ETH
        </span>
        <button
          className="mt-4 text-2xl bg-violet-600 hover:bg-violet-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
          onClick={() => onConfirm(amount, baseCost)}
        >
          <i className="mdi mdi-lightning-bolt mr-2"></i> Buy
        </button>
        <a
          href="https://opensea.io/collection/ponyjack-mystery-box"
          target="_blank"
        >
          <button className="mt-2 text-xl bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
            <i className="mdi mdi-link-variant mr-2"></i> OpenSea
          </button>
        </a>
      </div>
    </div>
  );
}

import { useEffect, useState, useContext } from "react";
import { Fade, Slide, AttentionSeeker } from "react-awesome-reveal";
import axios from "axios";
import { UserContext } from "../store/UserContext";

export default function AnimatedBackground() {
  //"bounce"
  const { allNFTs } = useContext(UserContext);
  const animations = ["flash", "heartBeat"];

  function getRandomAnimation() {
    const randomIndex = Math.floor(Math.random() * animations.length);
    return animations[randomIndex];
  }

  const [images, setImages] = useState([]); // Use state to store the images

  async function getAllImages() {
    var allFoundNfts = allNFTs.length > 0 ? allNFTs : [];
    var allImages = [];
    var allNames = [];
    for (var i = 0; i < allFoundNfts.length; i++) {
      var nft = allFoundNfts[i];
      var foundName = allNames.find((name) => name == nft.title);
      if (foundName) {
        continue;
      }
      allNames.push(nft.title);
      allImages.push(nft.image);
    }
    setImages(allImages);
    console.log("allImages", allImages);
    return allImages;
  }

  useEffect(() => {
    getAllImages();
  }, []);

  return (
    <section className="mx-auto px-4 sm:px-6 lg:px-8 relative pt-24 overflow-hidden">
      <div className="container-fluid relative">
        <div className="grid grid-cols-1">
          <div className="slider relative overflow-hidden m-auto mb-4 before:content-[''] before:absolute before:top-0 before:start-0 before:z-2 after:content-[''] after:absolute after:top-0 after:end-0 after:z-2">
            <div className="slide-track flex items-center justify-center ">
              {images?.slice(0, images.length / 2).map((item, index) => {
                const randomAnimation = getRandomAnimation();

                return (
                  <div key={index} className="slide flex-shrink-0 mx-2">
                    <AttentionSeeker effect={randomAnimation}>
                      <img
                        src={item}
                        className={`rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500 w-[100px] h-[80px] object-cover object-center
                        `}
                        alt=""
                        loading="lazy"
                      />
                    </AttentionSeeker>
                  </div>
                );
              })}
            </div>
            <div className="slide-track flex items-center justify-center mt-2 ">
              {images
                ?.slice(images.length / 2, images.length)
                .map((item, index) => {
                  const randomAnimation = getRandomAnimation();

                  return (
                    <div key={index} className="slide flex-shrink-0 mx-2">
                      <AttentionSeeker
                        effect={randomAnimation}
                        //add more here
                        duration={Math.floor(Math.random() * 1000) + 1000}
                        damping={Math.floor(Math.random() * 10) + 1}
                        cascade={true}
                        infinite={true}
                        queue={true}
                        leaveDuration={Math.floor(Math.random() * 1000) + 1000}
                      >
                        <img
                          src={item}
                          className={`rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500 w-[100px] h-[80px] object-cover object-center
                        `}
                          alt=""
                          loading="lazy"
                        />
                      </AttentionSeeker>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

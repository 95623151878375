import React from "react";
import { Link } from "react-router-dom";

export default function GetTouch() {
  return (
    <div className="container md:mt-24 mt-16">
      <div className="grid grid-cols-1 text-center">
        <h3 className="mb-4 md:text-3xl text-2xl md:leading-snug leading-snug font-semibold">
          Have Question ? Get in touch!
        </h3>
        <div className="mt-6">
          <Link
            to="/helpcenter-support"
            className="btn bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-full me-2 mt-2"
          >
            <i className="uil uil-phone"></i> Contact us
          </Link>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Switcher from "../../components/switcher";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../store/UserContext";
import { BattleContext } from "../../store/BattleContext";
import AttributeDisplay from "../../components/attributeDisplay";
import BattleHistory from "../../components/battlehistories";

export default function Activity() {
  const [page, setPage] = useState(1);
  const {
    user,
    account,
    balance,
    selectedItem,
    selectItemDetails,
    selectedBattle,
    setSelectedBattle,
  } = useContext(UserContext);

  const { battleHistory, getBattleHistory } = useContext(BattleContext);
  const buttonData = [
    {
      type: "All",
      icon: "uil uil-apps",
    },
    {
      type: "1v1",
      icon: "uil uil-user",
    },
    {
      type: "Free 1v1",
      icon: "uil uil-user-plus",
    },
    {
      type: "2v2",
      icon: "uil uil-users-alt",
    },
    {
      type: "3v3",
      icon: "uil uil-user-square", // General icon as a placeholder
    },
    {
      type: "4v4",
      icon: "uil uil-user-circle", // General icon as a placeholder
    },
    {
      type: "5v5",
      icon: "uil uil-tag", // General icon as a placeholder
    },
    {
      type: "Paid Tourney",
      icon: "uil uil-trophy",
    },
    {
      type: "Free Tourney",
      icon: "uil uil-gift",
    },
  ];

  const [selectedType, setSelectedType] = useState(buttonData[0].type);
  const [selectedOwner, setSelectedOwner] = useState("My Events");
  const [selectedSort, setSelectedSort] = useState("Newest");
  const [searchInput, setSearchInput] = useState("");

  const reload = async () => {
    console.log("reloading for ", account);
    await getBattleHistory();
  };

  useEffect(() => {
    if (battleHistory.length === 0) {
      reload();
    }
  }, []);

  const filterNameOrPonyjack = (item) => {
    var allNames = [];
    var allPonies = [];
    item.battleHistory.forEach((element) => {
      allNames.push(element.userInfo?.owner_name.toLowerCase());
      allPonies.push(element.nftData?.title.toLowerCase());
    });
    console.log(allNames, allPonies);
    console.log(searchInput.toLowerCase());
    return (
      allNames.filter((name) => name.includes(searchInput.toLowerCase()))
        .length > 0 ||
      allPonies.filter((pony) => pony.includes(searchInput.toLowerCase()))
        .length > 0
    );
  };

  const FilteredData = () => {
    var finalData = battleHistory || [];
    //date is in format = 2023-10-20T11:45:42.000Z
    //sorting by date
    finalData.sort((a, b) => {
      return new Date(b.modified) - new Date(a.modified);
    });

    if (selectedOwner === "My Events") {
      finalData = finalData.filter(
        (item) =>
          item?.battleHistory?.filter(
            (element) => element.userInfo?.owner_name === user?.owner_name
          ).length > 0
      );
    }
    if (selectedType !== "All") {
      console.log("selected type", selectedType);
      finalData = finalData.filter(
        (item) => item.nr_members === parseInt(selectedType.split("v")[1])
      );
    }
    if (searchInput !== "") {
      finalData = finalData.filter((item) => filterNameOrPonyjack(item));
    }
    if (selectedSort === "Newest") {
      finalData = finalData.sort((a, b) => {
        return new Date(b.modified) - new Date(a.modified);
      });
    } else if (selectedSort === "Oldest") {
      finalData = finalData.sort((a, b) => {
        return new Date(a.modified) - new Date(b.modified);
      });
    } else if (selectedSort === "Pending") {
      console.log(finalData);
      finalData = finalData.filter(
        (item) =>
          item.pending &&
          item.pendingBattle?.winnerAddress.toLowerCase() ===
            account?.toLowerCase()
      );
    }

    //sort by pending bool status

    console.log(finalData);

    //display only battles which are more than 10 minutes old
    // finalData = finalData.filter((item) => {
    //   return (
    //     new Date().getTime() - new Date(item.modified).getTime() >
    //     10 * 60 * 1000
    //   );
    // });

    return finalData;
  };

  return (
    <>
      <Navbar />
      <section className="relative table w-full  py-24 bg-[url('../../assets/images/Ponyjacks/Bronzejack.png')] bg-top bg-no-repeat">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-slate-900"></div>
        <div className="container">
          <div className="grid grid-cols-1 pb-8 text-center mt-10">
            <h3 className="md:text-3xl text-2xl md:leading-snug tracking-wide leading-snug font-medium text-white">
              Event History
            </h3>
            <button onClick={reload} className="text-white">
              Reload
            </button>
          </div>
        </div>

        <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
          <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
            <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white">
              <Link to="/">Ponyjacks</Link>
            </li>
            <li
              className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white"
              aria-current="page"
            >
              History
            </li>
          </ul>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16 min-h-screen">
        <div className="container">
          <div className="md:flex">
            <BattleHistory showButton={false} battleData={FilteredData()} />

            <div className="lg:w-2/5 md:w-1/2 md:ps-4">
              <div className="sticky top-20">
                <h5 className="text-lg font-semibold">Filter by</h5>

                <div className="form-icon relative mt-4">
                  <i className="uil uil-search text-lg absolute top-1/2 -translate-y-1/2 start-3"></i>
                  <input
                    type="text"
                    className="form-input w-full text-[15px] ps-10 py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded-full outline-none border border-gray-200 focus:border-violet-600 dark:border-gray-800 dark:focus:border-violet-600 focus:ring-0"
                    name="s"
                    id="searchItem2"
                    placeholder="Search by opponent name or ponyjack"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </div>
                <h5 className="text-lg font-semibold mt-6">Sort</h5>

                <ul className="list-none mt-4">
                  <li className="inline-block m-2">
                    <button
                      className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                      ${
                        selectedSort === "Pending"
                          ? "bg-violet-600 dark:bg-violet-800 "
                          : "bg-gray-50 dark:bg-slate-800 "
                      } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                      onClick={() => setSelectedSort("Pending")}
                    >
                      <i className="uil uil-wallet me-1"></i>
                      Withdrawals
                    </button>
                  </li>
                  <li className="inline-block m-2">
                    <button
                      className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                      ${
                        selectedSort === "Newest"
                          ? "bg-violet-600 dark:bg-violet-800 "
                          : "bg-gray-50 dark:bg-slate-800 "
                      } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                      onClick={() => setSelectedSort("Newest")}
                    >
                      Newest
                    </button>
                  </li>
                  <li className="inline-block m-2">
                    <button
                      className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                      ${
                        selectedSort === "Oldest"
                          ? "bg-violet-600 dark:bg-violet-800 "
                          : "bg-gray-50 dark:bg-slate-800 "
                      } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                      onClick={() => setSelectedSort("Oldest")}
                    >
                      Oldest
                    </button>
                  </li>
                </ul>
                <h5 className="text-lg font-semibold mt-6">Owner</h5>

                <ul className="list-none mt-4">
                  <li className="inline-block m-2">
                    <button
                      className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                      ${
                        selectedOwner === "My Events"
                          ? "bg-violet-600 dark:bg-violet-800 "
                          : "bg-gray-50 dark:bg-slate-800 "
                      } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                      onClick={() => setSelectedOwner("My Events")}
                    >
                      <i className="uil uil-wallet me-1"></i> My Events
                    </button>
                  </li>
                  <li className="inline-block m-2">
                    <button
                      className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white
                      ${
                        selectedOwner === "All Events"
                          ? "bg-violet-600 dark:bg-violet-800 "
                          : "bg-gray-50 dark:bg-slate-800 "
                      } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                      onClick={() => setSelectedOwner("All Events")}
                    >
                      All Events
                    </button>
                  </li>
                </ul>
                <h5 className="text-lg font-semibold mt-6"> Type</h5>

                <ul className="list-none mt-4">
                  {buttonData.map((item, index) => (
                    <li key={index} className="inline-block m-2">
                      <button
                        className={`px-3 py-1.5 text-slate-400 hover:text-white dark:hover:text-white 
                        ${
                          selectedType === item.type
                            ? "bg-violet-600 dark:bg-violet-800 "
                            : "bg-gray-50 dark:bg-slate-800 "
                        }
                        ${
                          item.icon ? "bg-violet-50" : "bg-gray-50"
                        } dark:bg-slate-800 text-sm hover:bg-violet-600 dark:hover:bg-violet-600 font-semibold rounded-lg shadow dark:shadow-gray-800 transition-all duration-500 ease-in-out`}
                        onClick={() => setSelectedType(item.type)}
                      >
                        {item.icon && <i className={`${item.icon} me-1`}></i>}{" "}
                        {item.type}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}
